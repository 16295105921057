import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserRoleRequest,
  getRoleRequest,
  getRoleRightsRequest,
  updateRoleRequest,
} from "redux/roleRights/action";
import { MainContext } from "context/contexts";
import usePrevious from "utility/hooks/usePrevious";
import { sortByTitle } from "utility/utility";
import { onHandleUpdateRole } from "utility/roles";
import DeleteConfirm from "components/modals/DeleteConfirm";
import SubHeader from "components/SubHeader";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { useTranslation } from "react-i18next";

const Role = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataSourceRef] = useCtrlSHandler(handleSubmit);
  const { t } = useTranslation();

  const {
    roleRights,
    role,
    isGetRoleRightsSuccess,
    isGetRoleRightsError,
    isGetRoleSuccess,
    isGetRoleError,
    isUpdateRoleSuccess,
    isUpdateRoleError,
    removeUserRoleSuccess,
    removeUserRoleFailure,
  } = useSelector((state) => state.roleRights);

  const prevIsGetRoleRightsSuccess = usePrevious(isGetRoleRightsSuccess);
  const prevIsGetRoleRightsError = usePrevious(isGetRoleRightsError);
  const prevIsGetRoleError = usePrevious(isGetRoleError);
  const prevIsUpdateRoleError = usePrevious(isUpdateRoleError);
  const prevRemoveUserRoleSuccess = usePrevious(removeUserRoleSuccess);
  const prevRemoveUserRoleFailure = usePrevious(removeUserRoleFailure);
  const prevIsGetRoleSuccess = usePrevious(isGetRoleSuccess);
  const prevIsUpdateRoleSuccess = usePrevious(isUpdateRoleSuccess);

  const [roleRightsData, setRoleRightsData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState(false);
  const [isDefaultSSO, setIsDefaultSSO] = useState(false);

  useEffect(() => {
    document.title = t("role_decisimo");
    setIsLoading(true);
    dispatch(getRoleRequest(id));
    dispatch(getRoleRightsRequest());
  }, []);

  useEffect(() => {
    if (isGetRoleRightsSuccess && prevIsGetRoleRightsSuccess === false) {
      const roleRightsClone = structuredClone(roleRights);
      roleRightsClone.sort(sortByTitle);
      setRoleRightsData(roleRightsClone);
    }
  }, [isGetRoleRightsSuccess]);

  useEffect(() => {
    if (
      (isGetRoleRightsError && prevIsGetRoleRightsError === false) ||
      (isGetRoleError && prevIsGetRoleError === false) ||
      (isUpdateRoleError && prevIsUpdateRoleError === false) ||
      (removeUserRoleFailure && prevRemoveUserRoleFailure === false)
    ) {
      setIsLoading(false);
    }
  }, [
    isGetRoleRightsError,
    isGetRoleError,
    isUpdateRoleError,
    removeUserRoleFailure,
  ]);

  useEffect(() => {
    if (isGetRoleSuccess && prevIsGetRoleSuccess === false) {
      setIsLoading(false);
      setRoleData(role);
    }
  }, [isGetRoleSuccess]);

  useEffect(() => {
    if (roleData.length > 0) {
      setIsDefaultSSO(roleData[0].is_default_sso === "1");
    }
  }, [roleData]);

  useEffect(() => {
    if (
      (removeUserRoleSuccess && prevRemoveUserRoleSuccess === false) ||
      (isUpdateRoleSuccess && prevIsUpdateRoleSuccess === false)
    ) {
      setIsLoading(false);
      if (prevRemoveUserRoleSuccess === false) {
        toast.warning(t("role_deleted"), ToastOptions);
        navigate("/user-roles");
      } else {
        toast.success(t("role_updated"), ToastOptions);
      }
    }
  }, [isUpdateRoleSuccess, removeUserRoleSuccess]);

  const isChecked = (accessType, accessRightId) => {
    return !!roleData.find(
      (role) =>
        role[accessType] === "1" && role["access_right_id"] === accessRightId
    );
  };

  const handleIsDefaultSSOChange = (e) => {
    setIsDefaultSSO(e.target.checked);
  };

  function handleSubmit(e) {
    const data = onHandleUpdateRole(e, roleData);
    setIsLoading(true);
    dispatch(updateRoleRequest({ data, id }));
  }

  const handleShowDeleteModal = () => setIsUserDeleteModalOpen(true);
  const handleClose = () => setIsUserDeleteModalOpen(false);

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(deleteUserRoleRequest(id));
  };

  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item delete-role"
          type="button"
          onClick={handleShowDeleteModal}
          title={t("delete_user_role")}
        >
          <TrashIcon /> {t("delete")}
        </button>
      ),
    },
  ];

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e.target);
        }}
      >
        <SubHeader
          title={t("user_role_rights")}
          actions={
            <>
              <Link to="/user-roles">
                <button className="btn outline-header">
                  <BackIcon />{" "}
                  <span className="ml-2">{t("back_to_roles")}</span>
                </button>
              </Link>
              <button className="btn primary-header mx-2" type="submit">
                <SaveIcon />
              </button>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn primary-header"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <SettingsIcon />
                </button>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                  {settings.length > 0 &&
                    settings.map((setting) => {
                      return (
                        <span key={setting.id}>
                          {setting.content}
                          {setting.divider && (
                            <div className="dropdown-divider" />
                          )}
                        </span>
                      );
                    })}
                </div>
              </div>
            </>
          }
        />
        <div className="row">
          <div className="col">
            <label>{t("table_title")}</label>
            <input
              type="text"
              className="form-control"
              name="title"
              defaultValue={
                roleData?.length > 0 ? roleData[0].access_role_title : ""
              }
              required
            />
          </div>
        </div>

        {roleData?.length > 0 && roleData[0].tenant_sso && (
          <div className="row mt-2 pl-4">
            <div className="col">
              <input
                type="checkbox"
                name="is_default_sso"
                className="form-check-input"
                checked={isDefaultSSO}
                onChange={handleIsDefaultSSOChange}
              />
              <label> {t("is_default_sso_role")} </label>
            </div>
          </div>
        )}
        <div className="mb-5 table-responsive mt-2">
          <table className="table table-pretty vertical-align-middle">
            <thead className="bg-transparent border-0">
              <tr>
                <th>{t("access_right_area")}</th>
                <th>{t("read")}</th>
                <th>{t("write")}</th>
                <th>{t("delete")}</th>
              </tr>
            </thead>
            <tbody>
              {roleRightsData?.length > 0 &&
                roleData?.length > 0 &&
                roleData.map((roleRights) => {
                  return (
                    <tr key={roleRights.access_right_id}>
                      <td>
                        {roleRights.title}
                        <input
                          type="hidden"
                          value="1"
                          name={`right[${roleRights.access_right_id}][_____irrelevant]`}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          name={`right[${roleRights.access_right_id}][access_read]`}
                          defaultChecked={isChecked(
                            "access_read",
                            roleRights.access_right_id
                          )}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          name={`right[${roleRights.access_right_id}][access_write]`}
                          defaultChecked={isChecked(
                            "access_write",
                            roleRights.access_right_id
                          )}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          name={`right[${roleRights.access_right_id}][access_execute]`}
                          defaultChecked={isChecked(
                            "access_execute",
                            roleRights.access_right_id
                          )}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </form>
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_user_role")}
        message={t("do_you_want_to_delete_this_user_role")}
        open={isUserDeleteModalOpen}
      />
    </>
  );
};

export default Role;
