import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getScorecardByIdRequest,
  updateScorecardRequest,
  updateScorecardSuccess,
  deleteScorecardRequest,
  deleteScorecardSuccess,
  getScorecardRevisionsRequest,
  getRunTestResultRequest,
  getTestScorecardRequest,
  getRunTestResultStatusRequest,
  getScorecardExplanationRequest,
} from "redux/scorecard/action";
import usePrevious from "utility/hooks/usePrevious";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { MainContext } from "context/contexts";
import SubHeader from "components/SubHeader";
import ScorecardEditorContent from "components/scorecards/ScorecardEditorContent";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useTranslation } from "react-i18next";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as WindIcon } from "assets/icons/wind.svg";
import { ReactComponent as SparklesIcon } from "assets/icons/sparkles.svg";
import DeleteConfirm from "components/modals/DeleteConfirm";
import Revisions from "components/modals/scorecards/Revisions";
import TestingModal from "components/modals/Testing";
import ExplanationModal from "components/modals/explain/ExplainModal";

const Scorecard = () => {
  const { vectorsData, setIsLoading, setIsEdited, isEdited } =
    useContext(MainContext);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    scorecard,
    revisions,
    testScorecard,
    isFetchingScorecard,
    isUpdatingScorecard,
    isDeletingScorecard,
    isDeleteScorecardSuccess,
    fetchScorecardError,
    updateScorecardError,
    updateScorecardSuccess,
    isUpdateScorecardSuccess,
    isGetScorecardSuccess,
    isGetTestResultStatusSuccess,
    isGetTestScorecardSuccess,
    isGetRunTestResultSuccess,
    runTestResultDetails,
    runTestResultStatus,
    scorecardExplanation,
    isGetScorecardExplanationSuccess,
    isGetScorecardExplanationFailure,
  } = useSelector((state) => state.scorecard);

  const [vectorsDataClone, setVectorsDataClone] = useState([]);
  const [testScorecardData, setTestScorecardData] = useState([]);
  const [scorecardData, setScorecardData] = useState({});
  const [isScorecardDeleteModalOpen, setIsScorecardDeleteModalOpen] =
    useState(false);
  const [isScorecardRevisionsModalOpen, setIsScorecardRevisionsModalOpen] =
    useState(false);

  const [isExplanationModalOpen, setIsExplanationModalOpen] = useState(false);
  const [isLoadingExplanation, setIsLoadingExplanation] = useState(false);
  const [explanation, setExplanation] = useState("");

  const prevIsRemovedScorecardSuccess = usePrevious(isDeleteScorecardSuccess);
  const prevIsGetTestScorecardSuccess = usePrevious(isGetTestScorecardSuccess);

  //setRunTestResultStatusData
  const [runTestResultStatusData, setRunTestResultStatusData] = useState();

  useEffect(() => {
    document.title = t("edit_scorecard") + " - Decisimo";
    setIsLoading(true);
    dispatch(getScorecardByIdRequest(id));
  }, [id, dispatch, t]);

  useEffect(() => {
    setVectorsDataClone([...vectorsData]);
  }, [vectorsData]);

  useEffect(() => {
    if (isGetTestScorecardSuccess && prevIsGetTestScorecardSuccess === false) {
      setIsLoading(false);
      setTestScorecardData(structuredClone(testScorecard));
    }
  }, [isGetTestScorecardSuccess]);

  //isGetTestResultStatusSuccess
  useEffect(() => {
    if (isGetTestResultStatusSuccess) {
      setIsLoading(false);
      setRunTestResultStatusData(structuredClone(runTestResultStatus));
    }
  }, [isGetTestResultStatusSuccess]);

  useEffect(() => {
    setIsLoading(isFetchingScorecard || isUpdatingScorecard);
  }, [isFetchingScorecard, isUpdatingScorecard, setIsLoading]);

  const scorecardIsValid = (scorecard) => {
    if (!scorecard.name) {
      return false;
    }
    if (!scorecard.predictors.length) {
      return false;
    }
    for (const predictor of scorecard.predictors) {
      if (!predictor.name) {
        return false;
      }
      if (!predictor.bins.length) {
        return false;
      }
      for (const bin of predictor.bins) {
        if (!bin.name) {
          return false;
        }
      }
    }
    return true;
  };
  const changePredictorAndBinIds = (scorecard) => {
    let newScorecard = { ...scorecard };

    //check if predictors is an array, if not, make it an array
    newScorecard.predictors = newScorecard.predictors || [];

    newScorecard.predictors = newScorecard.predictors.map((predictor) => {
      predictor.scorecard_predictor_id =
        predictor.scorecard_predictor_id.toString();
      //if it is null, make it an empty array
      predictor.bins = predictor.bins || [];

      predictor.bins = predictor.bins.map((bin) => {
        bin.scorecard_bin_id = bin.scorecard_bin_id.toString();
        return bin;
      });
      return predictor;
    });
    return newScorecard;
  };

  useEffect(() => {
    if (!isFetchingScorecard && scorecard && !fetchScorecardError) {
      const newScorecard = changePredictorAndBinIds(scorecard);
      setScorecardData(newScorecard);
    }
    if (fetchScorecardError) {
      toast.error(fetchScorecardError, ToastOptions);
    }
  }, [isFetchingScorecard, scorecard, fetchScorecardError]);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!firstUpdate.current) {
      if (updateScorecardError) {
        toast.error(updateScorecardError, ToastOptions);
      }
    } else {
      firstUpdate.current = false;
    }
  }, [isUpdatingScorecard, updateScorecardError, t]);

  const handleScorecardChange = (updatedScorecard) => {
    setScorecardData(updatedScorecard);
    setIsEdited(true);
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (isUpdateScorecardSuccess && !isUpdatingScorecard) {
      if (scorecard) {
        setScorecardData(scorecard);
        toast.success(t("scorecard_saved"), ToastOptions);
        setIsEdited(false);
      }
    }
    if (!isUpdatingScorecard && updateScorecardError) {
      toast.error(t("scorecard_saving_failed"), ToastOptions);
    }
  }, [isUpdatingScorecard, updateScorecardError, isUpdateScorecardSuccess]);

  const saveScorecard = () => {
    if (!scorecardIsValid(scorecardData)) {
      return toast.error(`${t("please_fill_out_all_fields")}.`, ToastOptions);
    }
    setIsLoading(true);
    setIsEdited(false);

    dispatch(updateScorecardRequest({ scorecardData, id }));
  };

  const handleToggleScorecardRevisionsModal = (isOpen = true) => {
    if (isOpen) {
      setIsLoading(true);
      dispatch(getScorecardRevisionsRequest(parseInt(id)));
    }
    setIsScorecardRevisionsModalOpen(isOpen);
  };

  const handleToggleScorecardDeleteModal = (isOpen = true) => {
    setIsScorecardDeleteModalOpen(isOpen);
  };

  const handleConfirmDeleteScorecardModal = () => {
    setIsLoading(true);
    setIsEdited(false);
    dispatch(deleteScorecardRequest(id));
    handleToggleScorecardDeleteModal(false);
  };

  useEffect(() => {
    if (
      !isDeletingScorecard &&
      isDeleteScorecardSuccess &&
      prevIsRemovedScorecardSuccess === false
    ) {
      setIsLoading(false);
      setIsScorecardDeleteModalOpen(false);
      navigate("/scorecards");

      toast.warning(t("scorecard_deleted"), ToastOptions);
    }
  }, [isDeleteScorecardSuccess]);

  //testing modal
  const [loading, setLoading] = useState(false);
  const [isTestingModalOpen, setIsTestingModalOpen] = useState(false);
  const [runTestResultDetailsData, setRunTestResultDetailsData] = useState();
  const [testScorecardClone, setTestScorecardClone] = useState([]);

  const handleToggleTestingModal = (isOpen = false) => {
    //if is edited, we will ask to save the scorecard first
    if (isEdited) {
      return toast.error(t("please_save_scorecard_first"), ToastOptions);
    }
    setIsTestingModalOpen(isOpen);
    if (!isOpen) {
      setRunTestResultDetailsData();
    } else {
      dispatch(getTestScorecardRequest(id));
    }
  };

  const handleRunTestResultConfirm = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = new URLSearchParams(new FormData(e.target));
    dispatch(getRunTestResultRequest({ data, id }));
  };

  useEffect(() => {
    if (
      runTestResultDetails &&
      (isGetRunTestResultSuccess || isGetTestResultStatusSuccess)
    ) {
      setRunTestResultDetailsData(runTestResultDetails);
      setLoading(false);
    }
  }, [runTestResultDetails, isGetTestResultStatusSuccess]);

  const handleRunTestResultSuccessConfirm = (vectorId) => {
    if (!vectorId) {
      vectorId = vectorsData.length > 1 ? vectorsData[1].vector_id : null;
    }
    if (!vectorId) {
      return toast.error(t("no_data_object_selected"), ToastOptions);
    }
    setIsLoading(true);
    dispatch(getRunTestResultStatusRequest({ id, vectorId }));
  };

  const handleExplain = (scorecardId) => {
    setExplanation("");
    dispatch(
      getScorecardExplanationRequest({
        scorecard_id: scorecardId,
      })
    );
    setIsLoadingExplanation(true);
    setIsExplanationModalOpen(true);
  };

  useEffect(() => {
    if (isGetScorecardExplanationSuccess) {
      setExplanation(scorecardExplanation);
      setIsLoadingExplanation(false);
    }
  }, [isGetScorecardExplanationSuccess]);

  useEffect(() => {
    if (isGetScorecardExplanationFailure) {
      setExplanation(t("explanation_not_available"));
      setIsLoadingExplanation(false);
    }
  }, [isGetScorecardExplanationFailure]);

  const handleTryAgain = () => {
    if (!isLoadingExplanation) {
      dispatch(
        getScorecardExplanationRequest({
          scorecard_id: id,
        })
      );
      setIsLoadingExplanation(true);
    }
  };

  const isScorecardDataLoaded = scorecardData && scorecardData.predictors;

  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item"
          onClick={() => handleToggleScorecardRevisionsModal()}
          type="button"
        >
          <ClockIcon /> {t("revisions")}
        </button>
      ),
      divider: false,
    },
    {
      id: 2,
      content: (
        <button
          className="dropdown-item"
          type="button"
          onClick={() => handleExplain(id)}
        >
          <SparklesIcon /> {t("explain")}
        </button>
      ),
      divider: true,
    },
    {
      id: 3,
      content: (
        <button
          className="dropdown-item"
          type="button"
          onClick={handleToggleScorecardDeleteModal}
        >
          <DeleteIcon /> {t("delete")}
        </button>
      ),
      divider: false,
    },
  ];

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveScorecard();
        }}
        onChange={() => setIsEdited(true)}
      >
        <SubHeader
          title={t("edit_scorecard")}
          actions={
            <>
              <button
                title={t("test_scorecard")}
                className="btn outline mr-2"
                type="button"
                onClick={() => handleToggleTestingModal(true)}
              >
                <WindIcon />
              </button>
              <button className="btn primary mr-1" type="submit">
                <SaveIcon />
              </button>
              <div>
                <button
                  type="button"
                  className="btn primary mr12 h-38"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  title={t("delete_scorecard")}
                >
                  <SettingsIcon />
                </button>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                  {settings.length > 0 &&
                    settings.map((setting) => {
                      return (
                        <span key={setting.id}>
                          {setting.content}
                          {setting.divider && (
                            <div className="dropdown-divider" />
                          )}
                        </span>
                      );
                    })}
                </div>
              </div>
            </>
          }
        />
        {isScorecardDataLoaded && (
          <ScorecardEditorContent
            scorecard={scorecardData}
            onScorecardChange={handleScorecardChange}
            vectorsData={vectorsDataClone}
          />
        )}
      </form>

      <DeleteConfirm
        handleClose={() => handleToggleScorecardDeleteModal(false)}
        handleConfirm={handleConfirmDeleteScorecardModal}
        title={t("delete_scorecard")}
        message={t("delete_scorecard_message")}
        open={isScorecardDeleteModalOpen}
      />

      <Revisions
        handleClose={() => handleToggleScorecardRevisionsModal(false)}
        revisionsData={revisions}
        linkTo={"scorecards"}
        open={isScorecardRevisionsModalOpen}
        elemId={parseInt(id)}
      />

      <TestingModal
        title={t("testing_scorecard")}
        size={"lg"}
        loading={loading}
        testingData={testScorecardData}
        handleClose={() => handleToggleTestingModal()}
        handleRunTestResultDetailsConfirm={handleRunTestResultConfirm}
        handleRunTestResultSuccessConfirm={handleRunTestResultSuccessConfirm}
        runTestResultDetailsData={runTestResultDetailsData}
        runTestResultStatusData={runTestResultStatusData}
        open={isTestingModalOpen}
        isScorecard={true}
        vectorsData={vectorsDataClone}
      />

      <ExplanationModal
        open={isExplanationModalOpen}
        handleClose={() => setIsExplanationModalOpen(false)}
        handleTryAgain={handleTryAgain}
        explanation={explanation}
        isLoadingExplanation={isLoadingExplanation}
      />
    </>
  );
};

export default Scorecard;
