import { handleActions } from "redux-actions";
import {
  changeIsSignUpSuccess,
  forgotPasswordFailure,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  getAuthUserFailure,
  getAuthUserRequest,
  getAuthUserSuccess,
  getLanguagesFailure,
  getLanguagesRequest,
  getLanguagesSuccess,
  getLoginFailure,
  getLoginRequest,
  getLoginSuccess,
  requireOTP,
  resetOTPRequirement,
  resendOTPRequest,
  resendOTPSuccess,
  resendOTPFailure,
  signUsingOTPRequest,
  signUsingOTPSuccess,
  signUsingOTPFailure,
  signOutFailure,
  signOutRequest,
  signOutSuccess,
  signUpFailure,
  signUpRequest,
  signUpSuccess,
  updateAuthPasswordFailure,
  updateAuthPasswordRequest,
  updateAuthPasswordSuccess,
  updateSettingsFailure,
  updateSettingsRequest,
  updateSettingsSuccess,
} from "redux/auth/action";

const initialState = {
  user: {},
  isGetLoginSuccess: false,
  isGetLoginFailure: false,
  isRequireOTP: false,
  isResendOTPRequestSuccess: false,
  isResendOTPRequestFailure: false,
  isGetAuthUserSuccess: false,
  isGetAuthUserFailure: false,
  isForgotPasswordSuccess: false,
  isForgotPasswordFailure: false,
  isSignUpRequest: false,
  isSignUpSuccess: false,
  isSignUpFailure: false,
  isSignOutRequest: false,
  isSignOutFailure: false,
  isChangePasswordSuccess: false,
  isChangePasswordFailure: false,
  isGetLanguagesRequest: false,
  isGetLanguagesSuccess: false,
  isGetLanguagesFailure: false,
  authUserData: {},
};

const reducer = handleActions(
  {
    // login
    [getLoginRequest]: (state) => ({
      ...state,
      isGetLoginSuccess: false,
      isGetLoginFailure: false,
    }),
    [getLoginSuccess]: (state, { payload }) => ({
      ...state,
      authUserData: payload,
      isGetLoginSuccess: true,
      isGetLoginFailure: false,
    }),
    [getLoginFailure]: (state) => ({
      ...state,
      isGetLoginSuccess: false,
      isGetLoginFailure: true,
    }),
    //require otp
    [requireOTP]: (state) => ({
      ...state,
      isGetLoginSuccess: false,
      isGetLoginFailure: false,
      isRequireOTP: true,
    }),
    [resetOTPRequirement]: (state) => ({
      ...state,
      isRequireOTP: false,
    }),
    // sign using otp
    [signUsingOTPRequest]: (state) => ({
      ...state,
      isSignUsingOTPSuccess: false,
      isSignUsingOTPFailure: false,
    }),
    [signUsingOTPSuccess]: (state) => ({
      ...state,
      isSignUsingOTPSuccess: true,
      isSignUsingOTPFailure: false,
    }),
    [signUsingOTPFailure]: (state) => ({
      ...state,
      isSignUsingOTPSuccess: false,
      isSignUsingOTPFailure: true,
    }),
    // register
    [signUpRequest]: (state) => ({
      ...state,
      isSignUpRequest: true,
      isSignUpSuccess: false,
      isSignUpFailure: false,
    }),
    [signUpSuccess]: (state) => ({
      ...state,
      isSignUpRequest: false,
      isSignUpSuccess: true,
      isSignUpFailure: false,
    }),
    [signOutRequest]: (state, { payload }) => ({
      token: payload,
      isSignOutRequest: true,
      isSignOutFailure: false,
    }),
    [signOutSuccess]: (state) => ({
      ...state,
      isSignOutFailure: false,
    }),
    [signOutFailure]: () => ({
      isGetLoginSuccess: false,
      isSignOutFailure: true,
    }),
    [changeIsSignUpSuccess]: (state) => ({
      ...state,
      isSignUpSuccess: false,
    }),
    [signUpFailure]: (state, { payload }) => ({
      ...state,
      isSignUpRequest: false,
      isSignUpSuccess: false,
      isSignUpFailure: true,
      statueCode: payload.status,
    }),
    // forgot password
    [forgotPasswordRequest]: (state) => ({
      ...state,
      isForgotPasswordSuccess: false,
      isForgotPasswordFailure: false,
    }),
    [forgotPasswordSuccess]: (state) => ({
      ...state,
      isForgotPasswordSuccess: true,
      isForgotPasswordFailure: false,
    }),
    [forgotPasswordFailure]: (state) => ({
      ...state,
      isForgotPasswordSuccess: false,
      isForgotPasswordFailure: true,
    }),
    // change user password
    [updateAuthPasswordRequest]: (state) => ({
      ...state,
      isChangePasswordSuccess: false,
      isChangePasswordFailure: false,
    }),
    [updateAuthPasswordSuccess]: (state) => ({
      ...state,
      isChangePasswordSuccess: true,
      isChangePasswordFailure: false,
    }),
    [updateAuthPasswordFailure]: (state) => ({
      ...state,
      isChangePasswordSuccess: false,
      isChangePasswordFailure: true,
    }),
    // change user settings
    [updateSettingsRequest]: (state) => ({
      ...state,
      isChangeSettingsSuccess: false,
      isChangeSettingsFailure: false,
    }),
    [updateSettingsSuccess]: (state) => ({
      ...state,
      isChangeSettingsSuccess: true,
      isChangeSettingsFailure: false,
    }),
    [updateSettingsFailure]: (state) => ({
      ...state,
      isChangeSettingsSuccess: false,
      isChangeSettingsFailure: true,
    }),
    // get auth user
    [getAuthUserRequest]: (state) => ({
      ...state,
      user: {},
      isGetAuthUserSuccess: false,
      isGetAuthUserFailure: false,
    }),
    [getAuthUserSuccess]: (state, { payload }) => ({
      ...state,
      isGetAuthUserSuccess: true,
      isGetAuthUserFailure: false,
      user: payload,
    }),
    [getAuthUserFailure]: (state) => ({
      ...state,
      isGetAuthUserSuccess: false,
      isGetAuthUserFailure: true,
    }),
    [getLanguagesRequest]: (state) => ({
      ...state,
      isGetLanguagesSuccess: false,
      isGetLanguagesFailure: false,
    }),
    [getLanguagesSuccess]: (state, { payload }) => ({
      ...state,
      isGetLanguagesSuccess: true,
      isGetLanguagesFailure: false,
      languages: payload,
    }),
    [getLanguagesFailure]: (state) => ({
      ...state,
      isGetLanguagesSuccess: false,
      isGetLanguagesFailure: true,
    }),
    [resendOTPRequest]: (state) => ({
      ...state,
      isResendOTPRequestSuccess: false,
      isResendOTPRequestFailure: false,
    }),
    [resendOTPSuccess]: (state) => ({
      ...state,
      isResendOTPRequestSuccess: true,
      isResendOTPRequestFailure: false,
    }),
    [resendOTPFailure]: (state) => ({
      ...state,
      isResendOTPRequestSuccess: false,
      isResendOTPRequestFailure: true,
    }),
  },
  initialState
);

export default reducer;
