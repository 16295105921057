import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import AutoSuggestInput from "components/autosuggest";
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";

const Variable = ({
  index,
  variable,
  vectorsData,
  handleShowVariableDeleteModal,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: variable.model_variable_id });

  const style = {
    transform: CSS.Transform.toString(transform),
    touchAction: "none",
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      style={style}
      id={`variable-${variable.model_variable_id}`}
      key={variable.model_variable_id}
      className="border-sm cursor-move flex-column flex-md-row mb-4 mb-md-2 mt-2 p-3 p-md-0 row"
    >
      <div
        style={{ minWidth: "50px", marginLeft: "15px" }}
        className="align-items-center border cursor-move d-flex justify-content-center p-2 p-md-0 text-center"
        {...listeners}
      >
        {index + 1}
      </div>
      <div className="col-12 col-md-3 mt-3 mt-md-0 pr-0 pr-md-3">
        <input
          type="text"
          name={`model_variable[${variable.model_variable_id}][name]`}
          className="form-control"
          defaultValue={variable.name}
          maxLength={130}
        />
        <input
          type="hidden"
          name={`model_variable[${variable.model_variable_id}][model_variable_id]`}
          className="form-control"
          value={variable.model_variable_id}
        />
      </div>
      <div className="col-12 col-md-2 mt-3 mt-md-0 pr-0 pr-md-3">
        <select
          name={`model_variable[${variable.model_variable_id}][data_type]`}
          className="form-control"
          defaultValue={variable.data_type}
        >
          <option value="STRING">string</option>
          <option value="INTEGER">integer</option>
          <option value="NUMBER">number</option>
          <option value="DATE">date</option>
        </select>
      </div>
      <div className="col mt-3 mt-md-0 pr-0 pr-md-3">
        <AutoSuggestInput
          isModel={true}
          vectorsData={vectorsData}
          defaultValue={variable.mapping || ""}
          inputName={`model_variable[${variable.model_variable_id}][mapping]`}
        />
      </div>
      <div className="col-12 col-md-1 mr-0 mt-3 mt-md-0 pr-0 pr-md-3 text-md-left text-right">
        <button
          className="btn outline"
          type="button"
          onClick={() =>
            handleShowVariableDeleteModal(variable.model_variable_id)
          }
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};

Variable.propTypes = {
  index: PropTypes.number,
  variable: PropTypes.object,
  vectorsData: PropTypes.array,
  handleShowVariableDeleteModal: PropTypes.func,
};

export default Variable;
