import moment from "moment";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { s } from "react-dom-factories";
import { isObject } from "lodash";

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const getCurrentYear = (format = "YYYY", date = new Date()) => {
  return moment(date).format(format);
};

export const dashboardCards = (overview) => {
  return [
    {
      id: 1,
      size: "col-xl-3",
      color: "text-primary",
      title: "Calls",
      titleTranslate: "calls",
      key: "sub_title",
      description: overview?.cnt_request,
    },
    {
      id: 2,
      size: "col-xl-3",
      color: "text-info",
      title: "average response",
      titleTranslate: "average_response",
      key: "sub_title",
      description: overview?.avg_total_duration,
    },
    {
      id: 3,
      size: "col-xl-2",
      color: "text-success",
      title: "data source calls",
      titleTranslate: "data_source_calls",
      key: "sub_title",
      description: overview?.cnt_ext_data_requests,
    },
    {
      id: 4,
      size: "col-xl-2",
      color: "text-warning",
      title: "data source success rate",
      titleTranslate: "data_source_success_rate",
      key: "sub_title",
      description: overview?.rate_ext_data_success,
    },
    {
      id: 5,
      size: "col-xl-2",
      color: "text-warning",
      title: "data source response speed",
      titleTranslate: "data_source_response_speed",
      key: "sub_title",
      description: overview?.avg_ext_data_duration,
    },
  ];
};

export const sampleSizes = [
  {
    label: "10",
    value: "10",
  },
  {
    label: "100",
    value: "100",
  },
  {
    label: "1000",
    value: "1000",
  },
  {
    label: "10000",
    value: "10000",
  },
];

export const syntheticOptions = [
  {
    label: "First Name",
    value: "firstName",
  },
  {
    label: "Last Name",
    value: "lastName",
  },
  {
    label: "City",
    value: "city",
  },
  {
    label: "Country",
    value: "country",
  },
  {
    label: "Street Name",
    value: "streetName",
  },
  {
    label: "latitude",
    value: "latitude",
  },
  {
    label: "longitude",
    value: "longitude",
  },
];

export const operators = [
  {
    value: "plus",
    label: "+",
  },
  {
    value: "minus",
    label: "-",
  },
  {
    value: "division",
    label: "/",
  },
  {
    value: "multi",
    label: "*",
  },
  {
    value: "mod",
    label: "%",
  },
  {
    value: "power",
    label: "^",
  },
  {
    value: "delete",
    label: "Delete",
    divider: true,
  },
];

export const contextMenu = [
  {
    id: 1,
    value: "v",
    label: "Value",
  },
  {
    id: 2,
    value: "a",
    label: "Variable",
  },
  {
    id: 3,
    value: "b",
    label: "( ... )",
  },
  {
    id: 4,
    value: "c",
    label: "Constants",
    children: ["e", "pi", "rand"],
  },
  {
    id: 5,
    value: "f",
    label: "Mathematical function",
    children: [
      "sin",
      "sinh",
      "arcsin",
      "arcsinh",
      "cos",
      "cosh",
      "arccos",
      "arccosh",
      "tan",
      "tanh",
      "arctan",
      "arctanh",
      "sqrt",
      "abs",
      "ln",
      "log",
      "date_to_timestamp",
    ],
  },
  {
    id: 6,
    value: "delete",
    label: "Delete",
    divider: true,
  },
];

/** -------------- initial formula data -------------- **/

export const defaultFceItems = (itemId) => [
  {
    children: [],
    parent_fce_item_id: itemId,
    fce_item_id: `new_${Math.round(Math.random() * 10000)}`,
    item_type: "v",
    operator: null,
    numeric_value: "1",
  },
  {
    children: [],
    parent_fce_item_id: itemId,
    fce_item_id: `new_${Math.round(Math.random() * 10000)}`,
    item_type: "o",
    operator: "+",
    numeric_value: "0",
  },
  {
    children: [],
    parent_fce_item_id: itemId,
    fce_item_id: `new_${Math.round(Math.random() * 10000)}`,
    item_type: "v",
    operator: null,
    numeric_value: "1",
  },
];

export const defaultMathematicalFunction = (itemId) => [
  {
    children: [],
    parent_fce_item_id: itemId,
    fce_item_id: `new_${Math.round(Math.random() * 10000)}`,
    item_type: "v",
    operator: null,
    numeric_value: "1",
  },
];

export const newAttribute = {
  name: "",
  attribute_path: null,
  fce_attribute_id: null,
  is_new: true,
  isDefault: true,
};

export const items = {
  children: [
    {
      children: [],
      parent_fce_item_id: null,
      fce_item_id: "new_0",
      item_type: "v",
      operator: "+",
      numeric_value: "1",
    },
    {
      children: [],
      parent_fce_item_id: null,
      fce_item_id: "new_1",
      item_type: "o",
      operator: "+",
      numeric_value: "0",
    },
    {
      children: [],
      parent_fce_item_id: null,
      fce_item_id: "new_2",
      item_type: "v",
      operator: "+",
      numeric_value: "1",
    },
  ],
  parent_fce_item_id: null,
  fce_item_id: null,
  item_type: "b",
  operator: null,
  numeric_value: null,
};

/** -------------- initial formula data -------------- **/

export const inputAdder = (length, operator, fceId = null) => {
  const fceItem = [
    {
      children: [],
      parent_fce_item_id: fceId,
      fce_item_id: `new_${Math.round(Math.random() * 10000)}`,
      item_type: "v",
      operator: null,
      numeric_value: "1",
    },
  ];
  if (length > 0) {
    const itemData = [
      {
        children: [],
        parent_fce_item_id: fceId,
        fce_item_id: `new_${Math.round(Math.random() * 10000)}`,
        item_type: "o",
        operator: operator,
        numeric_value: "1",
      },
    ];
    return itemData.concat(fceItem);
  }

  return fceItem;
};

export const getStructuredItems = (
  items = [],
  parentId = null,
  structure = []
) => {
  structure[parentId] = [];
  let children = [];
  items.map((item) => {
    if (item.item_type !== null && item.parent_fce_item_id === parentId) {
      structure[item.parent_fce_item_id].push(item);
      const i = structure[item.parent_fce_item_id].length - 1;

      children = getStructuredItems(items, item.fce_item_id, structure);
      if (children.length > 0) {
        structure[item.parent_fce_item_id][i].children = children;
      } else structure[item.parent_fce_item_id][i].children = [];
    }
  });

  children = structure[parentId];

  return children;
};

export const defaultFormulaItem = {
  children: [],
  parent_fce_item_id: null,
  fce_item_id: null,
  item_type: "b",
  operator: null,
  numeric_value: null,
};

export const JsonToFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      JsonToFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
};

export const sortByTitle = (a, b) => {
  a = a.title.toLowerCase();
  b = b.title.toLowerCase();
  return a < b ? -1 : a > b ? 0 : 1;
};

export const sortById = (a, b) => {
  a = a.workflow_step_id;
  b = b.workflow_step_id;
  return a < b ? -1 : a > b ? 0 : 1;
};

export const sortByType = (a, b) => {
  const a_role = a.name === "start" ? 0 : 1;
  const b_role = b.name === "start" ? 0 : 1;
  return a_role < b_role ? -1 : a_role > b_role ? 1 : 0;
};

export const sortByName = (a, b) => {
  const a_item = a.name.toLowerCase();
  const b_item = b.name.toLowerCase();
  return a_item < b_item ? -1 : a_item > b_item ? 0 : 1;
};

export const sortByTimeDesc = (a, b) => {
  const a_item = a.dtime_inserted;
  const b_item = b.dtime_inserted;
  return a_item > b_item ? -1 : a_item < b_item ? 0 : 1;
};

export const sortByMapping = (a, b) => {
  const a_item = a.mapping.toLowerCase();
  const b_item = b.mapping.toLowerCase();
  return a_item < b_item ? -1 : a_item > b_item ? 0 : 1;
};

export const sortByOrderIndex = (a, b) => {
  const a_item = parseInt(a.order_index);
  const b_item = parseInt(b.order_index);
  return a_item < b_item ? -1 : a_item > b_item ? 0 : 1;
};

export const sortByOrder = (a, b) => {
  const a_item = parseInt(a.column_index);
  const b_item = parseInt(b.column_index);
  return a_item < b_item ? -1 : a_item > b_item ? 0 : 1;
};

export const sortByRole = (a, b) => {
  const a_role = a.role.toLowerCase();
  const b_role = b.role.toLowerCase();
  return a_role < b_role ? -1 : a_role > b_role ? 1 : 0;
};

export const compareColumns = (a, b) => {
  if (a.column_index < b.column_index) {
    return -1;
  } else if (a.column_index > b.column_index) {
    return 1;
  }

  if (a.role === "condition" && b.role === "result") {
    return -1;
  } else if (a.role === "result" && b.role === "condition") {
    return 1;
  }

  return 0;
};

export const Loader = () => {
  return (
    <div className="ml-3">
      <div className="spinner-grow text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-dark" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export const chartOption = {
  title: {
    text: "",
  },
  tooltip: {},
  legend: {
    data: [],
  },
  xAxis: {
    data: [],
  },
  yAxis: {},
  series: [],
};

export const chartEmpty = {
  title: {
    show: true,
    textStyle: {
      color: "grey",
      fontSize: 20,
    },
    text: "No Requests :(",
    left: "center",
    top: "center",
  },
  xAxis: {
    show: false,
  },
  yAxis: {
    show: false,
  },
  series: [],
};

export const loadingOption = {
  text: "",
  color: "#0F3356",
  maskColor: "none",

  // Font size. Available since `v4.8.0`.
  fontSize: 12,
  // Show an animated "spinner" or not. Available since `v4.8.0`.
  showSpinner: true,
  // Radius of the "spinner". Available since `v4.8.0`.
  spinnerRadius: 20,
  // Line width of the "spinner". Available since `v4.8.0`.
  lineWidth: 4,
  // Font thick weight. Available since `v5.0.1`.
  fontWeight: "normal",
  // Font style. Available since `v5.0.1`.
  fontStyle: "normal",
  // Font family. Available since `v5.0.1`.
  fontFamily: "sans-serif",
};

export const isJson = (json) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return false;
  }
};

export const getModelStatus = (status = "d", editMode = false) => {
  const statuses = [];
  statuses["n"] = "new";
  statuses["s"] = "preparing";
  statuses["d"] = "ready";
  statuses["f"] = editMode ? "failed" : "problem";

  return statuses[status];
};

export const getBatchTaskStatus = (status = "d") => {
  const statuses = [];
  statuses["d"] = {
    status: "done",
    color: "text-success",
  };
  statuses["s"] = {
    status: "scheduled",
    color: "text-primary",
  };
  statuses["f"] = {
    status: "failed",
    color: "text-danger",
  };
  statuses["c"] = {
    status: "canceled",
    color: "text-secondary",
  };

  return (
    <span className={statuses[status]?.color}>{statuses[status]?.status}</span>
  );
};

export const generateCloudInstanceName = () => {
  // list of meaningful adjectives to use in the instance name
  const adjectives = [
    "cloudy",
    "skyblue",
    "stormy",
    "lightning-fast",
    "thunderous",
    "rainy",
    "sunny",
    "hazy",
    "decisive",
    "modeled",
    "predicted",
    "forecasted",
    "estimated",
    "strategic",
    "planned",
    "probable",
    "analyzed",
    "judged",
    "insightful",
    "anticipative",
    "prescient",
    "foresighted",
    "intuitive",
    "discerning",
    "wise",
    "solicitous",
  ];

  // list of meaningful nouns to use in the instance name1
  const nouns = [
    "cloud",
    "sky",
    "storm",
    "lightning",
    "thunder",
    "rain",
    "sun",
    "haze",
    "rule",
    "decision",
    "model",
    "prediction",
    "inference",
    "forecast",
    "estimation",
    "strategy",
    "plan",
    "choice",
    "probability",
    "outcome",
    "conclusion",
    "analysis",
    "judgment",
    "insight",
    "forethought",
    "anticipation",
    "prescience",
    "foresight",
    "intuition",
    "instinct",
    "discernment",
    "wisdom",
    "sense",
  ];

  // generate a random number between 1000 and 9999
  const randomNumber = Math.floor(Math.random() * 9000) + 1000;

  // choose a random adjective and a random noun from the lists
  const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const noun = nouns[Math.floor(Math.random() * nouns.length)];

  // create the instance name by concatenating the adjective, the noun, and the random number
  return `${adjective}-${noun}-${randomNumber}`;
};

export const generateWorkflowInstanceName = () => {
  // list of meaningful adjectives to use in the instance name
  const adjectives = [
    "cloudy",
    "skyblue",
    "stormy",
    "lightning-fast",
    "thunderous",
    "rainy",
    "sunny",
    "hazy",
    "decisive",
    "modeled",
    "predicted",
    "forecasted",
    "estimated",
    "strategic",
    "planned",
    "probable",
    "analyzed",
    "judged",
    "insightful",
    "anticipative",
    "prescient",
    "foresighted",
    "intuitive",
    "discerning",
    "wise",
    "solicitous",
  ];

  // list of meaningful nouns to use in the instance name1
  const nouns = [
    "cloud",
    "sky",
    "storm",
    "lightning",
    "thunder",
    "rain",
    "sun",
    "haze",
    "rule",
    "decision",
    "model",
    "prediction",
    "inference",
    "forecast",
    "estimation",
    "strategy",
    "plan",
    "choice",
    "probability",
    "outcome",
    "conclusion",
    "analysis",
    "judgment",
    "insight",
    "forethought",
    "anticipation",
    "prescience",
    "foresight",
    "intuition",
    "instinct",
    "discernment",
    "wisdom",
    "sense",
  ];

  // generate a random number between 1000 and 9999
  const randomNumber = Math.floor(Math.random() * 9000) + 1000;

  // choose a random adjective and a random noun from the lists
  const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const noun = nouns[Math.floor(Math.random() * nouns.length)];

  const title = `${adjective.charAt(0).toUpperCase() + adjective.slice(1)}  ${
    noun.charAt(0).toUpperCase() + noun.slice(1)
  }`;

  const name = `${adjective}-${noun}-${randomNumber}`;

  // create the instance name by concatenating the adjective, the noun, and the random number
  return { name, title };
};

export const generateEndpointApiName = () => {
  // list of meaningful adjectives to use in the instance name
  const adjectives = [
    "cloud",
    "server",
    "endpoint",
    "decision",
    "computing",
    "network",
    "service",
    "api",
    "gateway",
    "management",
    "virtualization",
    "scalability",
    "availability",
    "automation",
    "orchestration",
    "analytics",
    "monitoring",
    "balancing",
    "hybrid",
    "edge",
    "block",
    "intelligence",
    "learning",
    "predictive",
    "microservice",
    "container",
    "route",
    "navigation",
    "map",
    "junction",
    "intersection",
    "turn",
    "lane",
    "highway",
    "bypass",
    "pathway",
    "trail",
    "connectivity",
    "access",
    "mobility",
    "traffic",
    "commute",
    "destination",
  ];

  // generate a random number between 1000 and 9999
  const randomNumber = Math.floor(Math.random() * 9000) + 1000;

  // choose a random adjective and a random noun from the lists
  const first = adjectives[Math.floor(Math.random() * adjectives.length)];
  const second = adjectives[Math.floor(Math.random() * adjectives.length)];

  // create the instance name by concatenating the adjective, the noun, and the random number
  return `${first}-${second}-${randomNumber}`;
};

export const generateUniqueFourDigitNumber = () => {
  const currentDate = new Date();
  const timestamp = currentDate.getTime();
  const randomNum = Math.floor(Math.random() * 9000) + 1000;

  return timestamp.toString().slice(-4) + randomNum;
};

export const getVectors = (
  vectorsData,
  setVectorsData,
  suggestData = false
) => {
  if (vectorsData?.length > 0 && vectorsData[0].vector_attributes) {
    const vectorAttributes = vectorsData[0].vector_attributes.map((vector) => ({
      value: (suggestData ? vector.attribute_path : vector) || "",
      entity_title: (suggestData ? vector.entity : "") || "",
      dummy_value: (suggestData ? vector.dummy_value : "") || "",
      label: vector,
    }));
    setVectorsData(vectorAttributes);
  }
  if (
    isObject(vectorsData) &&
    vectorsData.vector_attributes &&
    vectorsData.vector_attributes.length > 0
  ) {
    const vectorAttributes = vectorsData.vector_attributes.map((vector) => ({
      value: (suggestData ? vector.attribute_path : vector) || "",
      entity_title: (suggestData ? vector.entity : "") || "",
      dummy_value: (suggestData ? vector.dummy_value : "") || "",
      label: vector,
    }));
    setVectorsData(vectorAttributes);
  }
};

export const TableNoItems = ({ colspan = 4 }) => {
  const { t } = useTranslation();
  return (
    <tr>
      <td colSpan={colspan} className="text-center">
        {t("no_items_here")}
      </td>
    </tr>
  );
};

TableNoItems.propTypes = {
  colspan: PropTypes.number,
  message: PropTypes.string,
};

export const Pagination = ({
  currentPage,
  itemsLength = 0,
  setCurrentPage,
  isScrollTo = true,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (isScrollTo) {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, 200);
    }
  }, [currentPage]);

  const handleChangePage = (isPrev = false) => {
    setCurrentPage(isPrev ? currentPage - 1 : currentPage + 1);
  };

  return (
    <div
      className={`pagination justify-content-end mb-5 ${
        isScrollTo ? "mt-4" : ""
      }`}
    >
      {currentPage > 0 && (
        <button
          type="button"
          className="page-link outline"
          onClick={() => handleChangePage(true)}
        >
          {t("previous")}
        </button>
      )}
      {itemsLength !== 0 && (
        <button
          type="button"
          className="page-link outline"
          onClick={() => handleChangePage()}
        >
          {t("next")}
        </button>
      )}
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  itemsLength: PropTypes.number,
  setCurrentPage: PropTypes.func,
  isScrollTo: PropTypes.bool,
};

export const getFirstObject = (json, path) => {
  const keys = path.split(".").slice(1);

  let result = json;
  for (const key of keys) {
    if (result[key] !== undefined && result[key] !== null) {
      result = result[key];
    } else {
      result = 0;
      break;
    }
  }

  return result;
};

export const recursionChildren = (data, itemId, value) => {
  if (!Object.keys(data)) {
    return data;
  }
  data?.children?.forEach((item) => {
    if (item.fce_item_id === itemId) {
      item.numeric_value = value;
      return item;
    }

    recursionChildren(item, itemId, value);
  });
  return data;
};

function flattenArrayWithChildren(arr) {
  let result = [];

  if (arr?.length) {
    for (const obj of arr) {
      result.push(obj);
      if (obj.children && obj.children.length > 0) {
        result = result.concat(flattenArrayWithChildren(obj.children));
      }
    }
  }
  return result;
}

export const ruleDataConvertJson = (
  data,
  ruleSetData,
  isRuleSet = false,
  isCopy,
  isEditOption
) => {
  const title = isRuleSet ? ruleSetData.title : data.get("title");
  const name = isRuleSet ? ruleSetData.name : data.get("name");
  const rules = ruleSetData.rules ? ruleSetData.rules : ruleSetData.rule;

  return {
    title: isCopy ? `Copy of ${title}` : title,
    name: isCopy ? `copy-${name}` : name,
    rule: rules?.map((rule) => {
      const flattenedArray = flattenArrayWithChildren(rule.condition);
      return {
        title: isRuleSet
          ? rule.title
          : data.get(`rule[${rule.rule_id}][title]`) || "New rule",
        name: rule.name || generateCloudInstanceName(),
        type: isRuleSet
          ? rule.type
          : data.get(`rule[${rule.rule_id}][type]`) || "all",
        rule_id: !isEditOption ? rule.rule_id : null,
        order_index: rule.order_index || "0",
        active: data.get(`rule[${rule.rule_id}][active]`) || rule.active || "0",
        execution_type:
          data.get(`rule[${rule.rule_id}][execution_type]`) || "standard",
        attribute_path: data.get(`rule[${rule.rule_id}][attribute_path]`),
        condition: flattenedArray.map((cond) => {
          const returnValue = (name) => {
            return !isRuleSet
              ? data.get(
                  `rule[${rule.rule_id}][condition][${cond.rule_condition_id}][${name}]`
                )
              : cond[name] === 0
              ? "0"
              : cond[name];
          };
          const ruleData = {
            rule_condition_id: isCopy
              ? `new_${cond.rule_condition_id}`
              : cond.rule_condition_id ||
                `new_${parseInt(Date.now() * Math.random())}`,
            parent_condition_id: !isRuleSet
              ? data.get(
                  `rule[${rule.rule_id}][condition][${cond.rule_condition_id}][parent_condition_id]`
                )
              : isCopy && cond.parent_condition_id
              ? `new_${cond.parent_condition_id}`
              : cond.parent_condition_id,
            operator: !isRuleSet
              ? data.get(
                  `rule[${rule.rule_id}][condition][${cond.rule_condition_id}][operator]`
                ) || "greater_than"
              : cond.condition_type_name || cond.operator,
            attribute1_path: !isRuleSet
              ? data.get(
                  `rule[${rule.rule_id}][condition][${cond.rule_condition_id}][attribute1_path]`
                )
              : cond.attribute1_path,
            attribute2_path: !isRuleSet
              ? data.get(
                  `rule[${rule.rule_id}][condition][${cond.rule_condition_id}][attribute2_path]`
                )
              : cond.attribute2_path,
          };

          const names = [
            "attribute1_vector",
            "attribute1_path",
            "attribute1_value",
            "attribute2_vector",
            "attribute2_path",
            "attribute2_value",
          ];

          if (cond.condition_type_name === "group") {
            names.push("logical_operator");
          }

          names.map((name) => {
            if (returnValue(name)) {
              ruleData[name] = returnValue(name);
            }
          });

          return ruleData;
        }),
        action: rule.action?.map((action) => {
          const returnValueAction = (name) => {
            return !isRuleSet
              ? data.get(
                  `rule[${rule.rule_id}][action][${action.rule_action_id}][${name}]`
                )
              : action[name];
          };

          const actionData = {};

          const actionNames = [
            "attribute_path",
            "action_path",
            "action_value",
            "action_type",
            "action_vector",
            "rule_action_id",
          ];

          actionNames.map((actionName) => {
            if (returnValueAction(actionName)) {
              actionData[actionName] = returnValueAction(actionName);
            }
          });
          return actionData;
        }),
      };
    }),
    verbose: ruleSetData.verbose === "1" ? "1" : "0",
  };
};
