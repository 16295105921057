import {
  createDecisionTableRequest,
  updateDecisionTableRequest,
} from "redux/decisionTables/action";

export const createItemCopy = (
  content,
  dispatch,
  templateMode = false,
  copy_of = "Copy of",
  copy
) => {
  const decisionTableDefinition = structuredClone(content);
  const data = new URLSearchParams(new FormData());
  data.set(
    "title",
    !templateMode
      ? `${copy_of} ${decisionTableDefinition.title}`
      : decisionTableDefinition.title
  );
  data.set(
    "name",
    !templateMode
      ? `${copy} -${decisionTableDefinition.name}`
      : decisionTableDefinition.name
  );

  if (
    decisionTableDefinition?.cols?.length > 0 &&
    decisionTableDefinition?.cols[0].decision_table_column_id !== null
  ) {
    decisionTableDefinition?.cols?.forEach((col) => {
      const randomNumber = `new_${Math.round(Math.random() * 10000)}`;
      data.set(`column[${randomNumber}][title]`, col.title);
      data.set(`column[${randomNumber}][attribute_path]`, col.attribute_path);
      data.set(`column[${randomNumber}][role]`, col.role);
    });
  }

  setTimeout(() => {
    dispatch(createDecisionTableRequest({ data }));
  }, 1000);
};

export const updateDecisionTable = (
  decisionTable,
  rows,
  newDecisionTableId,
  dispatch
) => {
  const decisionTableRows = structuredClone(rows);
  const dataRows = new URLSearchParams(new FormData());

  const cloneDecisionTable = structuredClone(decisionTable);
  if (decisionTableRows?.length > 0) {
    decisionTableRows?.forEach((row) => {
      const rowId = `new_${Math.round(Math.random() * 10000)}`;
      if (row.cols?.length > 0) {
        row.cols.forEach((revisionCol, index) => {
          const dtRowColumnId = `new_${Math.round(Math.random() * 10000)}`;
          dataRows.set(`field[${dtRowColumnId}][value_a]`, revisionCol.value_a);
          dataRows.set(`field[${dtRowColumnId}][value_b]`, revisionCol.value_b);
          dataRows.set(
            `field[${dtRowColumnId}][decision_table_column_id]`,
            cloneDecisionTable?.cols[index]?.decision_table_column_id
          );
          dataRows.set(
            `field[${dtRowColumnId}][dt_row_column_id]`,
            dtRowColumnId
          );
          dataRows.set(`field[${dtRowColumnId}][decision_table_row_id]`, rowId);
          dataRows.set(
            `field[${dtRowColumnId}][operator]`,
            revisionCol.operator
          );
        });
      }
      dataRows.set(`row[${rowId}][index]`, row.row_index);
    });
  }

  dispatch(
    updateDecisionTableRequest({ id: newDecisionTableId, data: dataRows })
  );
};
