import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import usePrevious from "utility/hooks/usePrevious";
import {
  dataMarketActivateServiceRequest,
  dataMarketActivateServiceSuccess,
  dataMarketActivateServiceFailure,
} from "redux/dataMarket/action";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import "./ServiceCard.css";

const ServiceCard = ({ service }) => {
  const { setIsLoading } = useContext(MainContext);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    isDataMarketActivateServiceSuccess,
    isDataMarketActivateServiceError,
  } = useSelector((state) => state.dataMarket);

  const prevIsDataMarketActivateServiceSuccess = usePrevious(
    isDataMarketActivateServiceSuccess
  );

  const [showModal, setShowModal] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [apiUsername, setApiUsername] = useState("");
  const [apiPassword, setApiPassword] = useState("");
  const [hasAgreedToTnC, setHasAgreedToTnC] = useState(false);

  const handleActivateClick = () => {
    setShowModal(true); // Correct way to show the modal
  };

  const handleModalClose = () => {
    setShowModal(false); // Correct way to close the modal
  };

  const handleBYOKSubmit = () => {
    setIsLoading(true);
    dispatch(
      dataMarketActivateServiceRequest({
        dama_service_id: service.dama_service_id,
        custom_api_key: apiKey,
        custom_api_user: apiUsername,
        custom_api_password: apiPassword,
      })
    );
  };
  useEffect(() => {
    if (
      isDataMarketActivateServiceSuccess === true &&
      !prevIsDataMarketActivateServiceSuccess
    ) {
      setIsLoading(false);

      setShowModal(false);
    }
  }, [isDataMarketActivateServiceSuccess]);

  //when service activation failed, then show toast notification
  useEffect(() => {
    if (
      isDataMarketActivateServiceError === true &&
      !prevIsDataMarketActivateServiceSuccess
    ) {
      setIsLoading(false);
    }
  }, [isDataMarketActivateServiceError]);

  // Helper function to format price
  const formatPrice = (price) => {
    return price === 0 || price === "Free" || price === undefined
      ? t("Free")
      : `${price} $/call`;
  };

  // Determine service type badge color based on type
  const serviceTypeBadgeColor = (type) => {
    switch (type) {
      case "managed":
        return "badge-primary";
      case "free":
        return "badge-success";
      case "byok":
        return "badge-warning";
      default:
        return "badge-secondary";
    }
  };

  return (
    <div className="card service-card">
      <div className="card-header">
        <h5 className="card-title">{service.title}</h5>
        <div>
          <span
            className={`badge ${serviceTypeBadgeColor(service.service_type)}`}
          >
            {service.service_type}
          </span>
          {service.dama_service_tenant_id || service.isActive ? (
            <span className="badge badge-success ml-2">{t("active")}</span>
          ) : (
            <button
              onClick={() => handleActivateClick(service.dama_service_id)}
              className="btn btn-outline-primary ml-2"
            >
              {t("activate")}
            </button>
          )}
        </div>
      </div>
      <div className="card-body">
        <p className="card-text">{service.description}</p>
        <p className="service-price">{formatPrice(service.price)}</p>
        <p className="service-provider">
          {t("by")}{" "}
          <a
            href={service.providerUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {service.provider_title}
          </a>
        </p>
      </div>
      <>
        <Modal size="md" show={showModal} onHide={handleModalClose}>
          <Modal.Header>
            <h5 className="modal-title">{t("activate_service")}</h5>
            <button type="button" className="close" onClick={handleModalClose}>
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {service.service_type === "byok" &&
                service.secret_type == "password" && (
                  <>
                    <Form.Group controlId="apiUsername">
                      <Form.Label>{t("API Username")}</Form.Label>
                      <Form.Control
                        type="text"
                        value={apiUsername}
                        onChange={(e) => setApiUsername(e.target.value)}
                        placeholder={t("Enter API username")}
                      />
                    </Form.Group>
                    <Form.Group controlId="apiPassword">
                      <Form.Label>{t("API Password")}</Form.Label>
                      <Form.Control
                        type="password"
                        value={apiPassword}
                        onChange={(e) => setApiPassword(e.target.value)}
                        placeholder={t("Enter API password")}
                      />
                    </Form.Group>
                  </>
                )}
              {service.service_type === "byok" &&
                service.secret_type == "key" && (
                  <>
                    <Form.Group controlId="apiKey">
                      <Form.Label>{t("API Key")}</Form.Label>
                      <Form.Control
                        type="text"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                        placeholder={t("Enter API key")}
                      />
                    </Form.Group>
                  </>
                )}
              {service.service_type === "free" && (
                <p>
                  {t("free_service_activation_message", {
                    provider: service.provider_title,
                  })}
                </p>
              )}
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label={t("I agree to the Terms and Conditions")}
                  checked={hasAgreedToTnC}
                  onChange={(e) => setHasAgreedToTnC(e.target.checked)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="" className="outline" onClick={handleModalClose}>
              {t("Close")}
            </Button>
            <Button
              variant=""
              className={`outline option-delete d-flex align-items-center`}
              type="button"
              onClick={handleBYOKSubmit}
            >
              <span>{t("Submit")}</span> {/* Adjust text as needed */}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};

ServiceCard.propTypes = {
  service: PropTypes.shape({
    dama_service_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    dama_service_tenant_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    isActive: PropTypes.bool,
    secret_type: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    providerName: PropTypes.string,
    provider_title: PropTypes.string,
    providerUrl: PropTypes.string,
    service_type: PropTypes.string,
  }).isRequired,
};

export default ServiceCard;
