import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from "react";
import * as RJD from "react-js-diagrams";
import PropTypes, { node } from "prop-types";
import { DTCustomNodeModel } from "components/decisionTrees/drawflow/CustomNode/DTCustomNodeModel";
import { DTCustomLinkModel } from "components/decisionTrees/drawflow/CustomNode/DTCustomLinkModel";
//decision tree context
import { DecisionTreeContext } from "context/DecisionTreeContext";

import usePrevious from "utility/hooks/usePrevious";
import { use } from "react-dom-factories";

const DTDiagramDraw = forwardRef(
  ({ engine, decisionTree, handleCloneChange, isRevision }, ref) => {
    const [zoom, setZoom] = useState(100);

    const canvas = useRef();
    const [offset, setOffset] = useState({});
    const [model, setModel] = useState(new RJD.DiagramModel());
    const [decisionTreeClone, setDecisionTreeClone] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(false);
    const prevZoom = usePrevious(zoom);

    const topMargin = 50;
    const leftMargin = 20;

    const {
      setIsCanvasDragging,
      isCanvasDragging,
      setIsLoading,
      setIsUpdatedStep,
      setIsEdited,
      isEdited,
      isFirstLoad,
      setIsFirstLoad,
    } = React.useContext(DecisionTreeContext);

    model.setZoomLevel(zoom);

    useImperativeHandle(
      ref,
      () => ({
        zoomFit() {
          handleZoomToFit();
        },
        zoom() {
          handleZoom();
        },
        zoomOut() {
          handleZoomOut();
        },
      }),
      []
    );

    //workaround
    const triggerMouseEvent = (eventName, element) => {
      const event = document.createEvent("MouseEvents");
      event.initEvent(eventName, true, true);
      element.dispatchEvent(event);
    };

    useEffect(() => {
      if (Object.keys(offset).length > 0) {
        model.setOffset(offset.offsetX, offset.offsetY);
      }

      if (canvas.current && canvas.current.refs && canvas.current.refs.canvas) {
        canvas.current.refs.canvas.addEventListener(
          "wheel",
          function (e) {
            if (e.ctrlKey) {
              const diagramModel = engine.diagramModel;
              e.preventDefault();
              e.stopPropagation();
              if (
                diagramModel.getZoomLevel() - e.deltaY / 300 > 10 &&
                diagramModel.getZoomLevel() - e.deltaY < 300
              ) {
                diagramModel.setZoomLevel(
                  diagramModel.getZoomLevel() - e.deltaY / 300
                );
                setZoom(diagramModel.getZoomLevel() - e.deltaY / 300);
                engine.enableRepaintEntities([]);
                engine.forceUpdate();
              }
            }
          },
          {
            passive: false,
          }
        );
      }
      engine.setDiagramModel(model);
      engine.forceUpdate();
    }, [engine, model, offset]);

    useEffect(() => {
      if (decisionTree) {
        setDecisionTreeClone(decisionTree);
        processDecisionTreeData(decisionTree);
      }
    }, [decisionTree]);

    useEffect(() => {
      processDecisionTreeData(decisionTreeClone);

      engine.enableRepaintEntities([]);
      engine.forceUpdate();
      //workaround to trigger the refresh event... for some reason it is necessary
      if (canvas.current && canvas.current.refs && canvas.current.refs.canvas) {
        triggerMouseEvent("mousedown", canvas.current.refs.canvas);
        triggerMouseEvent("mouseup", canvas.current.refs.canvas);
      }
    }, [decisionTreeClone]);

    const handleZoomToFit = () => {
      const xFactor = engine.canvas.clientWidth / engine.canvas.scrollWidth;
      const yFactor = engine.canvas.clientHeight / engine.canvas.scrollHeight;
      const zoomFactor = xFactor < yFactor ? xFactor : yFactor;
      const offsetX =
        (engine.canvas.clientWidth - engine.canvas.scrollWidth * zoomFactor) /
        2;
      const offsetY =
        (engine.canvas.clientHeight - engine.canvas.scrollHeight * zoomFactor) /
        2;
      setZoom(zoom * zoomFactor);
      model.setZoomLevel(zoom * zoomFactor);
      model.setOffset(offsetX, Math.max(offsetY, topMargin)); // Ensure at least 50px from the top
      engine.enableRepaintEntities([]);
      engine.forceUpdate();
    };

    const handleZoom = () => {
      const zoomSize = engine.diagramModel.getZoomLevel() + 10;
      engine.diagramModel.setZoomLevel(zoomSize);
      engine.enableRepaintEntities([]);
      setZoom(zoomSize);
      setTimeout(() => {
        engine.forceUpdate();
      });
    };

    const handleZoomOut = () => {
      if (engine.diagramModel.getZoomLevel() > 20) {
        const zoomSize = engine.diagramModel.getZoomLevel() - 10;
        engine.diagramModel.setZoomLevel(zoomSize);
        engine.enableRepaintEntities([]);
        setZoom(zoomSize);
        setTimeout(() => {
          engine.forceUpdate();
        });
      }
    };

    const processDecisionTreeData = (decisionTreeForProcess) => {
      if (!decisionTreeForProcess || !decisionTreeForProcess.nodes) return;

      const localModel = new RJD.DiagramModel();

      // Mapping each node to its children for easy access
      const childMap = {};
      const nodeModelMap = {};
      decisionTreeForProcess.nodes.forEach((node) => {
        nodeModelMap[node.decision_tree_node_id] = new DTCustomNodeModel({
          ...node,
          data: node,
        });
        if (!node.parent_decision_tree_node_id) {
          childMap[node.decision_tree_node_id] = [];
        } else {
          if (!childMap[node.parent_decision_tree_node_id]) {
            childMap[node.parent_decision_tree_node_id] = [];
          }
          childMap[node.parent_decision_tree_node_id].push(
            node.decision_tree_node_id
          );
        }
      });

      //Magic by Reingold–Tilford algorithm
      const layoutTree = (nodeId, x, y) => {
        const nodeModel = nodeModelMap[nodeId];
        nodeModel.x = x + leftMargin;
        nodeModel.y = y + topMargin;
        localModel.addNode(nodeModel);

        const children = childMap[nodeId];
        if (children) {
          const numChildren = children.length;
          let subtreeWidth = 0;
          children.forEach((childId, index) => {
            const childWidth = layoutTree(childId, x + subtreeWidth, y + 150); // assuming fixed depth distance of 150
            subtreeWidth += childWidth + (index < numChildren - 1 ? 100 : 0); // assuming fixed sibling distance of 100
          });
          return Math.max(subtreeWidth, 100); // minimum width of a subtree
        }
        return 100; // width of a node with no children
      };

      const rootNodes = decisionTreeForProcess.nodes.filter(
        (node) => !node.parent_decision_tree_node_id
      );
      let x = 0;
      rootNodes.forEach((rootNode) => {
        const subtreeWidth = layoutTree(rootNode.decision_tree_node_id, x, 0);
        x += subtreeWidth + 100; // space between trees
      });

      // Function to adjust parent node positions
      const adjustParents = (nodeId) => {
        const children = childMap[nodeId];
        if (children && children.length > 0) {
          let minX = Infinity;
          let maxX = -Infinity;
          children.forEach((childId) => {
            adjustParents(childId); // Recursively adjust children first
            const childModel = nodeModelMap[childId];
            minX = Math.min(minX, childModel.x);
            maxX = Math.max(maxX, childModel.x);
          });
          const parentNodeModel = nodeModelMap[nodeId];
          parentNodeModel.x = (minX + maxX) / 2; // Center the parent
          //update in the model
        }
      };

      // Second pass: Adjust parent positions
      rootNodes.forEach((rootNode) => {
        adjustParents(rootNode.decision_tree_node_id);
      });

      // Add links between nodes
      decisionTreeForProcess.nodes.forEach((node) => {
        if (node.parent_decision_tree_node_id) {
          const parentNodeModel =
            nodeModelMap[node.parent_decision_tree_node_id];
          const childNodeModel = nodeModelMap[node.decision_tree_node_id];
          let portOut = parentNodeModel.getPort("right");
          let portIn = childNodeModel.getPort("left");
          if (portOut && portIn) {
            const link = new DTCustomLinkModel();
            link.setSourcePort(portOut);
            link.setTargetPort(portIn);
            link.linkId =
              node.decision_tree_node_id +
              "-" +
              node.parent_decision_tree_node_id;
            localModel.addLink(link);
          }
        }
      });

      setModel(localModel);
      engine.setDiagramModel(localModel);
      model.setZoomLevel(zoom);
      engine.forceUpdate();
      engine.enableRepaintEntities([]);

      setTimeout(() => {
        engine.forceUpdate();
        //zoom to fit
        if (isFirstLoad) {
          handleZoomToFit();
          setIsFirstLoad(false);
        }
      });

      //if revision is true, disable the canvas dragging
      if (isRevision) {
        setIsCanvasDragging(false);
      }
    };

    return (
      <RJD.DiagramWidget
        ref={canvas}
        diagramEngine={engine}
        actions={{
          zoom: false,
          canvasDrag: { isCanvasDragging },
          copy: false,
          selectAll: false,
          deleteItems: false,
          multiselect: false,
          multiselectDrag: false,
          moveItems: false,
        }}
      />
    );
  }
);

DTDiagramDraw.displayName = "DTDiagramDraw";

DTDiagramDraw.propTypes = {
  engine: PropTypes.any.isRequired,
  decisionTree: PropTypes.object,
  handleCloneChange: PropTypes.func,
  isRevision: PropTypes.bool,
};

export default DTDiagramDraw;
