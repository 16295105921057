import { handleActions } from "redux-actions";
import {
  createModelFailure,
  createModelRequest,
  createModelSuccess,
  deleteModelFailure,
  deleteModelRequest,
  deleteModelSuccess,
  deleteModelVariableFailure,
  deleteModelVariableRequest,
  deleteModelVariableSuccess,
  getContainerImagesFailure,
  getContainerImagesRequest,
  getContainerImagesSuccess,
  getContainerPluginsFailure,
  getContainerPluginsRequest,
  getContainerPluginsSuccess,
  getModelFailure,
  getModelRequest,
  getModelRunTestResultStatusFailure,
  getModelRunTestResultStatusRequest,
  getModelRunTestResultStatusSuccess,
  getModelsFailure,
  getModelsRequest,
  getModelsSuccess,
  getModelSuccess,
  getModelTestingResultFailure,
  getModelTestingResultRequest,
  getModelTestingResultSuccess,
  getVariablesFailure,
  getVariablesRequest,
  getVariablesSuccess,
  updateModelFailure,
  updateModelRequest,
  updateModelSuccess,
} from "redux/models/action";

const initialState = {
  models: [],
  model: {},
  variables: [],
  isGetModelsSuccess: false,
  isGetModelsError: false,
  isGetModelSuccess: false,
  isGetModelError: false,
  isGetVariablesSuccess: false,
  isGetVariablesError: false,
  isUpdatedModelSuccess: false,
  isUpdatedModelError: false,
  isDeletedModelSuccess: false,
  isDeletedModelError: false,
  isDeletedModelVariableSuccess: false,
  isDeletedModelVariableError: false,
  isCreatedModelSuccess: false,
  isCreatedModelError: false,
  containerImages: [],
  isGetContainerImagesSuccess: false,
  isGetContainerImagesError: false,
  containerPlugins: [],
  isGetContainerPluginsSuccess: false,
  isGetContainerPluginsError: false,
  newModelId: "",
  isGetModelTestingResultSuccess: false,
  isGetModelTestingResultError: false,
  isModelGetRunTestResultStatusSuccess: false,
  isModelGetRunTestResultStatusError: false,
  runTestResultStatus: {},
  testingResult: {},
};

const reducer = handleActions(
  {
    // get models
    [getModelsRequest]: (state) => ({
      ...state,
      isGetModelsSuccess: false,
      isGetModelsError: false,
    }),
    [getModelsSuccess]: (state, { payload }) => ({
      ...state,
      models: payload || [],
      isGetModelsSuccess: true,
      isGetModelsError: false,
    }),
    [getModelsFailure]: (state) => ({
      ...state,
      isGetModelsSuccess: false,
      isGetModelsError: true,
    }),
    // get model
    [getModelRequest]: (state) => ({
      ...state,
      isGetModelSuccess: false,
      isGetModelError: false,
    }),
    [getModelSuccess]: (state, { payload }) => ({
      ...state,
      model: payload ?? {},
      isGetModelSuccess: true,
      isGetModelError: false,
    }),
    [getModelFailure]: (state) => ({
      ...state,
      isGetModelSuccess: false,
      isGetModelError: true,
    }),
    // get variables
    [getVariablesRequest]: (state) => ({
      ...state,
      isGetVariablesSuccess: false,
      isGetVariablesError: false,
    }),
    [getVariablesSuccess]: (state, { payload }) => ({
      ...state,
      variables: payload,
      isGetVariablesSuccess: true,
      isGetVariablesError: false,
    }),
    [getVariablesFailure]: (state) => ({
      ...state,
      isGetVariablesSuccess: false,
      isGetVariablesError: true,
    }),
    // update model
    [updateModelRequest]: (state) => ({
      ...state,
      isUpdatedModelSuccess: false,
      isUpdatedModelError: false,
    }),
    [updateModelSuccess]: (state) => ({
      ...state,
      isUpdatedModelSuccess: true,
      isUpdatedModelError: false,
    }),
    [updateModelFailure]: (state) => ({
      ...state,
      isUpdatedModelSuccess: false,
      isUpdatedModelError: true,
    }),
    // delete model
    [deleteModelRequest]: (state) => ({
      ...state,
      isDeletedModelSuccess: false,
      isDeletedModelError: false,
    }),
    [deleteModelSuccess]: (state) => ({
      ...state,
      isDeletedModelSuccess: true,
      isDeletedModelError: false,
    }),
    [deleteModelFailure]: (state) => ({
      ...state,
      isDeletedModelSuccess: false,
      isDeletedModelError: true,
    }),
    // delete model variable
    [deleteModelVariableRequest]: (state) => ({
      ...state,
      isDeletedModelVariableSuccess: false,
      isDeletedModelVariableError: false,
    }),
    [deleteModelVariableSuccess]: (state) => ({
      ...state,
      isDeletedModelVariableSuccess: true,
      isDeletedModelVariableError: false,
    }),
    [deleteModelVariableFailure]: (state) => ({
      ...state,
      isDeletedModelVariableSuccess: false,
      isDeletedModelVariableError: true,
    }),
    // create model
    [createModelRequest]: (state) => ({
      ...state,
      isCreatedModelSuccess: false,
      isCreatedModelError: false,
    }),
    [createModelSuccess]: (state, { payload }) => ({
      ...state,
      newModelId: payload ?? "",
      isCreatedModelSuccess: true,
      isCreatedModelError: false,
    }),
    [createModelFailure]: (state) => ({
      ...state,
      isCreatedModelSuccess: false,
      isCreatedModelError: true,
    }),
    // get container images
    [getContainerImagesRequest]: (state) => ({
      ...state,
      isGetContainerImagesSuccess: false,
      isGetContainerImagesError: false,
    }),
    [getContainerImagesSuccess]: (state, { payload }) => ({
      ...state,
      containerImages: payload,
      isGetContainerImagesSuccess: true,
      isGetContainerImagesError: false,
    }),
    [getContainerImagesFailure]: (state) => ({
      ...state,
      isGetContainerImagesSuccess: false,
      isGetContainerImagesError: true,
    }),
    // get container plugins
    [getContainerPluginsRequest]: (state) => ({
      ...state,
      isGetContainerPluginsSuccess: false,
      isGetContainerPluginsError: false,
    }),
    [getContainerPluginsSuccess]: (state, { payload }) => ({
      ...state,
      containerPlugins: payload,
      isGetContainerPluginsSuccess: true,
      isGetContainerPluginsError: false,
    }),
    [getContainerPluginsFailure]: (state) => ({
      ...state,
      isGetContainerPluginsSuccess: false,
      isGetContainerPluginsError: true,
    }),
    // get model testing result
    [getModelTestingResultRequest]: (state) => ({
      ...state,
      isGetModelTestingResultSuccess: false,
      isGetModelTestingResultError: false,
    }),
    [getModelTestingResultSuccess]: (state, { payload }) => ({
      ...state,
      testingResult: payload,
      isGetModelTestingResultSuccess: true,
      isGetModelTestingResultError: false,
    }),
    [getModelTestingResultFailure]: (state) => ({
      ...state,
      isGetModelTestingResultSuccess: false,
      isGetModelTestingResultError: true,
    }),
    // get run test result status
    [getModelRunTestResultStatusRequest]: (state) => ({
      ...state,
      isModelGetRunTestResultStatusSuccess: false,
      isModelGetRunTestResultStatusError: false,
    }),
    [getModelRunTestResultStatusSuccess]: (state, { payload }) => ({
      ...state,
      runTestResultStatus: payload,
      isModelGetRunTestResultStatusSuccess: true,
      isModelGetRunTestResultStatusError: false,
    }),
    [getModelRunTestResultStatusFailure]: (state) => ({
      ...state,
      isModelGetRunTestResultStatusSuccess: false,
      isModelGetRunTestResultStatusError: true,
    }),
  },
  initialState
);

export default reducer;
