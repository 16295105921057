import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import { ToastOptions } from "components/toastify";
import { resetOTPRequirement } from "../../redux/auth/action";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Login = ({ open, handleConfirm, isLoading }) => {
  const { user, isGetLoginSuccess, isGetLoginFailure, isRequireOTP } =
    useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const prevIsGetLoginFailure = usePrevious(isGetLoginFailure);
  const prevIsRequireOTP = usePrevious(isRequireOTP);

  const [secret, setSecret] = useState(false);

  const name = localStorage.getItem("name");
  const email = localStorage.getItem("email");
  const { t } = useTranslation();

  const [isWaiting, setIsWaiting] = useState(false);

  function togglePassword() {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      setSecret(true);
    } else {
      x.type = "password";
      setSecret(false);
    }
  }

  //OTP required
  useEffect(() => {
    if (isRequireOTP) {
      toast.success(t("please_check_your_email_for_otp"), ToastOptions);
      setIsWaiting(false);
      //reset OTP requirement
      dispatch(resetOTPRequirement());
      window.location.href = "/otp-login";
    }
  }, [isRequireOTP]);

  //isGetLoginFailure stop loading
  useEffect(() => {
    if (isGetLoginFailure && prevIsGetLoginFailure !== false) {
      setIsWaiting(false);
    }
  }, [isGetLoginFailure]);

  return (
    <>
      <Modal centered={true} size="sm" show={open} className="login">
        <Modal.Body className="mt-3">
          <form onSubmit={handleConfirm} className="login-modal">
            <div className="text-center px-4">
              <h1 className="login-modal__title mb-3">
                {t("hey")} {name || user?.full_name}, {t("you_still")}
              </h1>
              <input
                type="email"
                name="email"
                className="form-control mb-2"
                placeholder="Email address"
                defaultValue={email || user?.email}
                required
                autoFocus
              />
              <label className="sr-only">{t("password")}</label>
              <div className="input-group">
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control"
                  placeholder={t("password")}
                  required
                />
                <div className="input-group-append">
                  <button
                    title={!secret ? t("show_password") : t("hide_password")}
                    className="form-control"
                    type="button"
                    style={{
                      borderLeft: "none",
                      borderTopLeftRadius: "0",
                      borderBottomLeftRadius: "0",
                    }}
                    onClick={togglePassword}
                  >
                    {secret ? <EyeOffIcon /> : <EyeIcon />}
                  </button>
                </div>
              </div>
              <button className="login-modal__btn my-3" type="submit">
                {isWaiting ? (
                  <div
                    className="spinner-border"
                    style={{
                      width: "1rem",
                      height: "1rem",
                    }}
                    role="status"
                  >
                    <span className="sr-only">{t("loading")}</span>
                  </div>
                ) : (
                  t("log_in")
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

Login.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default Login;
