import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastOptions } from "components/toastify";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import SubHeader from "components/SubHeader";
import DeleteConfirm from "components/modals/DeleteConfirm";
import usePrevious from "utility/hooks/usePrevious";
import {
  deleteEndPointRequest,
  getEndPointsRequest,
} from "redux/endpoints/action";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { MainContext } from "context/contexts";
import { sortByName, TableNoItems } from "utility/utility";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { useTranslation } from "react-i18next";

const EndPoints = () => {
  const { setIsLoading, isMobile } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isGetEndPointsSuccess,
    isGetEndPointsError,
    endPoints,
    isDeletedEndPointSuccess,
    isDeletedEndPointError,
  } = useSelector((state) => state.endPoints);

  const prevIsDeletedEndPointSuccess = usePrevious(isDeletedEndPointSuccess);
  const prevIsGetEndPointsSuccess = usePrevious(isGetEndPointsSuccess);
  const prevIsGetEndPointsError = usePrevious(isGetEndPointsError);
  const prevIsDeletedEndPointError = usePrevious(isDeletedEndPointError);

  const [endPointsClone, setEndPointsClone] = useState([]);
  const [endpointName, setEndpointName] = useState("");
  const [selectedEndPoint, setSelectedEndPoint] = useState({});
  const [isEndPointDeleteModalOpen, setIsEndPointDeleteModalOpen] =
    useState(false);

  useEffect(() => {
    document.title = t("document_title");
    setIsLoading(true);
    dispatch(getEndPointsRequest());
  }, []);

  useEffect(() => {
    if (isGetEndPointsSuccess && prevIsGetEndPointsSuccess === false) {
      setIsLoading(false);
      const endPointsClone = structuredClone(endPoints);
      //if it is array and sortable
      if (endPointsClone.length > 0 && Array.isArray(endPointsClone)) {
        endPointsClone?.sort(sortByName);
      }
      setEndPointsClone(!endPointsClone ? [] : endPointsClone);
    }
  }, [isGetEndPointsSuccess]);

  useEffect(() => {
    if (
      (isGetEndPointsError && prevIsGetEndPointsError === false) ||
      (isDeletedEndPointError && prevIsDeletedEndPointError === false)
    ) {
      if (isDeletedEndPointError) {
        handleClose();
      }
      setIsLoading(false);
    }
  }, [isGetEndPointsError, isDeletedEndPointError]);

  useEffect(() => {
    if (isDeletedEndPointSuccess && prevIsDeletedEndPointSuccess === false) {
      setIsLoading(false);
      setEndPointsClone(
        endPointsClone.filter(
          (endPoint) =>
            endPoint.endpoint_placeholder_id !==
            selectedEndPoint.endpoint_placeholder_id
        )
      );
      handleClose();
      toast.warning(t("endpoint_deleted"), ToastOptions);
    }
  }, [isDeletedEndPointSuccess]);

  const handleShowDeleteModal = (endpoint) => {
    if (endpoint?.block_deletion == 1) {
      //show toast message
      toast.error(t("this_endpoint_cannot_be_deleted"), ToastOptions);
      return;
    }
    setSelectedEndPoint(endpoint);
    setIsEndPointDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsEndPointDeleteModalOpen(false);
    setTimeout(() => {
      setSelectedEndPoint({});
    }, 100);
  };

  const handleConfirm = () => {
    if (selectedEndPoint.name !== endpointName) {
      toast.error(t("endpoint_delete_info"), ToastOptions);
    } else {
      setIsLoading(true);
      dispatch(deleteEndPointRequest(selectedEndPoint.endpoint_placeholder_id));
      setIsEndPointDeleteModalOpen(false);
    }
  };

  return (
    <>
      <SubHeader
        title={t("api_endpoints")}
        actions={
          <Link to="/api-endpoint">
            <button
              className="btn primary-header"
              title={t("add_api_endpoint")}
            >
              <PlusIcon />{" "}
              <span className="ml-2">{t("plus_api_endpoint")}</span>
            </button>
          </Link>
        }
      />
      <div className="mb-5 ">
        <table className="table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow col-lg-3 py-3">
                {t("nav_bar_endpoint")}
              </th>
              <th scope="col" className="th-flow col-lg-3 py-3 ">
                {t("current_deployment")}
              </th>
              {!isMobile && (
                <>
                  <th scope="col" className="th-flow col-lg-2 py-3 ">
                    {t("release_table")}
                  </th>
                  <th scope="col" className="th-flow col-lg-2 py-3 ">
                    {t("instance")}
                  </th>
                  <th scope="col" className="th-flow col-lg-2 py-3 ">
                    {t("region")}
                  </th>
                </>
              )}
              <th scope="col" className="th-flow col-lg-1 py-3 text-right " />
            </tr>
          </thead>
          <tbody>
            {endPointsClone?.length > 0 && Array.isArray(endPointsClone)
              ? endPointsClone.map((endPoint) => {
                  return (
                    <tr
                      key={endPoint.endpoint_placeholder_id}
                      className=" td-flow"
                    >
                      <td>
                        <Link
                          to={`/api-endpoints/${endPoint.endpoint_placeholder_id}`}
                        >
                          {endPoint.name}
                        </Link>
                      </td>
                      <td className=" ">
                        {!endPoint.wf_release_deployment_name ? (
                          <em>{t("deleted")}</em>
                        ) : (
                          endPoint.wf_release_deployment_name
                        )}
                      </td>
                      {!isMobile && (
                        <>
                          <td>
                            {!endPoint.workflow_release_name ? (
                              <em>{t("deleted")}</em>
                            ) : (
                              <Link
                                to={`/releases/${endPoint.workflow_release_id}`}
                                className="function-item"
                              >
                                {endPoint.workflow_release_name}
                              </Link>
                            )}
                          </td>
                          <td>{endPoint.instance_title}</td>
                          <td>{endPoint.region_title}</td>
                        </>
                      )}
                      <td className="d-flex align-items-center justify-content-end border-0">
                        <ReactTooltip
                          type="dark"
                          place="left"
                          effect="solid"
                          id="edit-api-endpoint"
                        >
                          {t("edit")}
                        </ReactTooltip>
                        <button
                          type="button"
                          className="btn primary-text border-0 p-0 pb-1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title={t("delete_endpoint")}
                        >
                          <MoreVertical
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </button>
                        <div
                          className="dropdown-menu table-dropdown"
                          aria-labelledby="dropdownMenuLink"
                        >
                          {isMobile && (
                            <>
                              <Link
                                to={`/api-endpoints/${endPoint.endpoint_placeholder_id}`}
                                className="dropdown-item"
                                title={t("edit_api_endpoint")}
                                data-tip={true}
                                data-for="edit-api-endpoint"
                              >
                                <EditIcon /> {t("edit")}
                              </Link>
                              <div className="dropdown-divider" />
                            </>
                          )}
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleShowDeleteModal(endPoint)}
                            title={t("delete_endpoint")}
                          >
                            <TrashIcon /> {t("delete")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : isGetEndPointsSuccess &&
                endPoints?.length === 0 && <TableNoItems colspan={6} />}
          </tbody>
        </table>
      </div>
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_endpoint")}
        message={
          <>
            <p>
              {t("do_you_really_want_to_delete")} <b>{selectedEndPoint.name}</b>
              ?
            </p>
            <p>
              {t("any_connected_systems_will_start")}{" "}
              <strong> {t("getting_errors")}</strong>
              {t("when_trying_to_call_this_endpoint")}
            </p>
            <label>
              {t("write_below")} <strong>{selectedEndPoint.name}</strong>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={selectedEndPoint.name}
              onChange={(e) => setEndpointName(e.target.value)}
            />
          </>
        }
        icon=""
        open={isEndPointDeleteModalOpen}
      />
    </>
  );
};

export default EndPoints;
