import { call, put, select, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  createDecisionTableFailure,
  createDecisionTableRequest,
  createDecisionTableSuccess,
  deleteDecisionTableFailure,
  deleteDecisionTableRequest,
  deleteDecisionTableRowFailure,
  deleteDecisionTableRowRequest,
  deleteDecisionTableRowSuccess,
  deleteDecisionTableSuccess,
  deleteTableColumnFailure,
  deleteTableColumnRequest,
  deleteTableColumnSuccess,
  exportTableDataFailure,
  exportTableDataRequest,
  exportTableDataSuccess,
  getConditionsFailure,
  getConditionsRequest,
  getConditionsSuccess,
  getDecisionTableFailure,
  getDecisionTableRequest,
  getDecisionTableRevisionFailure,
  getDecisionTableRevisionRequest,
  getDecisionTableRevisionsFailure,
  getDecisionTableRevisionsRequest,
  getDecisionTableRevisionsSuccess,
  getDecisionTableRevisionSuccess,
  getDecisionTableRowsFailure,
  getDecisionTableRowsRequest,
  getDecisionTableRowsSuccess,
  getDecisionTablesFailure,
  getDecisionTablesRequest,
  getDecisionTablesSuccess,
  getDecisionTableSuccess,
  getRunTestObjectResultFailure,
  getRunTestObjectResultRequest,
  getRunTestObjectResultSuccess,
  getRunTestResultStatusTableFailure,
  getRunTestResultStatusTableRequest,
  getRunTestResultStatusTableSuccess,
  getRunTestSimulationResultFailure,
  getRunTestSimulationResultRequest,
  getRunTestSimulationResultSuccess,
  importTableDataFailure,
  importTableDataRequest,
  importTableDataSuccess,
  updateDecisionTableDefinitionFailure,
  updateDecisionTableDefinitionRequest,
  updateDecisionTableDefinitionSuccess,
  updateDecisionTableFailure,
  updateDecisionTableRequest,
  updateDecisionTableSuccess,
  getOverlapValidationRequest,
  getOverlapValidationSuccess,
  getOverlapValidationFailure,
} from "redux/decisionTables/action";
import { useCaseWorkflowRequest } from "../workflows/action";

const useCaseWorkflowSelector = (state) => state.workflows.useCaseWorkflow;

function* getDecisionTables({ payload }) {
  try {
    const response = yield call(
      axios.get,
      payload ? `/decision-tables?page=${payload}` : "/decision-tables"
    );
    if (response.status === 200) {
      yield put(getDecisionTablesSuccess(response.data));
    }
  } catch (e) {
    yield put(getDecisionTablesFailure("e.message"));
  }
}

function* getDecisionTable({ payload }) {
  try {
    const url = `/decision-tables/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getDecisionTableSuccess(response.data));
      const decisionTable = localStorage.getItem("decision_table");
      if (decisionTable) {
        const decisionTableRows = JSON.parse(decisionTable).rows;
        const dataRows = new URLSearchParams(new FormData());
        const cloneDecisionTable = structuredClone(response.data);

        if (decisionTableRows?.length > 0) {
          decisionTableRows?.forEach((row) => {
            const rowId = `new_${Math.round(Math.random() * 10000)}`;
            if (row.cols?.length > 0) {
              row.cols.forEach((revisionCol, index) => {
                const dtRowColumnId = `new_${Math.round(
                  Math.random() * 10000
                )}`;
                dataRows.append(
                  `field[${dtRowColumnId}][value_a]`,
                  revisionCol.value_a
                );
                dataRows.append(
                  `field[${dtRowColumnId}][value_b]`,
                  revisionCol.value_b
                );
                dataRows.append(
                  `field[${dtRowColumnId}][decision_table_column_id]`,
                  cloneDecisionTable?.cols[index]?.decision_table_column_id
                );
                dataRows.append(
                  `field[${dtRowColumnId}][dt_row_column_id]`,
                  dtRowColumnId
                );
                dataRows.append(
                  `field[${dtRowColumnId}][decision_table_row_id]`,
                  rowId
                );
                dataRows.append(
                  `field[${dtRowColumnId}][operator]`,
                  revisionCol.operator
                );
              });
            }
            dataRows.append(`row[${rowId}][index]`, row.row_index);
          });
        }

        yield put(updateDecisionTableRequest({ id: payload, data: dataRows }));
      }
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getDecisionTableFailure("e.message"));
  }
}

function* getConditions({ payload }) {
  try {
    const url = `/decision-tables/${payload}/conditions`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getConditionsSuccess(response.data));
    }
  } catch (e) {
    yield put(getConditionsFailure("e.message"));
  }
}

function* updateDecisionTableFunc({ payload }) {
  try {
    const url = `/decision-tables/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 200) {
      localStorage.removeItem("decision_table");
      yield put(updateDecisionTableSuccess());
    }
  } catch (e) {
    yield put(updateDecisionTableFailure("e.message"));
  }
}

function* getDecisionTableRows({ payload }) {
  try {
    const url = `/decision-tables/${payload}/rows`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getDecisionTableRowsSuccess(response.data));
    }
  } catch (e) {
    yield put(getDecisionTableRowsFailure("e.message"));
  }
}

function* deleteDecisionTableRow({ payload }) {
  try {
    const url = `/decision-tables/${payload.tableId}/rows/${payload.rowId}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteDecisionTableRowSuccess());
    }
  } catch (e) {
    yield put(deleteDecisionTableRowFailure("e.message"));
  }
}

function* getDecisionTableRevisions({ payload }) {
  try {
    const url = `/decision-tables/${payload}/revisions`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getDecisionTableRevisionsSuccess(response.data));
    }
  } catch (e) {
    yield put(getDecisionTableRevisionsFailure("e.message"));
  }
}

function* getDecisionTableRevision({ payload }) {
  try {
    const url = `/decision-tables/${payload.id}/revisions/${payload.revisionId}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getDecisionTableRevisionSuccess(response.data));
    }
  } catch (e) {
    yield put(getDecisionTableRevisionFailure("e.message"));
  }
}

function* importTableData({ payload }) {
  try {
    const url = `/decision-tables/${payload.id}/import`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 202) {
      yield put(importTableDataSuccess());
    }
  } catch (e) {
    yield put(importTableDataFailure("e.message"));
  }
}

function* exportTableData({ payload }) {
  try {
    const url = `/decision-tables/${payload}/export`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(exportTableDataSuccess(response.data));
    }
  } catch (e) {
    yield put(exportTableDataFailure("e.message"));
  }
}

function* getRunTestResultStatus({ payload }) {
  try {
    const url = `/decision-tables/${payload.id}/test`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 200) {
      yield put(getRunTestResultStatusTableSuccess(response.data));
    }
  } catch (e) {
    yield put(getRunTestResultStatusTableFailure("e.message"));
  }
}

function* getRunTestObjectResult({ payload }) {
  try {
    const url = `/decision-tables/${payload.id}/test-object/${payload.vectorId}`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 200) {
      yield put(getRunTestObjectResultSuccess(response.data));
    }
  } catch (e) {
    yield put(getRunTestObjectResultFailure("e.message"));
  }
}

function* getRunTestSimulationResult({ payload }) {
  try {
    const url = `/decision-tables/${payload.id}/sim`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 200) {
      yield put(getRunTestSimulationResultSuccess(response.data));
    }
  } catch (e) {
    yield put(getRunTestSimulationResultFailure("e.message"));
  }
}

function* createDecisionTable({ payload }) {
  try {
    const response = yield call(axios.post, "/decision-tables", payload.data);
    if (response.status === 201) {
      yield put(createDecisionTableSuccess(response.headers.etag));
      if (payload.decision_table_id) {
        const useCaseWorkflowData = yield select(useCaseWorkflowSelector);

        useCaseWorkflowData.workflow_step.find((step) => {
          const option = step.options?.find(
            (option) => option.decision_table_id === payload.decision_table_id
          );
          if (option) {
            option.decision_table_id = response.headers.etag;
          }
        });
        yield put(useCaseWorkflowRequest(useCaseWorkflowData));
      }
      if (payload.useCase) {
        yield put(getDecisionTableRequest(response.headers.etag));
      }
    }
  } catch (e) {
    yield put(createDecisionTableFailure("e.message"));
  }
}

function* updateDecisionTableColumns({ payload }) {
  try {
    const url = `/decision-tables/${payload.id}/columns`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 200) {
      yield put(updateDecisionTableDefinitionSuccess());
    }
  } catch (e) {
    yield put(updateDecisionTableDefinitionFailure("e.message"));
  }
}

function* deleteDecisionTable({ payload }) {
  try {
    const url = `/decision-tables/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteDecisionTableSuccess());
    }
  } catch (e) {
    yield put(deleteDecisionTableFailure("e.message"));
  }
}

function* deleteTableColumn({ payload }) {
  try {
    const url = `/decision-tables/${payload.id}/columns/${payload.columnId}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteTableColumnSuccess());
    }
  } catch (e) {
    yield put(deleteTableColumnFailure("e.message"));
  }
}

//overlap validation
function* getOverlapValidation({ payload }) {
  try {
    const url = `/decision-tables/${payload.id}/overlap-validations`;
    const response = yield call(axios.get, url, payload.data);
    if (response.status === 200) {
      yield put(getOverlapValidationSuccess(response.data));
    }
  } catch (e) {
    yield put(getOverlapValidationFailure("e.message"));
  }
}

export default function* saga() {
  yield takeLatest(getDecisionTablesRequest, getDecisionTables);
  yield takeLatest(getDecisionTableRequest, getDecisionTable);
  yield takeLatest(getConditionsRequest, getConditions);
  yield takeLatest(getDecisionTableRowsRequest, getDecisionTableRows);
  yield takeLatest(deleteDecisionTableRowRequest, deleteDecisionTableRow);
  yield takeLatest(getDecisionTableRevisionsRequest, getDecisionTableRevisions);
  yield takeLatest(getDecisionTableRevisionRequest, getDecisionTableRevision);
  yield takeLatest(importTableDataRequest, importTableData);
  yield takeLatest(exportTableDataRequest, exportTableData);
  yield takeLatest(getRunTestResultStatusTableRequest, getRunTestResultStatus);
  yield takeLatest(getRunTestObjectResultRequest, getRunTestObjectResult);
  yield takeLatest(
    getRunTestSimulationResultRequest,
    getRunTestSimulationResult
  );
  yield takeLatest(
    updateDecisionTableDefinitionRequest,
    updateDecisionTableColumns
  );
  yield takeLatest(updateDecisionTableRequest, updateDecisionTableFunc);
  yield takeLatest(createDecisionTableRequest, createDecisionTable);
  yield takeLatest(deleteDecisionTableRequest, deleteDecisionTable);
  yield takeLatest(deleteTableColumnRequest, deleteTableColumn);
  yield takeLatest(getOverlapValidationRequest, getOverlapValidation);
}
