import { handleActions } from "redux-actions";
import {
  getScorecardsFailure,
  getScorecardsRequest,
  getScorecardsSuccess,
  getScorecardByIdFailure,
  getScorecardByIdRequest,
  getScorecardByIdSuccess,
  createScorecardFailure,
  createScorecardRequest,
  createScorecardSuccess,
  updateScorecardFailure,
  updateScorecardRequest,
  updateScorecardSuccess,
  deleteScorecardFailure,
  deleteScorecardRequest,
  deleteScorecardSuccess,
  getScorecardRevisionsFailure,
  getScorecardRevisionsRequest,
  getScorecardRevisionsSuccess,
  getScorecardRevisionFailure,
  getScorecardRevisionRequest,
  getScorecardRevisionSuccess,
  getTestScorecardFailure,
  getTestScorecardRequest,
  getTestScorecardSuccess,
  getRunTestResultFailure,
  getRunTestResultRequest,
  getRunTestResultSuccess,
  getRunTestResultStatusFailure,
  getRunTestResultStatusRequest,
  getRunTestResultStatusSuccess,
  getScorecardExplanationRequest,
  getScorecardExplanationSuccess,
  getScorecardExplanationFailure,
} from "redux/scorecard/action";

const initialState = {
  scorecards: [],
  scorecard: {},
  revisions: [],
  revision: {},
  testScorecard: {},
  runTestResultStatus: {},
  isFetchingScorecards: false,
  isFetchingScorecard: false,
  isCreatingScorecard: false,
  isUpdatingScorecard: false,
  isDeletingScorecard: false,
  isCreateScorecardSuccess: false,
  isGetScorecardsSuccess: false,
  isUpdateScorecardSuccess: false,
  isDeleteScorecardSuccess: false,
  isGetTestRuleSetSuccess: false,
  isGetRunTestResultDetailsSuccess: false,
  isGetRunTestResultStatusSuccess: false,
  fetchScorecardsError: null,
  fetchScorecardError: null,
  createScorecardError: null,
  updateScorecardError: null,
  deleteScorecardError: null,
  newScorecardId: "",
  isGetScorecardExplanationSuccess: false,
  isGetScorecardExplanationError: false,
  scorecardExplanation: null,
};

const reducer = handleActions(
  {
    // get scorecards
    [getScorecardsRequest]: (state) => ({
      ...state,
      isGetScorecardsSuccess: false,
      isFetchingScorecards: true,
      fetchScorecardsError: null,
    }),
    [getScorecardsSuccess]: (state, { payload }) => ({
      ...state,
      scorecards: payload || [],
      isGetScorecardsSuccess: true,
      isFetchingScorecards: false,
    }),
    [getScorecardsFailure]: (state, { payload }) => ({
      ...state,
      isFetchingScorecards: false,
      fetchScorecardsError: payload,
    }),
    // get scorecard by id
    [getScorecardByIdRequest]: (state) => ({
      ...state,
      isFetchingScorecard: true,
      fetchScorecardError: null,
    }),
    [getScorecardByIdSuccess]: (state, { payload }) => ({
      ...state,
      scorecard: payload,
      isFetchingScorecard: false,
    }),
    [getScorecardByIdFailure]: (state, { payload }) => ({
      ...state,
      isFetchingScorecard: false,
      fetchScorecardError: payload,
    }),
    // create scorecard
    [createScorecardRequest]: (state) => ({
      ...state,
      isCreatingScorecard: true,
      createScorecardError: null,
    }),
    [createScorecardSuccess]: (state, { payload }) => ({
      ...state,
      newScorecardId: payload,
      isCreateScorecardSuccess: true,
      isCreatingScorecard: false,
    }),
    [createScorecardFailure]: (state, { payload }) => ({
      ...state,
      isCreatingScorecard: false,
      createScorecardError: payload,
    }),
    // update scorecard
    [updateScorecardRequest]: (state) => ({
      ...state,
      isUpdatingScorecard: true,
      updateScorecardError: null,
    }),
    [updateScorecardSuccess]: (state, { payload }) => ({
      ...state,
      scorecard: payload,
      isUpdateScorecardSuccess: true,
      isUpdatingScorecard: false,
    }),
    [updateScorecardFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingScorecard: false,
      updateScorecardError: payload,
    }),
    // delete scorecard
    [deleteScorecardRequest]: (state) => ({
      ...state,
      isDeletingScorecard: true,
      isDeleteScorecardSuccess: false,
      deleteScorecardError: null,
    }),
    [deleteScorecardSuccess]: (state) => ({
      ...state,
      isDeleteScorecardSuccess: true,
      isDeletingScorecard: false,
    }),
    [deleteScorecardFailure]: (state, { payload }) => ({
      ...state,
      isDeletingScorecard: false,
      isDeleteScorecardSuccess: false,
      deleteScorecardError: payload,
    }),
    // get scorecard revisions
    [getScorecardRevisionsRequest]: (state) => ({
      ...state,
      isGetScorecardRevisionsSuccess: false,
      isGetScorecardRevisionsError: false,
    }),
    [getScorecardRevisionsSuccess]: (state, { payload }) => ({
      ...state,
      revisions: payload,
      isGetScorecardRevisionsSuccess: true,
      isGetScorecardRevisionsError: false,
    }),
    [getScorecardRevisionsFailure]: (state) => ({
      ...state,
      isGetScorecardRevisionsSuccess: false,
      isGetScorecardRevisionsError: true,
    }),
    // get scorecard revision
    [getScorecardRevisionRequest]: (state) => ({
      ...state,
      isGetScorecardRevisionSuccess: false,
      isGetScorecardRevisionError: false,
    }),
    [getScorecardRevisionSuccess]: (state, { payload }) => ({
      ...state,
      revision: payload,
      isGetScorecardRevisionSuccess: true,
      isGetScorecardRevisionError: false,
    }),
    [getScorecardRevisionFailure]: (state) => ({
      ...state,
      isGetScorecardRevisionSuccess: false,
      isGetScorecardRevisionError: true,
    }),
    // get test scorecard
    [getTestScorecardRequest]: (state) => ({
      ...state,
      isGetTestScorecardSuccess: false,
      isGetTestScorecardError: false,
    }),
    [getTestScorecardSuccess]: (state, { payload }) => ({
      ...state,
      testScorecard: payload,
      isGetTestScorecardSuccess: true,
      isGetTestScorecardError: false,
    }),
    [getTestScorecardFailure]: (state) => ({
      ...state,
      isGetTestScorecardSuccess: false,
      isGetTestScorecardError: true,
    }),
    // get run test result details
    [getRunTestResultRequest]: (state) => ({
      ...state,
      isGetRunTestResultSuccess: false,
      isGetRunTestResultError: false,
    }),
    [getRunTestResultSuccess]: (state, { payload }) => ({
      ...state,
      runTestResultDetails: payload ?? [],
      isGetRunTestResultSuccess: true,
      isGetRunTestResultError: false,
    }),
    [getRunTestResultFailure]: (state) => ({
      ...state,
      isGetRunTestResultSuccess: false,
      isGetRunTestResultError: true,
    }),
    // get run test result status
    [getRunTestResultStatusRequest]: (state) => ({
      ...state,
      isGetTestResultStatusSuccess: false,
      isGetTestResultStatusError: false,
    }),
    [getRunTestResultStatusSuccess]: (state, { payload }) => ({
      ...state,
      runTestResultStatus: payload,
      isGetTestResultStatusSuccess: true,
      isGetTestResultStatusError: false,
    }),
    [getRunTestResultStatusFailure]: (state) => ({
      ...state,
      isGetTestResultStatusSuccess: false,
      isGetTestResultStatusError: true,
    }),
    // get scorecard explanation
    [getScorecardExplanationRequest]: (state) => ({
      ...state,
      isGetScorecardExplanationSuccess: false,
      isGetScorecardExplanationError: false,
    }),
    [getScorecardExplanationSuccess]: (state, { payload }) => ({
      ...state,
      scorecardExplanation: payload.explanation,
      isGetScorecardExplanationSuccess: true,
      isGetScorecardExplanationError: false,
    }),
    [getScorecardExplanationFailure]: (state) => ({
      ...state,
      isGetScorecardExplanationSuccess: false,
      isGetScorecardExplanationError: true,
    }),
  },
  initialState
);

export default reducer;
