import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Route,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthUserRequest } from "redux/auth/action";
import { privateRoutes, publicRoutes } from "routes";
import PageNotFound from "components/PageNotFound";
import MainLayout from "components/layouts/MainLayout";
import RouteListenerLayout from "components/router/RouteListenerLayout";

const Index = () => {
  const dispatch = useDispatch();
  const { isSignUpSuccess, authUserData } = useSelector((state) => state.auth);
  const { isGetAuthUserSuccess } = useSelector((state) => state.auth);

  useEffect(() => {
    if (authUserData && Object.keys(authUserData).length) {
      localStorage.setItem("user_language", authUserData.language_code);
    }
  }, [authUserData]);

  const token = localStorage.getItem("token");
  const prevURL = localStorage.getItem("prevURL");

  useEffect(() => {
    if (
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/signup" &&
      window.location.pathname !== "/reset-password" &&
      window.location.pathname !== "/new-password" &&
      window.location.pathname !== "/otp-login"
    ) {
      localStorage.setItem("prevURL", window.location.pathname);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (token) {
      dispatch(getAuthUserRequest());
    }
  }, [dispatch, token]);

  const routes = [
    {
      path: "/",
      element: <RouteListenerLayout logged={token || isGetAuthUserSuccess} />,
      children: [
        ...privateRoutes.map((route) => ({
          path: route.path,
          element:
            token || isGetAuthUserSuccess ? (
              route.element
            ) : (
              <Navigate to="/login" replace />
            ),
          exact: route.exact,
        })),
        ...publicRoutes.map((route) => ({
          path: route.path,
          element:
            token || isGetAuthUserSuccess ? (
              <Navigate
                to={
                  isSignUpSuccess
                    ? "/workflows"
                    : prevURL
                    ? prevURL
                    : "/dashboard"
                }
                replace
              />
            ) : (
              route.element
            ),
          exact: route.exact,
        })),
        {
          path: "*",
          element:
            token || isGetAuthUserSuccess ? (
              <MainLayout>
                <PageNotFound isLogged={true} />
              </MainLayout>
            ) : (
              <PageNotFound isLogged={false} />
            ),
        },
      ],
    },
  ];

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export default Index;
