import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import PropTypes from "prop-types";
import "assets/css/autosuggest/theme.scss";

const AutoSuggestInput = ({
  vectorsData,
  defaultValue,
  inputName,
  revisionMode = false,
  tableMode = false,
  onInputChange,
  isColumn,
  placeholder,
  isLast,
  isDataSource = false,
  isFunc = false,
  isRuleSet = false,
  isModel = false,
  isFork = false,
  isAction = false,
  isRuleArray = false,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [suggestions, setSuggestions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  function getSuggestions(value) {
    return vectorsData.filter((vector) => {
      const val = (vector?.value || vector.attribute_path || "").toString();
      if (val?.toUpperCase().indexOf(value.toUpperCase()) > -1) {
        return vector;
      }
    });
  }

  const getSuggestionValue = (suggestion) => suggestion.value;

  const renderSuggestion = (suggestion) => {
    return (
      <div title={suggestion?.dummy_value || ""}>
        {suggestion.value}
        {suggestion.entity_title && (
          <>
            <br />
            <small className="secondary">{suggestion.entity_title}</small>
          </>
        )}
      </div>
    );
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    if (isColumn) {
      onInputChange(event);
    } else {
      if (onInputChange) onInputChange(newValue);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const renderInputComponent = ({ key, ...inputProps }) => {
    if (
      (value.charAt(0) === "$" && value.charAt(1) === ".") ||
      ((value === "" || revisionMode) && !tableMode)
    ) {
      inputProps.className = "react-autosuggest__input";
      if (tableMode) {
        inputProps.className =
          "form-control form-control-sm form-control-plaintext";
      }
    } else {
      inputProps.className = "react-autosuggest__input form-control is-invalid";
      if (tableMode) {
        inputProps.className =
          "form-control form-control-sm form-control-plaintext is-invalid";
        if (value === "") {
          inputProps.className =
            "form-control form-control-sm form-control-plaintext";
        }
      }
    }

    return (
      <>
        <input
          key={key}
          disabled={revisionMode ? "disabled" : ""}
          {...inputProps}
        />
      </>
    );
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const style = {
    paddingRight: "0 !important",
    fontSize: "1rem",
    backgroundColor: isLast ? "#F8F9FA" : "",
    borderLeft: isModel || isRuleArray ? "" : "none",
    borderTop: isFunc ? "none" : "",
    color: "#0f3356",
    height:
      isDataSource || isRuleSet || isModel || isFork || isAction ? "38px" : "",
    borderRadius: isModel || isRuleArray ? "4px" : "",
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      onSuggestionSelected={() => {}}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      disabled={revisionMode ? "disabled" : ""}
      inputProps={{
        style: style,
        className: `react-autosuggest__input ${isFunc ? "" : "h-100"}`,
        placeholder: placeholder
          ? placeholder
          : tableMode
          ? "$.some.attribute"
          : "",
        value: value ? value : "",
        onKeyPress: onKeyPress,
        name: inputName,
        type: "input",
        onChange: onChange,
      }}
    />
  );
};

AutoSuggestInput.propTypes = {
  vectorsData: PropTypes.array,
  defaultValue: PropTypes.string,
  inputName: PropTypes.string,
  revisionMode: PropTypes.bool,
  tableMode: PropTypes.bool,
  onInputChange: PropTypes.func,
  isColumn: PropTypes.bool,
  placeholder: PropTypes.string,
  isLast: PropTypes.bool,
  isDataSource: PropTypes.bool,
  isFunc: PropTypes.bool,
  isRuleSet: PropTypes.bool,
  isModel: PropTypes.bool,
  isFork: PropTypes.bool,
  isAction: PropTypes.bool,
  isRuleArray: PropTypes.bool,
};

export default AutoSuggestInput;
