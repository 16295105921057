import React from "react";
import PropTypes from "prop-types";

class WeightInput extends React.Component {
  state = {
    inputType: "text",
    inputValue: this.props.weight,
  };

  handleFocus = () => {
    this.setState({ inputType: "text" });
  };

  handleBlur = (e) => {
    const value = parseFloat(e.target.value);
    // Check if the value is a non-negative number without the upper limit
    if (!isNaN(value)) {
      this.setState({ inputType: "number", inputValue: value.toString() }); // Ensure consistency in state
      this.props.onChange(value);
    } else {
      // Optionally reset to the previous valid value or handle invalid input differently
      this.setState({ inputValue: this.props.weight.toString() });
    }
  };

  handleChange = (e) => {
    const value = e.target.value;
    const reg = /^-?\d*(\.\d*)?$/;
    if (!value || reg.test(value)) {
      this.setState({ inputValue: value });
    }
  };

  render() {
    return (
      <input
        type={this.state.inputType}
        className="form-control border-0 rounded-0"
        value={this.state.inputValue}
        disabled={this.props.disabled}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      />
    );
  }
}

WeightInput.propTypes = {
  weight: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default WeightInput;
