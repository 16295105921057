import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ReactComponent as DragHandleIcon } from "assets/icons/grip-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg"; // Assuming you have a trash icon available
import CustomSelect from "components/CustomSelect";
import { Card, Row, Col } from "react-bootstrap";

const ApprovalSequenceStep = ({
  step,
  index,
  onChange,
  dragHandleProps,
  userRoles,
  deleteStep,
}) => {
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onChange(index, name, value);
  };

  const handleSelectChange = (selectedOption) => {
    const newValue = selectedOption ? selectedOption.value : null;
    onChange(index, "access_role_id", newValue);
  };

  const selectOptions = userRoles.map((role) => ({
    value: role.access_role_id,
    label: role.title,
  }));

  const selectedRole = selectOptions.find(
    (option) => option.value === step.access_role_id
  );

  const customStyles = {
    singleValue: (styles) => ({
      ...styles,
      color: selectedRole ? "#6C757D" : "#dc3545",
      marginLeft: "10px",
    }),
  };

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div
            {...dragHandleProps}
            style={{ cursor: "grab" }}
            className="mr-2 ml-0"
          >
            <DragHandleIcon />
          </div>
          <span className="me-2">
            {index + 1}
            {". "}
          </span>
          <span>{step.title || t("approval_step")}</span>
        </div>
        <button
          type="button"
          className="btn p-0"
          onClick={() => deleteStep(index)}
        >
          <TrashIcon />
        </button>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <label className="form-label">{t("title_capitalized")}</label>
              <input
                type="text"
                className="form-control border-radius-4"
                name="title"
                value={step.title}
                onChange={handleInputChange}
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <label className="form-label">{t("approving_role")}</label>
              <CustomSelect
                defaultValue={selectedRole}
                key={selectOptions.length}
                options={selectOptions}
                handleChange={handleSelectChange}
                isSearchable={false}
                selectWidth="w-100"
                selectStyle={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ApprovalSequenceStep.propTypes = {
  step: PropTypes.any,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.object.isRequired,
  userRoles: PropTypes.array,
  deleteStep: PropTypes.func.isRequired, // Add the deleteStep prop type
};

export default ApprovalSequenceStep;
