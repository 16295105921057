import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import { ToastOptions } from "components/toastify";
import { Card } from "components/auth/Card";
import "react-toastify/dist/ReactToastify.css";
import "components/auth/login.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { signUsingOTPRequest, resendOTPRequest } from "redux/auth/action";

const OTPLogin = () => {
  const dispatch = useDispatch();
  const {
    isSignUsingOTPFailure,
    isResendOTPRequestSuccess,
    isResendOTPRequestFailure,
  } = useSelector((state) => state.auth);
  const prevIsSignUsingOTPFailure = usePrevious(isSignUsingOTPFailure);
  const { t } = useTranslation();
  const [isWaiting, setIsWaiting] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputsRef = useRef([]);
  const [counter, setCounter] = useState(30);

  const onHandleLogin = (e) => {
    e.preventDefault();

    let email = localStorage.getItem("login_email");
    let otp = inputsRef.current.reduce((acc, input) => acc + input.value, "");
    const data = new FormData();
    data.append("email", email);
    data.append("otp", otp);
    dispatch(signUsingOTPRequest(data));
    setIsWaiting(true);
  };

  useEffect(() => {
    if (
      isSignUsingOTPFailure &&
      prevIsSignUsingOTPFailure !== isSignUsingOTPFailure &&
      isWaiting
    ) {
      setIsWaiting(false);
      toast.error(t("the_otp_is_invalid"), ToastOptions);
    }
  }, [isSignUsingOTPFailure]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleKeyUp = (event, index) => {
    if (event.keyCode === 8 && !event.target.value && index !== 0) {
      const prevSibling = inputsRef.current[index - 1];
      prevSibling.focus();
      prevSibling.select();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData("text");
    const numbers = paste
      .split("")
      .filter((n) => !isNaN(n))
      .slice(0, 6);
    if (numbers.length === 6) {
      setOtp(numbers);
      inputsRef.current.forEach((input, index) => {
        if (index < numbers.length) {
          input.value = numbers[index];
        }
      });

      setTimeout(() => {
        onHandleLogin(event);
      }, 0);
    }
  };

  //Resend OTP

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsWaiting(false);
    }
  }, [counter]);

  const handleResendOTP = () => {
    const email = localStorage.getItem("login_email");

    const data = new FormData();
    data.append("email", email);
    dispatch(resendOTPRequest(data));
    setCounter(30);
  };

  //give notification when resend OTP success
  useEffect(() => {
    if (isResendOTPRequestSuccess) {
      toast.success(t("otp_has_been_sent"), ToastOptions);
    }
  }, [isResendOTPRequestSuccess]);

  useEffect(() => {
    document.title = "OTP Login - Decisimo";
  }, []);

  return (
    <Card>
      <form className="form-pretty" onSubmit={onHandleLogin}>
        <h1 className="form-pretty__title my-4">{t("fill_in_otp")}</h1>
        <p className="text-center">
          {t("please_check_your_email_and_fill_in_the_otp")}
        </p>
        <div className="otp-inputs d-flex justify-content-center mb-3">
          {otp.map((value, index) => (
            <input
              key={index}
              type="tel"
              name={`otp-${index + 1}`}
              maxLength="1"
              pattern="\d*"
              className="form-control otp-input"
              value={value}
              onChange={(e) => handleChange(e.target, index)}
              onKeyUp={(e) => handleKeyUp(e, index)}
              onFocus={(e) => e.target.select()}
              autoFocus={index === 0}
              required
              ref={(ref) => (inputsRef.current[index] = ref)}
              onPaste={index === 0 ? handlePaste : undefined}
            />
          ))}
        </div>
        <button className="form-pretty__btn" type="submit" disabled={isWaiting}>
          {isWaiting ? (
            <div
              className="spinner-border"
              style={{ width: "1rem", height: "1rem" }}
              role="status"
            >
              <span className="sr-only">{t("loading")}</span>
            </div>
          ) : (
            t("log_in")
          )}
        </button>
        <br />
        <button
          type="button"
          className="btn text-decoration-none"
          onClick={handleResendOTP}
          disabled={counter > 0}
        >
          {counter > 0 ? `${t("resend_otp_in")} ${counter}s` : t("resend_otp")}
        </button>
        <hr />

        <div>
          <p className="mb-3">
            <Link to="/login" className="text-decoration-none">
              {t("back_to_login")}
            </Link>
          </p>
        </div>
      </form>
    </Card>
  );
};

export default OTPLogin;
