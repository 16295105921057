import { combineReducers } from "redux";
import auth from "redux/auth/reducer";
import roleRights from "redux/roleRights/reducer";
import users from "redux/user/reducer";
import subscriptions from "redux/subscription/reducer";
import ruleSets from "redux/rulesets/reducer";
import vectors from "redux/vectors/reducer";
import decisionTables from "redux/decisionTables/reducer";
import functions from "redux/functions/reducer";
import dataSources from "redux/dataSources/reducer";
import models from "redux/models/reducer";
import workflows from "redux/workflows/reducer";
import releases from "redux/releases/reducer";
import endPoints from "redux/endpoints/reducer";
import deployments from "redux/deployments/reducer";
import dashboards from "redux/dashboard/reducer";
import batches from "redux/batches/reducer";
import timezones from "redux/timezones/reducer";
import contactUs from "redux/contactUs/reducer";
import templates from "redux/templates/reducer";
import approvalProcesses from "redux/approvalProcesses/reducer";
import scorecard from "redux/scorecard/reducer";
import dataMarket from "redux/dataMarket/reducer";
import decisionTrees from "redux/decisionTrees/reducer";

const rootReducer = combineReducers({
  auth,
  users,
  roleRights,
  subscriptions,
  ruleSets,
  vectors,
  decisionTables,
  functions,
  dataSources,
  models,
  workflows,
  releases,
  endPoints,
  deployments,
  dashboards,
  batches,
  timezones,
  contactUs,
  templates,
  approvalProcesses,
  scorecard,
  dataMarket,
  decisionTrees,
});

export default rootReducer;
