import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteFunctionRequest,
  getFunctionExplanationRequest,
  getFunctionRequest,
  getFunctionRevisionsRequest,
  getFunctionTestRequest,
  getRunTestResultDetailsFunctionRequest,
  getRunTestResultStatusFunctionRequest,
  updateFunctionRequest,
} from "redux/functions/action";
import { getStructuredItems, newAttribute } from "utility/utility";
import usePrevious from "utility/hooks/usePrevious";
import SubHeader from "components/SubHeader";
import { ToastOptions } from "components/toastify";
import FunctionContent from "components/functions/content/FunctionContent";
import DeleteConfirm from "components/modals/DeleteConfirm";
import Revisions from "components/modals/ruleSet/Revisions";
import { MainContext } from "context/contexts";
import TestingModal from "components/modals/Testing";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as WindIcon } from "assets/icons/wind.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as FunctionIcon } from "assets/icons/functions.svg";

import { ReactComponent as SparklesIcon } from "assets/icons/sparkles.svg";
import {
  useCtrlSHandler,
  useCtrlQHandler,
} from "utility/hooks/useCtrlSHandler";
import { useTranslation } from "react-i18next";
import ExplanationModal from "components/modals/explain/ExplainModal";

import { i, use } from "react-dom-factories";

const UpdateFunction = () => {
  const { vectorsData, setIsLoading, setIsEdited, isEdited } =
    useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSourceRef] = useCtrlSHandler(updateFunction);
  useCtrlQHandler(() => handleToggleTestingModal(true));
  const { t } = useTranslation();

  const { id } = useParams();
  const {
    isGetFunctionSuccess,
    isGetFunctionRevisionsSuccess,
    functionData,
    revisions,
    isUpdatedFunctionSuccess,
    isUpdatedFunctionError,
    isDeletedFunctionSuccess,
    isGetFunctionTestSuccess,
    isGetFunctionTestError,
    testFunction,
    resultDetails,
    resultStatus,
    isGetTestResultDetailsSuccess,
    isGetTestResultStatusSuccess,
    isGetFunctionError,
    isGetFunctionRevisionsError,
    isGetTestResultDetailsError,
    functionExplanation,
    isGetFunctionExplanationSuccess,
    isGetFunctionExplanationError,
  } = useSelector((state) => state.functions);

  const prevIsGetFunctionSuccess = usePrevious(isGetFunctionSuccess);
  const prevIsGetFunctionRevisionsSuccess = usePrevious(
    isGetFunctionRevisionsSuccess
  );
  const prevIsUpdatedFunctionSuccess = usePrevious(isUpdatedFunctionSuccess);
  const prevIsGetTestResultDetailsError = usePrevious(
    isGetTestResultDetailsError
  );
  const prevIsGetFunctionTestError = usePrevious(isGetFunctionTestError);
  const prevIsGetFunctionRevisionsError = usePrevious(
    isGetFunctionRevisionsError
  );
  const prevIsUpdatedFunctionError = usePrevious(isUpdatedFunctionError);
  const prevIsDeletedFunctionSuccess = usePrevious(isDeletedFunctionSuccess);
  const prevIsGetFunctionTestSuccess = usePrevious(isGetFunctionTestSuccess);
  const prevIsGetFunctionError = usePrevious(isGetFunctionError);
  const prevIsGetTestResultDetailsSuccess = usePrevious(
    isGetTestResultDetailsSuccess
  );
  const prevIsGetTestResultStatusSuccess = usePrevious(
    isGetTestResultStatusSuccess
  );

  //prev expl
  const prevIsGetFunctionExplanationSuccess = usePrevious(
    isGetFunctionExplanationSuccess
  );
  const prevIsGetFunctionExplanationError = usePrevious(
    isGetFunctionExplanationError
  );

  const [attributes, setAttributes] = useState([]);
  const [functionItems, setFunctionItems] = useState([]);
  const [testFunctionData, setTestFunctionData] = useState([]);
  const [runTestResultDetailsData, setRunTestResultDetailsData] = useState();
  const [runTestResultStatusData, setRunTestResultStatusData] = useState();
  const [isFunctionRevisionsModalOpen, setIsFunctionRevisionsModalOpen] =
    useState(false);
  const [isFunctionDeleteModalOpen, setIsFunctionDeleteModalOpen] =
    useState(false);
  const [isTestingModalOpen, setIsTestingModalOpen] = useState(false);
  const [visualizeFormula, setVisualizeFormula] = useState(false);

  const [isExplanationModalOpen, setIsExplanationModalOpen] = useState(false);
  const [isLoadingExplanation, setIsLoadingExplanation] = useState(false);
  const [explanation, setExplanation] = useState(false);

  useEffect(() => {
    document.title = t("function_decisimo");
    setIsLoading(true);
    dispatch(getFunctionRequest(id));
  }, []);

  useEffect(() => {
    if (isGetFunctionSuccess && prevIsGetFunctionSuccess === false) {
      setIsLoading(false);
      const newAttr = { ...newAttribute };
      newAttr.fce_attribute_id = `new_${Math.round(Math.random() * 10000)}`;
      newAttr.isDefault = true;
      setAttributes([...(functionData?.attributes || []), newAttr]);
      setFunctionItems(
        getStructuredItems(structuredClone(functionData?.items))
      );
    }
  }, [isGetFunctionSuccess]);

  useEffect(() => {
    if (
      (isGetFunctionError && prevIsGetFunctionError === false) ||
      (isGetFunctionRevisionsError &&
        prevIsGetFunctionRevisionsError === false) ||
      (isGetFunctionTestError && prevIsGetFunctionTestError === false) ||
      (isGetTestResultDetailsError &&
        prevIsGetTestResultDetailsError === false) ||
      (isUpdatedFunctionError && prevIsUpdatedFunctionError === false) ||
      (isGetFunctionRevisionsSuccess &&
        prevIsGetFunctionRevisionsSuccess === false)
    ) {
      setIsLoading(false);
    }
  }, [
    isGetFunctionRevisionsSuccess,
    isGetFunctionError,
    isGetFunctionRevisionsError,
    isGetFunctionTestError,
    isGetTestResultDetailsError,
    isUpdatedFunctionError,
  ]);

  useEffect(() => {
    if (
      isGetTestResultDetailsSuccess &&
      prevIsGetTestResultDetailsSuccess === false
    ) {
      setIsLoading(false);
      setRunTestResultDetailsData(structuredClone(resultDetails));
    }
  }, [isGetTestResultDetailsSuccess]);

  useEffect(() => {
    if (isGetFunctionTestError && prevIsGetFunctionTestError === false) {
      setIsLoading(false);
      toast.error(t("failed_testing_function"), ToastOptions);
    }
  }, [isGetFunctionTestError]);

  useEffect(() => {
    if (
      isGetTestResultStatusSuccess &&
      prevIsGetTestResultStatusSuccess === false
    ) {
      setIsLoading(false);
      setRunTestResultStatusData(structuredClone(resultStatus));
    }
  }, [isGetTestResultStatusSuccess]);

  useEffect(() => {
    if (isUpdatedFunctionSuccess && prevIsUpdatedFunctionSuccess === false) {
      setIsLoading(false);
      toast.success(t("function_updated"), ToastOptions);
      dispatch(getFunctionRequest(id));
      setTestFunctionData([]);
    }
  }, [isUpdatedFunctionSuccess]);

  useEffect(() => {
    if (isDeletedFunctionSuccess && prevIsDeletedFunctionSuccess === false) {
      setIsLoading(false);
      toast.warning(t("function_deleted"), ToastOptions);
      navigate("/functions");
    }
  }, [isDeletedFunctionSuccess]);

  useEffect(() => {
    if (isGetFunctionTestSuccess && prevIsGetFunctionTestSuccess === false) {
      setIsLoading(false);
      setTestFunctionData(structuredClone(testFunction));
    }
  }, [isGetFunctionTestSuccess]);

  function updateFunction(target) {
    setIsEdited(false);
    if (target.querySelector(".is-invalid")) {
      return toast.error(t("attribute_value_valid"), ToastOptions);
    }
    const data = new URLSearchParams(new FormData(target));
    const name = target.name.value;
    const title = target.title.value;

    if (name.length === 0 || title.length === 0) {
      return toast.error(t("please_fill_title"), ToastOptions);
    }

    setIsLoading(true);
    dispatch(updateFunctionRequest({ id, data }));
  }

  const handleToggleFunctionRevisionsModal = (isOpen = true) => {
    if (isOpen) {
      setIsLoading(true);
      dispatch(getFunctionRevisionsRequest(parseInt(id)));
    }
    setIsFunctionRevisionsModalOpen(isOpen);
  };

  const handleToggleFunctionDeleteModal = (isOpen = true) => {
    setIsFunctionDeleteModalOpen(isOpen);
  };

  const handleConfirmDeleteFunctionModal = () => {
    setIsLoading(true);
    setIsEdited(false);
    dispatch(deleteFunctionRequest(id));
    handleToggleFunctionDeleteModal(false);
  };

  const handleToggleTestingModal = (isOpen = false) => {
    //if is edited, we will ask to save first
    if (isEdited) {
      return toast.error(t("please_save_function_first"), ToastOptions);
    }

    if (isOpen) {
      setIsLoading(true);
      dispatch(getFunctionTestRequest(id));
    } else {
      setRunTestResultDetailsData();
      setRunTestResultStatusData();
    }

    setIsTestingModalOpen(isOpen);
  };

  //Explanation
  const handleExplain = (id) => {
    setExplanation("");
    dispatch(
      getFunctionExplanationRequest({
        fce_id: id,
      })
    );
    setIsLoadingExplanation(true);
    setIsExplanationModalOpen(true);
  };

  useEffect(() => {
    if (isGetFunctionExplanationSuccess) {
      setExplanation(functionExplanation);
      setIsLoadingExplanation(false);
    }
  }, [isGetFunctionExplanationSuccess]);

  useEffect(() => {
    if (isGetFunctionExplanationError) {
      setExplanation(t("explanation_not_available"));
      setIsLoadingExplanation(false);
    }
  }, [isGetFunctionExplanationError]);

  const handleTryAgain = () => {
    if (!isLoadingExplanation) {
      dispatch(
        getFunctionExplanationRequest({
          fce_id: id,
        })
      );
      setIsLoadingExplanation(true);
    }
  };

  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item"
          onClick={() => handleToggleFunctionRevisionsModal()}
          type="button"
        >
          <ClockIcon /> {t("revisions")}
        </button>
      ),
      divider: true,
    },

    {
      id: 3,
      content: (
        <button
          className="dropdown-item"
          type="button"
          onClick={() => handleExplain(id)}
        >
          <SparklesIcon /> {t("explain")}
        </button>
      ),
      divider: true,
    },
    {
      id: 2,
      content: (
        <button
          className="dropdown-item"
          type="button"
          onClick={handleToggleFunctionDeleteModal}
        >
          <DeleteIcon /> {t("delete")}
        </button>
      ),
      divider: false,
    },
  ];

  const handleRunTestResultDetailsConfirm = (e) => {
    e.preventDefault();
    const data = new URLSearchParams(new FormData(e.target));

    if (testFunction.length > 0) {
      testFunction.forEach((item) => {
        data.set(
          `variable[${item.fce_attribute_id}][path]`,
          item.attribute_path
        );
      });
    }
    setIsLoading(true);
    dispatch(getRunTestResultDetailsFunctionRequest({ data, id }));
  };

  const handleRunTestResultSuccessConfirm = (vectorId) => {
    if (!vectorId) {
      vectorId = vectorsData.length > 1 ? vectorsData[1].vector_id : null;
    }
    if (!vectorId) {
      return toast.error(t("no_data_object_selected"), ToastOptions);
    }
    setIsLoading(true);
    dispatch(getRunTestResultStatusFunctionRequest({ id, vectorId }));
  };

  return (
    <>
      <form
        ref={dataSourceRef}
        className="h-100 overflow-hidden"
        onSubmit={(e) => {
          e.preventDefault();
          updateFunction(e.target);
        }}
        onChange={() => setIsEdited(true)}
      >
        <SubHeader
          alt={t("function_definition")}
          title={t("function_definition")}
          icon={<FunctionIcon />}
          actions={
            <>
              <Link
                to="/functions"
                className="mr12"
                title={t("back_to_functions")}
              >
                <button className="h-100 btn outline">
                  <BackIcon />
                </button>
              </Link>

              <button
                className="btn outline mr12"
                type="button"
                onClick={() => setVisualizeFormula(true)}
                title={t("visualize_formula")}
              >
                <FunctionIcon />
              </button>

              <button
                className="btn outline mr12"
                type="button"
                onClick={() => handleToggleTestingModal(true)}
                title={t("test_function")}
              >
                <WindIcon />
              </button>

              <button
                className="btn primary mr12"
                type="submit"
                data-tip={true}
                title={t("save_function")}
              >
                <SaveIcon />
              </button>
              <div>
                <button
                  type="button"
                  className="btn primary mr12 h-38"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  title={t("delete_function")}
                >
                  <SettingsIcon />
                </button>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                  {settings.length > 0 &&
                    settings.map((setting) => {
                      return (
                        <span key={setting.id}>
                          {setting.content}
                          {setting.divider && (
                            <div className="dropdown-divider" />
                          )}
                        </span>
                      );
                    })}
                </div>
              </div>
            </>
          }
        />
        <FunctionContent
          attributes={attributes}
          info={functionData?.info}
          items={functionItems}
          setAttributes={setAttributes}
          visualizeFormula={visualizeFormula}
          setVisualizeFormula={setVisualizeFormula}
        />
      </form>

      <DeleteConfirm
        handleClose={() => handleToggleFunctionDeleteModal(false)}
        handleConfirm={handleConfirmDeleteFunctionModal}
        title={t("delete_function")}
        message={t("delete_function_message")}
        open={isFunctionDeleteModalOpen}
      />

      <Revisions
        handleClose={() => handleToggleFunctionRevisionsModal(false)}
        revisionsData={revisions}
        linkTo={"functions"}
        open={isFunctionRevisionsModalOpen}
        elemId={parseInt(id)}
      />

      <TestingModal
        title={t("testing_function")}
        size={"lg"}
        handleClose={() => handleToggleTestingModal()}
        handleRunTestResultDetailsConfirm={handleRunTestResultDetailsConfirm}
        handleRunTestResultSuccessConfirm={handleRunTestResultSuccessConfirm}
        runTestResultDetailsData={runTestResultDetailsData}
        runTestResultStatusData={runTestResultStatusData}
        testingData={testFunctionData}
        isFunction={true}
        vectorsData={vectorsData}
        open={isTestingModalOpen}
      />

      <ExplanationModal
        open={isExplanationModalOpen}
        handleClose={() => setIsExplanationModalOpen(false)}
        handleTryAgain={handleTryAgain}
        explanation={explanation}
        isLoadingExplanation={isLoadingExplanation}
      />
    </>
  );
};

export default UpdateFunction;
