import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  createModelFailure,
  createModelRequest,
  createModelSuccess,
  deleteModelFailure,
  deleteModelRequest,
  deleteModelSuccess,
  deleteModelVariableFailure,
  deleteModelVariableRequest,
  deleteModelVariableSuccess,
  getContainerImagesFailure,
  getContainerImagesRequest,
  getContainerImagesSuccess,
  getContainerPluginsFailure,
  getContainerPluginsRequest,
  getContainerPluginsSuccess,
  getModelFailure,
  getModelRequest,
  getModelRunTestResultStatusFailure,
  getModelRunTestResultStatusRequest,
  getModelRunTestResultStatusSuccess,
  getModelsFailure,
  getModelsRequest,
  getModelsSuccess,
  getModelSuccess,
  getModelTestingResultFailure,
  getModelTestingResultRequest,
  getModelTestingResultSuccess,
  getVariablesFailure,
  getVariablesRequest,
  getVariablesSuccess,
  updateModelFailure,
  updateModelRequest,
  updateModelSuccess,
} from "redux/models/action";

function* getModels({ payload }) {
  try {
    const response = yield call(
      axios.get,
      payload ? `/models?page=${payload}` : "/models"
    );
    if (response.status === 200) {
      yield put(getModelsSuccess(response.data));
    }
  } catch (e) {
    yield put(getModelsFailure("e.message"));
  }
}

function* getModel({ payload }) {
  try {
    const url = `models/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getModelSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getModelFailure("e.message"));
  }
}

function* getVariables({ payload }) {
  try {
    const url = `models/${payload}/variables`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getVariablesSuccess(response.data));
    }
  } catch (e) {
    yield put(getVariablesFailure("e.message"));
  }
}

function* updateModel({ payload }) {
  try {
    const url = `models/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 204) {
      yield put(updateModelSuccess());
    }
  } catch (e) {
    yield put(updateModelFailure("e.message"));
  }
}

function* deleteModel({ payload }) {
  try {
    const url = `models/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteModelSuccess());
    }
  } catch (e) {
    yield put(deleteModelFailure("e.message"));
  }
}

function* deleteModelVariable({ payload }) {
  try {
    const url = `models/${payload.id}/variables/${payload.variableId}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteModelVariableSuccess());
    }
  } catch (e) {
    yield put(deleteModelVariableFailure("e.message"));
  }
}

function* createModel({ payload }) {
  try {
    const response = yield call(axios.post, "/models", payload);
    if (response.status === 201) {
      yield put(createModelSuccess(response.headers.etag));
    }
  } catch (e) {
    yield put(createModelFailure("e.message"));
  }
}

function* getContainerImages() {
  try {
    const response = yield call(axios.get, "/container-images");
    if (response.status === 200) {
      yield put(getContainerImagesSuccess(response.data));
    }
  } catch (e) {
    yield put(getContainerImagesFailure("e.message"));
  }
}

function* getContainerPlugins() {
  try {
    const response = yield call(axios.get, "/container-plugins");
    if (response.status === 200) {
      yield put(getContainerPluginsSuccess(response.data));
    }
  } catch (e) {
    yield put(getContainerPluginsFailure("e.message"));
  }
}

function* getModelTestingResult({ payload }) {
  try {
    const response = yield call(
      axios.post,
      `/models/${payload.id}/test`,
      payload.data
    );
    if (response.status === 200) {
      yield put(getModelTestingResultSuccess(response.data));
    }
  } catch (e) {
    yield put(getModelTestingResultFailure("e.message"));
  }
}

function* getRunTestResultStatus({ payload }) {
  try {
    const url = `/models/${payload.id}/test-object/${payload.vectorId}`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 200) {
      yield put(getModelRunTestResultStatusSuccess(response.data));
    }
  } catch (e) {
    yield put(getModelRunTestResultStatusFailure(e.message));
  }
}

export default function* saga() {
  yield takeLatest(getModelsRequest, getModels);
  yield takeLatest(getModelRequest, getModel);
  yield takeLatest(getVariablesRequest, getVariables);
  yield takeLatest(updateModelRequest, updateModel);
  yield takeLatest(deleteModelRequest, deleteModel);
  yield takeLatest(deleteModelVariableRequest, deleteModelVariable);
  yield takeLatest(createModelRequest, createModel);
  yield takeLatest(getContainerImagesRequest, getContainerImages);
  yield takeLatest(getContainerPluginsRequest, getContainerPlugins);
  yield takeLatest(getModelTestingResultRequest, getModelTestingResult);
  yield takeLatest(getModelRunTestResultStatusRequest, getRunTestResultStatus);
}
