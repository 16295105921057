import { all } from "redux-saga/effects";
import saga from "redux/auth/saga";
import subscriptions from "redux/subscription/saga";
import users from "redux/user/saga";
import roleRights from "redux/roleRights/saga";
import ruleSets from "redux/rulesets/saga";
import vectors from "redux/vectors/saga";
import decisionTables from "redux/decisionTables/saga";
import functions from "redux/functions/saga";
import dataSources from "redux/dataSources/saga";
import models from "redux/models/saga";
import workflows from "redux/workflows/saga";
import releases from "redux/releases/saga";
import endPoints from "redux/endpoints/saga";
import deployments from "redux/deployments/saga";
import dashboards from "redux/dashboard/saga";
import batches from "redux/batches/saga";
import timezones from "redux/timezones/saga";
import contactUs from "redux/contactUs/saga";
import templates from "redux/templates/saga";
import approvalProcesses from "redux/approvalProcesses/saga";
import scorecardSaga from "./scorecard/saga";
import dataMarketSaga from "./dataMarket/saga";
import decisionTreesSaga from "./decisionTrees/saga";

export default function* rootSaga() {
  yield all([
    saga(),
    users(),
    roleRights(),
    subscriptions(),
    ruleSets(),
    vectors(),
    decisionTables(),
    functions(),
    dataSources(),
    models(),
    workflows(),
    releases(),
    endPoints(),
    deployments(),
    dashboards(),
    batches(),
    timezones(),
    contactUs(),
    templates(),
    scorecardSaga(),
    dataMarketSaga(),
    decisionTreesSaga(),
    approvalProcesses(),
  ]);
}
