import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataMarketGetServicesRequest } from "redux/dataMarket/action";
import ServiceCard from "./ServiceCard";
import SubHeader from "components/SubHeader";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";

const DataMarketServicesPage = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    services,
    isLoading,
    isDataMarketActivateServiceSuccess,
    isDataMarketActivateServiceError,
  } = useSelector((state) => state.dataMarket);

  useEffect(() => {
    setIsLoading(true);
    dispatch(dataMarketGetServicesRequest());
  }, [dispatch, setIsLoading]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    if (isDataMarketActivateServiceSuccess) {
      toast.success(t("service_activated_successfully"), ToastOptions);
    }
    if (isDataMarketActivateServiceError) {
      toast.error(t("service_activation_failed"), ToastOptions);
    }
  }, [isDataMarketActivateServiceSuccess, isDataMarketActivateServiceError, t]);

  return (
    <>
      <SubHeader title={t("data_services_hub")} actions="" />{" "}
      <div className="data-market-services-page mt-4">
        {isLoading ? (
          <div>{t("loadingServices")}...</div>
        ) : services.length > 0 ? (
          <div className="row">
            {services.map((service) => (
              <div key={service.dama_service_id} className="col-md-4 mb-4">
                <ServiceCard service={service} />
              </div>
            ))}
          </div>
        ) : (
          <div>{t("no_services_available")}</div>
        )}
      </div>
    </>
  );
};

export default DataMarketServicesPage;
