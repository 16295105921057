import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import SubHeader from "components/SubHeader";
import { MainContext } from "context/contexts";
import { getReleasesRequest } from "redux/releases/action";

const ReleaseValidation = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isGetReleasesSuccess, releases } = useSelector(
    (state) => state.releases
  );

  const [selectedRelease, setSelectedRelease] = useState("");
  const [testPackage, setTestPackage] = useState(null);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [signedUrl, setSignedUrl] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getReleasesRequest());
  }, []);

  const handleReleaseChange = (event) => {
    setSelectedRelease(event.target.value);
  };

  const handleFileChange = (event) => {
    setTestPackage(event.target.files[0]);
  };

  const fetchSignedUrl = async () => {
    try {
      const response = await fetch("http://web.engine.loc/api/upload.php", {
        method: "POST",
        body: JSON.stringify({
          filename: testPackage.name,
          contentType: testPackage.type,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to generate signed URL");
      }

      const data = await response.json();
      setSignedUrl(data.signedUrl);
    } catch (error) {
      console.error("Error fetching signed URL:", error);
      toast.error(t("fetch_signed_url_error"), ToastOptions);
    }
  };

  const handleSubmit = async () => {
    setIsLoadingUpload(true);

    if (!signedUrl) {
      await fetchSignedUrl(); // Fetch signed URL if not already fetched
    }

    try {
      const uploadResponse = await fetch(signedUrl, {
        method: "PUT",
        body: testPackage,
        headers: {
          "Content-Type": testPackage.type,
        },
        // Track upload progress
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded / total) * 100);
          setUploadProgress(percentCompleted);
        },
      });

      if (!uploadResponse.ok) {
        throw new Error("Upload failed");
      }

      toast.success(t("upload_success"), ToastOptions);
    } catch (error) {
      console.error("Upload error:", error);
      toast.error(t("upload_error"), ToastOptions);
    }

    setIsLoadingUpload(false);
    setUploadProgress(0); // Reset progress after upload
  };

  return (
    <>
      <SubHeader title={t("release_validation")} actions="" />
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-6">
            <label htmlFor="selectRelease" className="form-label">
              {t("select_release")}
            </label>
            <select
              id="selectRelease"
              className="form-select mb-3"
              onChange={handleReleaseChange}
              value={selectedRelease}
            >
              <option value="">{t("select_release")}</option>
              {isGetReleasesSuccess &&
                releases.map((release) => (
                  <option
                    key={release.workflow_release_id}
                    value={release.workflow_release_id}
                  >
                    {release.name}
                  </option>
                ))}
            </select>

            <label htmlFor="uploadFile" className="form-label">
              {t("upload_test_package")}
            </label>
            <input
              type="file"
              className="form-control mb-3"
              id="uploadFile"
              accept=".zip,.tar,.tar.gz"
              onChange={handleFileChange}
            />

            <div className="mb-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={!selectedRelease || !testPackage || isLoadingUpload}
              >
                {isLoadingUpload ? (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  t("upload")
                )}
              </button>
            </div>

            {uploadProgress > 0 && (
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${uploadProgress}%` }}
                  aria-valuenow={uploadProgress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {`${uploadProgress}%`}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReleaseValidation;
