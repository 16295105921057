import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import * as actions from "./action";

// Helper function to perform API calls
const fetchDataFromAPI = async (url, method = "GET", data = null) => {
  const response = await axios({ url, method, data });
  return response.data;
};

// Saga to fetch Data Market services
function* fetchDataMarketServices() {
  try {
    const data = yield call(fetchDataFromAPI, "/data-market-services");
    yield put(actions.dataMarketGetServicesSuccess(data));
  } catch (error) {
    yield put(actions.dataMarketGetServicesFailure(error.toString()));
  }
}

function* activateDataMarketService({ payload }) {
  try {
    let finalData;
    if (payload.serviceDetails) {
      finalData = payload.serviceDetails;
    } else {
      finalData = payload;
    }

    const response = yield call(
      axios.post,
      `/data-market-services-active/${payload.dama_service_id}`,
      finalData
    );

    if (response.status === 201) {
      //add the etag as dama_service_tenant_id into the payload copy
      let updatedData = {
        ...finalData,
        dama_service_tenant_id: response.headers.etag,
      };

      yield put(
        actions.dataMarketActivateServiceSuccess({
          updatedData,
        })
      );
    }
  } catch (error) {
    yield put(
      actions.dataMarketActivateServiceFailure({
        id: payload.id,
        error: error.message,
      })
    );
  }
}

//get service details
function* fetchDataMarketServiceDetails({ payload }) {
  try {
    const data = yield call(
      fetchDataFromAPI,
      `/data-market-services/${payload.dama_service_id}`
    );
    yield put(actions.dataMarketGetServiceDetailsSuccess(data));
  } catch (error) {
    yield put(actions.dataMarketGetServiceDetailsFailure(error.toString()));
  }
}

// Watcher sagas
export default function* dataMarketSaga() {
  yield takeLatest(
    actions.dataMarketGetServicesRequest().type,
    fetchDataMarketServices
  );
  yield takeLatest(
    actions.dataMarketActivateServiceRequest().type,
    activateDataMarketService
  );
  yield takeLatest(
    actions.dataMarketGetServiceDetailsRequest().type,
    fetchDataMarketServiceDetails
  );
}
