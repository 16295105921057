import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteModelRequest,
  getModelRequest,
  getModelRunTestResultStatusRequest,
  getModelTestingResultRequest,
  getVariablesRequest,
  updateModelRequest,
} from "redux/models/action";
import usePrevious from "utility/hooks/usePrevious";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { MainContext } from "context/contexts";
import ModelContent from "components/models/ModelContent";
import SubHeader from "components/SubHeader";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as WindIcon } from "assets/icons/wind.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as ModelsIcon } from "assets/icons/models.svg";

import DeleteConfirm from "components/modals/DeleteConfirm";
import TestingModal from "components/modals/Testing";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { useTranslation } from "react-i18next";
import { set } from "lodash";

const UpdateModel = () => {
  const { vectorsData, setIsLoading, setIsEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const [dataSourceRef] = useCtrlSHandler(updateModel);

  const {
    model,
    variables,
    isGetModelSuccess,
    isGetVariablesSuccess,
    isGetVariablesError,
    isUpdatedModelSuccess,
    isUpdatedModelError,
    isDeletedModelSuccess,
    isGetModelTestingResultSuccess,
    isModelGetRunTestResultStatusSuccess,
    isModelGetRunTestResultStatusError,
    isGetModelTestingResultError,
    runTestResultStatus,
    testingResult,
  } = useSelector((state) => state.models);

  const prevIsGetModelSuccess = usePrevious(isGetModelSuccess);
  const prevIsGetVariablesSuccess = usePrevious(isGetVariablesSuccess);
  const prevIsGetVariablesError = usePrevious(isGetVariablesError);
  const prevIsUpdatedModelSuccess = usePrevious(isUpdatedModelSuccess);
  const prevIsUpdatedModelError = usePrevious(isUpdatedModelError);
  const prevIsDeletedModelSuccess = usePrevious(isDeletedModelSuccess);
  const prevIsGetModelTestingResultSuccess = usePrevious(
    isGetModelTestingResultSuccess
  );
  const prevIsGetModelTestingResultError = usePrevious(
    isGetModelTestingResultError
  );
  const prevIsModelGetRunTestResultStatusSuccess = usePrevious(
    isModelGetRunTestResultStatusSuccess
  );
  const prevIsModelGetRunTestResultStatusError = usePrevious(
    isModelGetRunTestResultStatusError
  );

  const [modelClone, setModelClone] = useState({});
  const [variablesClone, setVariablesClone] = useState([]);
  const [isModelDeleteModalOpen, setIsModelDeleteModalOpen] = useState(false);
  const [isTestingModalOpen, setIsTestingModalOpen] = useState(false);
  const [testingResultClone, setTestingResultClone] = useState(null);
  const [vectorsDataClone, setVectorsDataClone] = useState([]);
  const [runTestResultStatusData, setRunTestResultStatusData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = t("model_decisimo");
    setIsLoading(true);
    dispatch(getModelRequest(id));
    dispatch(getVariablesRequest(id));
  }, []);

  useEffect(() => {
    if (isGetModelSuccess && prevIsGetModelSuccess === false) {
      setModelClone(structuredClone(model));
      setIsEdited(false);
    }
  }, [isGetModelSuccess]);

  useEffect(() => {
    setVectorsDataClone([...vectorsData]);
  }, [vectorsData]);

  useEffect(() => {
    if (
      isModelGetRunTestResultStatusSuccess &&
      prevIsModelGetRunTestResultStatusSuccess === false
    ) {
      setLoading(false);
      setRunTestResultStatusData({ ...runTestResultStatus });
    }
  }, [isModelGetRunTestResultStatusSuccess]);

  useEffect(() => {
    if (isGetVariablesSuccess && prevIsGetVariablesSuccess === false) {
      setIsLoading(false);
      setVariablesClone(structuredClone(variables));
    }
  }, [isGetVariablesSuccess]);

  useEffect(() => {
    if (
      (isUpdatedModelError && prevIsUpdatedModelError === false) ||
      (isGetVariablesError && prevIsGetVariablesError === false) ||
      (isModelGetRunTestResultStatusError &&
        prevIsModelGetRunTestResultStatusError === false) ||
      (isGetModelTestingResultError &&
        prevIsGetModelTestingResultError === false)
    ) {
      setIsLoading(false);
    }
  }, [
    isGetModelTestingResultError,
    isModelGetRunTestResultStatusError,
    isUpdatedModelError,
    isGetVariablesError,
  ]);

  useEffect(() => {
    if (isUpdatedModelSuccess && prevIsUpdatedModelSuccess === false) {
      setIsLoading(false);
      dispatch(getVariablesRequest(id));
      toast.success(t("model_saved"), ToastOptions);
      setIsEdited(false);
    }
  }, [isUpdatedModelSuccess]);

  useEffect(() => {
    if (isDeletedModelSuccess && prevIsDeletedModelSuccess === false) {
      setIsLoading(false);
      toast.warning(t("model_deleted"), ToastOptions);
      navigate("/models");
    }
  }, [isDeletedModelSuccess]);

  useEffect(() => {
    if (
      isGetModelTestingResultSuccess &&
      prevIsGetModelTestingResultSuccess === false
    ) {
      setLoading(false);
      setTestingResultClone(structuredClone(testingResult));
    }
  }, [isGetModelTestingResultSuccess]);

  function updateModel(target) {
    setIsEdited(false);
    if (target.querySelector(".react-autosuggest__input.is-invalid")) {
      return toast.error(t("attribute_value_valid"), ToastOptions);
    }
    const data = new URLSearchParams(new FormData(target));
    const name = target.name.value;
    const title = target.title.value;

    if (name.length === 0 || title.length === 0) {
      return toast.error(
        t("please_fill_out_the_title_or_identifier"),
        ToastOptions
      );
    }

    data.append("model_type", "python");
    data.append("model_container_image_id", "1");

    setIsLoading(true);
    dispatch(updateModelRequest({ id, data }));
  }

  const handleShowModelDeleteModal = () => {
    setIsModelDeleteModalOpen(true);
  };

  const handleModelModalClose = () => {
    setIsModelDeleteModalOpen(false);
  };

  const handleModelDeleteConfirm = () => {
    setIsLoading(true);
    dispatch(deleteModelRequest(id));
    handleModelModalClose();
  };

  const handleToggleTestingModal = (isOpen = false) => {
    setIsTestingModalOpen(isOpen);
    if (!isOpen) {
      setRunTestResultStatusData();
      setTestingResultClone(null);
    }
  };

  const handleRunTestResultDetailsConfirm = (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      const data = new URLSearchParams(new FormData(e.target));

      if (variablesClone?.length > 0) {
        variablesClone?.forEach((variable) => {
          data.set(
            `variable[${variable.model_variable_id}][model_variable_id]`,
            variable.model_variable_id
          );
        });
      }
      dispatch(getModelTestingResultRequest({ data, id }));
    }
  };

  const handleRunTestResultSuccessConfirm = (vectorId) => {
    if (!loading) {
      if (!vectorId) {
        vectorId = vectorsData.length > 0 ? vectorsData[0].vector_id : null;
      }
      setLoading(true);
      dispatch(getModelRunTestResultStatusRequest({ id, vectorId }));
    }
  };

  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item"
          onClick={handleShowModelDeleteModal}
          type="button"
        >
          <TrashIcon /> {t("delete")}
        </button>
      ),
    },
  ];

  const headerActions = () => {
    return (
      <>
        {modelClone?.status === "d" && (
          <button
            className="btn outline mr-2"
            type="button"
            onClick={() => handleToggleTestingModal(true)}
          >
            <WindIcon />
          </button>
        )}

        <button
          className="btn primary mr-2"
          type="submit"
          title={t("save_model")}
        >
          <SaveIcon />
        </button>
        <div>
          <button
            type="button"
            className="btn primary"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-expanded="false"
            title={t("delete_model")}
          >
            <SettingsIcon />
          </button>
          <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
            {settings.length > 0 &&
              settings.map((setting) => {
                return (
                  <span key={setting.id}>
                    {setting.content}
                    {setting.divider && <div className="dropdown-divider" />}
                  </span>
                );
              })}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          updateModel(e.target);
        }}
        onChange={() => setIsEdited(true)}
      >
        <SubHeader
          alt="Model"
          icon={<ModelsIcon />}
          title={t("model")}
          actions={headerActions()}
        />
        <ModelContent
          model={modelClone}
          editMode={true}
          variables={variablesClone}
        />
      </form>
      <DeleteConfirm
        handleClose={() => handleModelModalClose()}
        handleConfirm={handleModelDeleteConfirm}
        title={t("delete_model")}
        message={t("delete_model_message")}
        open={isModelDeleteModalOpen}
        variantDelete="primary"
        icon=""
      />
      <TestingModal
        title={t("testing_model")}
        size={"xl"}
        handleClose={() => handleToggleTestingModal()}
        handleRunTestResultSuccessConfirm={handleRunTestResultSuccessConfirm}
        handleRunTestResultDetailsConfirm={handleRunTestResultDetailsConfirm}
        testingData={variablesClone}
        loading={loading}
        vectorsData={vectorsDataClone}
        runTestResultStatusData={runTestResultStatusData}
        runTestResultDetailsData={testingResultClone}
        open={isTestingModalOpen}
        isModel={true}
      />
    </>
  );
};

export default UpdateModel;
