import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as EditIcon } from "assets/icons/edit-small.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { useTranslation } from "react-i18next";

const AttributeRow = ({
  index,
  style,
  attribute,
  disabledInputs,
  changeValue,
  handleConfirmEditAttr,
  handleShowAttributeEditModal,
  handleShowVectorAttrDeleteModal,
}) => {
  const { t } = useTranslation();

  return (
    <form
      onSubmit={(e) =>
        handleConfirmEditAttr(e, attribute.vector_attribute_id, index)
      }
      style={style}
      className="d-flex align-items-center attribute-row"
    >
      <div style={{ width: "47%", paddingRight: "10px" }}>
        <input
          onChange={changeValue}
          name={`mapping-${index}`}
          className="form-control form-control-sm form-control-plaintext"
          type="text"
          placeholder={t("attribute_mapping")}
          disabled={!disabledInputs.includes(attribute.vector_attribute_id)}
          defaultValue={attribute?.mapping}
          required
        />
      </div>
      <div style={{ width: "47%", paddingRight: "10px" }}>
        <input
          name={`dummy_value-${index}`}
          className="form-control form-control-sm form-control-plaintext"
          type="text"
          placeholder={attribute?.dummy_value ? t("attribute_mapping") : ""}
          disabled={!disabledInputs.includes(attribute.vector_attribute_id)}
          defaultValue={attribute?.dummy_value}
          required
        />
      </div>
      <div className="d-flex align-items-center" style={{ width: "6%" }}>
        <span className="ml-2 clickable">
          {!disabledInputs.includes(attribute.vector_attribute_id) ? (
            <EditIcon
              title={t("edit_attribute")}
              onClick={() =>
                handleShowAttributeEditModal(
                  { ...attribute, index },
                  attribute.vector_attribute_id
                )
              }
            />
          ) : (
            <button
              title={t("save_attribute")}
              type="submit"
              className="btn outline p-0 m-0 border-0"
            >
              <SaveIcon />
            </button>
          )}
        </span>
        <span
          title={t("delete_attribute")}
          className="ml-4 clickable"
          onClick={() => handleShowVectorAttrDeleteModal(attribute)}
        >
          <DeleteIcon />
        </span>
      </div>
    </form>
  );
};

AttributeRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  attribute: PropTypes.object.isRequired,
  disabledInputs: PropTypes.array.isRequired,
  changeValue: PropTypes.func.isRequired,
  handleConfirmEditAttr: PropTypes.func.isRequired,
  handleShowAttributeEditModal: PropTypes.func.isRequired,
  handleShowVectorAttrDeleteModal: PropTypes.func.isRequired,
};

export { AttributeRow };
