import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createModelRequest } from "redux/models/action";
import usePrevious from "utility/hooks/usePrevious";
import SubHeader from "components/SubHeader";
import ModelContent from "components/models/ModelContent";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { MainContext } from "context/contexts";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { useTranslation } from "react-i18next";

const CreateModel = () => {
  const { t } = useTranslation();

  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSourceRef] = useCtrlSHandler(createModel);

  const { isCreatedModelSuccess, isCreatedModelError, newModelId } =
    useSelector((state) => state.models);

  const prevIsCreatedModelSuccess = usePrevious(isCreatedModelSuccess);
  const prevIsCreatedModelError = usePrevious(isCreatedModelError);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileBtoa, setFileBtoa] = useState("");

  useEffect(() => {
    document.title = t("model_decisimo");
  }, []);

  useEffect(() => {
    if (isCreatedModelSuccess && prevIsCreatedModelSuccess === false) {
      setIsLoading(false);
      toast.success(t("model_added"), ToastOptions);
      navigate(`/models/${newModelId}`);
    }
  }, [isCreatedModelSuccess]);

  useEffect(() => {
    if (isCreatedModelError && prevIsCreatedModelError === false) {
      setIsLoading(false);
      toast.error(t("invalid_data"), ToastOptions);
    }
  }, [isCreatedModelError]);

  const handleSelectFile = (selectedFile) => {
    const allowedExtensions = /(\.pkl|\.pickle|\.rdata|\.r|\.rds|\.jl|\.jls)$/i;
    if (!allowedExtensions.exec(selectedFile.name)) {
      alert(t("invalid_file_type"));
    } else {
      setSelectedFile(selectedFile);
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        const result = event.target.result;
        setFileBtoa(btoa(result));
      };
      fileReader.readAsBinaryString(selectedFile);
    }
  };

  function createModel(target) {
    setIsEdited(false);
    if (target.querySelector(".react-autosuggest__input.is-invalid")) {
      return toast.error(t("attribute_value_valid"), ToastOptions);
    } else if (
      target.querySelectorAll('[name="plugin[]"]:checked').length === 0
    ) {
      return toast.error(t("select_python_library"), ToastOptions);
    }
    setIsLoading(true);

    const data = new URLSearchParams(new FormData(target));
    const name = target.name.value;
    const title = target.title.value;

    if (name.length === 0 || title.length === 0) {
      return toast.error(
        t("please_fill_out_the_title_or_identifier"),
        ToastOptions
      );
    }

    data.set("model", fileBtoa);
    dispatch(createModelRequest(data));
  }

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          createModel(e.target);
        }}
        onChange={() => setIsEdited(true)}
      >
        <SubHeader
          title="Model"
          actions={
            <button className="btn primary mr-2" type="submit">
              <SaveIcon />
            </button>
          }
        />
        <ModelContent
          handleSelectFile={handleSelectFile}
          selectedFile={selectedFile}
        />
      </form>
    </>
  );
};

export default CreateModel;
