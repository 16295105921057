import { createAction } from "redux-actions";

export const dataMarketGetServicesRequest = createAction(
  "DATA_MARKET_GET_SERVICES_REQUEST"
);
export const dataMarketGetServicesSuccess = createAction(
  "DATA_MARKET_GET_SERVICES_SUCCESS"
);
export const dataMarketGetServicesFailure = createAction(
  "DATA_MARKET_GET_SERVICES_FAILURE"
);

export const dataMarketActivateServiceRequest = createAction(
  "DATA_MARKET_ACTIVATE_SERVICE_REQUEST"
);
export const dataMarketActivateServiceSuccess = createAction(
  "DATA_MARKET_ACTIVATE_SERVICE_SUCCESS"
);
export const dataMarketActivateServiceFailure = createAction(
  "DATA_MARKET_ACTIVATE_SERVICE_FAILURE"
);

export const dataMarketGetProvidersRequest = createAction(
  "DATA_MARKET_GET_PROVIDERS_REQUEST"
);
export const dataMarketGetProvidersSuccess = createAction(
  "DATA_MARKET_GET_PROVIDERS_SUCCESS"
);
export const dataMarketGetProvidersFailure = createAction(
  "DATA_MARKET_GET_PROVIDERS_FAILURE"
);

export const dataMarketGetMyServicesRequest = createAction(
  "DATA_MARKET_GET_MY_SERVICES_REQUEST"
);
export const dataMarketGetMyServicesSuccess = createAction(
  "DATA_MARKET_GET_MY_SERVICES_SUCCESS"
);
export const dataMarketGetMyServicesFailure = createAction(
  "DATA_MARKET_GET_MY_SERVICES_FAILURE"
);

export const dataMarketDeactivateMyServiceRequest = createAction(
  "DATA_MARKET_DEACTIVATE_MY_SERVICE_REQUEST"
);
export const dataMarketDeactivateMyServiceSuccess = createAction(
  "DATA_MARKET_DEACTIVATE_MY_SERVICE_SUCCESS"
);
export const dataMarketDeactivateMyServiceFailure = createAction(
  "DATA_MARKET_DEACTIVATE_MY_SERVICE_FAILURE"
);

//get service details
export const dataMarketGetServiceDetailsRequest = createAction(
  "DATA_MARKET_GET_SERVICE_DETAILS_REQUEST"
);
export const dataMarketGetServiceDetailsSuccess = createAction(
  "DATA_MARKET_GET_SERVICE_DETAILS_SUCCESS"
);
export const dataMarketGetServiceDetailsFailure = createAction(
  "DATA_MARKET_GET_SERVICE_DETAILS_FAILURE"
);
