import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDecisionTableRequest,
  exportTableDataRequest,
  getDecisionTableRequest,
  getDecisionTableRevisionsRequest,
  getDecisionTableRowsRequest,
  getRunTestObjectResultRequest,
  getRunTestResultStatusTableRequest,
  getRunTestSimulationResultRequest,
  importTableDataRequest,
  updateDecisionTableRequest,
  getOverlapValidationRequest,
} from "redux/decisionTables/action";
import usePrevious from "utility/hooks/usePrevious";
import {
  useCtrlSHandler,
  useCtrlQHandler,
} from "utility/hooks/useCtrlSHandler";
import SubHeader from "components/SubHeader";
import Revisions from "components/modals/ruleSet/Revisions";
import TestingModal from "components/modals/Testing";
import Import from "components/modals/import";
import DeleteConfirm from "components/modals/DeleteConfirm";
import DecisionTableRows from "components/decisionTables/DecisionTableRows";
import { MainContext } from "context/contexts";
import { ToastOptions } from "components/toastify";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as WindIcon } from "assets/icons/wind.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as ImportIcon } from "assets/icons/import.svg";
import { ReactComponent as ExportIcon } from "assets/icons/export.svg";
import { ReactComponent as DecisionTablesIcon } from "assets/icons/decision_tables.svg";
import { ReactComponent as OverlapIcon } from "assets/icons/overlap.svg";
import { useTranslation } from "react-i18next";
import OverlapModal from "components/modals/decisionTables/OverlapModal";

const DecisionTable = () => {
  const { vectorsData, setIsLoading, setIsEdited, isEdited, isLoading } =
    useContext(MainContext);
  const { id } = useParams();
  const dispatch = useDispatch();
  const ref = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [dataSourceRef] = useCtrlSHandler(saveDecisionTable);
  useCtrlQHandler(() => handleShowTestDecisionTableModal());

  const {
    decisionTable,
    decisionTableRows,
    exportedTableData,
    isImportTableDataSuccess,
    isExportTableDataSuccess,
    isUpdatedDecisionTableSuccess,
    isGetDecisionTableRowsSuccess,
    isGetDecisionTableRevisionsSuccess,
    decisionTableRevisions,
    isGetDecisionTableSuccess,
    isRemovedDecisionTableSuccess,
    isGetTestResultStatusSuccess,
    isGetTestObjectResultSuccess,
    runTestResultStatus,
    runTestObjectResult,
    runTestSimulationResult,
    isGetTestSimulationResultSuccess,
    isGetDecisionTableError,
    isUpdatedDecisionTableError,
    isGetDecisionTableRowsError,
    isGetDecisionTableRevisionsError,
    isImportTableDataError,
    isExportTableDataError,
    isGetTestResultStatusError,
    isGetTestObjectResultError,
    isGetTestSimulationResultError,
    isRemovedDecisionTableError,
    overlaps,
    isGetOverlapValidationSuccess,
    isGetOverlapValidationError,
  } = useSelector((state) => state.decisionTables);

  const prevIsGetDecisionTableRevisionsSuccess = usePrevious(
    isGetDecisionTableRevisionsSuccess
  );
  const prevIsGetDecisionTableSuccess = usePrevious(isGetDecisionTableSuccess);
  const prevIsGetDecisionTableError = usePrevious(isGetDecisionTableError);
  const prevIsImportTableDataError = usePrevious(isImportTableDataError);
  const prevIsRemovedDecisionTableError = usePrevious(
    isRemovedDecisionTableError
  );
  const prevIsGetTestSimulationResultError = usePrevious(
    isGetTestSimulationResultError
  );
  const prevIsGetTestObjectResultError = usePrevious(
    isGetTestObjectResultError
  );
  const prevIsGetDecisionTableRevisionsError = usePrevious(
    isGetDecisionTableRevisionsError
  );
  const prevIsGetDecisionTableRowsError = usePrevious(
    isGetDecisionTableRowsError
  );
  const prevIsUpdatedDecisionTableError = usePrevious(
    isUpdatedDecisionTableError
  );
  const prevIsGetTestResultStatusError = usePrevious(
    isGetTestResultStatusError
  );
  const prevIsExportTableDataError = usePrevious(isExportTableDataError);
  const prevIsGetTestObjectResultSuccess = usePrevious(
    isGetTestObjectResultSuccess
  );
  const prevIsGetTestResultStatusSuccess = usePrevious(
    isGetTestResultStatusSuccess
  );
  const prevIsGetTestSimulationResultSuccess = usePrevious(
    isGetTestSimulationResultSuccess
  );
  const prevIsGetDecisionTableRowsSuccess = usePrevious(
    isGetDecisionTableRowsSuccess
  );
  const prevIsUpdatedDecisionTableSuccess = usePrevious(
    isUpdatedDecisionTableSuccess
  );
  const prevIsRemovedDecisionTableSuccess = usePrevious(
    isRemovedDecisionTableSuccess
  );
  const prevIsExportTableDataSuccess = usePrevious(isExportTableDataSuccess);
  const prevIsImportTableDataSuccess = usePrevious(isImportTableDataSuccess);

  const prevIsGetOverlapValidationError = usePrevious(
    isGetOverlapValidationError
  );
  const prevIsGetOverlapValidationSuccess = usePrevious(
    isGetOverlapValidationSuccess
  );

  const [decisionTableData, setDecisionTableData] = useState({});
  const [decisionTableCols, setDecisionTableCols] = useState([]);
  const [decisionTableColsRoles, setDecisionTableColsRoles] = useState([]);
  const [decisionTableRowsData, setDecisionTableRowsData] = useState([]);
  const [testingSimulationValues, setTestingSimulationValues] = useState([]);
  const [testObjectResultData, setTestObjectResultData] = useState();
  const [testSimulationResultData, setTestSimulationResultData] = useState();
  const [testResultDecisionTableData, setTestResultDecisionTableData] =
    useState();
  const [
    isDecisionTableRevisionsModalOpen,
    setIsDecisionTableRevisionsModalOpen,
  ] = useState(false);
  const [isTestDecisionTableModalOpen, setIsTestDecisionTableModalOpen] =
    useState(false);
  const [isDecisionTableDeleteModalOpen, setIsDecisionTableDeleteModalOpen] =
    useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const [isOverlapModalOpen, setIsOverlapModalOpen] = useState(false);

  const [vectorsDataClone, setVectorsDataClone] = useState([]);

  const [loading, setLoading] = useState(false);
  const [columnValues, setColumnValues] = useState([]);
  const [isRenderedTable, setIsRenderedTable] = useState(true);

  useEffect(() => {
    document.title = `${t("decision_table")} - Decisimo`;
    setIsLoading(true);
    dispatch(getDecisionTableRequest(id));
    dispatch(getDecisionTableRowsRequest(id));
  }, []);

  useEffect(() => {
    if (
      (isGetDecisionTableSuccess && prevIsGetDecisionTableSuccess === false) ||
      columnValues?.length > 0
    ) {
      setIsRenderedTable(false);
      const decisionTableClone = structuredClone(decisionTable);
      setDecisionTableData(decisionTableClone);
      const roles = decisionTableClone?.cols?.map((col) => ({
        role: col.role,
        decision_table_column_id: col.decision_table_column_id,
      }));
      const cols = decisionTableClone?.cols?.map((col) => ({
        title: col.title,
        decision_table_column_id: col.decision_table_column_id,
        type: "list",
        role: col.role,
        simTypeList: [],
        attribute_path: col.attribute_path,
        column_index: col.column_index, // Add column_index to cols
      }));
      setDecisionTableColsRoles(roles);
      setDecisionTableCols(cols);
      setIsLoading(false);

      if (columnValues.length > 0) {
        const simulationValues = [];
        decisionTableClone?.cols?.map((col) => {
          const potentialValues = columnValues[col.column_index]?.filter(
            (x, i, a) => a.indexOf(x) == i
          );
          if (potentialValues?.length > 0) {
            potentialValues.map((val) => {
              if (val.length > 0) {
                if (col.role === "condition" && col.value_a !== "") {
                  return simulationValues.push({
                    value: val,
                    label: val,
                    column_id: col.decision_table_column_id,
                  });
                }
              }
            });
          }
        });

        setTestingSimulationValues(simulationValues);
      }
    }
  }, [isGetDecisionTableSuccess, columnValues]);

  useEffect(() => {
    if (
      (isGetDecisionTableError && prevIsGetDecisionTableError === false) ||
      (isUpdatedDecisionTableError &&
        prevIsUpdatedDecisionTableError === false) ||
      (isGetDecisionTableRowsError &&
        prevIsGetDecisionTableRowsError === false) ||
      (isGetDecisionTableRevisionsError &&
        prevIsGetDecisionTableRevisionsError === false) ||
      (isImportTableDataError && prevIsImportTableDataError === false) ||
      (isExportTableDataError && prevIsExportTableDataError === false) ||
      (isGetTestResultStatusError &&
        prevIsGetTestResultStatusError === false) ||
      (isGetTestObjectResultError &&
        prevIsGetTestObjectResultError === false) ||
      (isGetTestSimulationResultError &&
        prevIsGetTestSimulationResultError === false) ||
      (isRemovedDecisionTableError && prevIsRemovedDecisionTableError === false)
    ) {
      if (isImportTableDataError) {
        handleCloseImportModal();
      }
      setIsLoading(false);
      setIsRenderedTable(false);
    }
  }, [
    isGetDecisionTableError,
    isUpdatedDecisionTableError,
    isGetDecisionTableRowsError,
    isGetDecisionTableRevisionsError,
    isImportTableDataError,
    isExportTableDataError,
    isGetTestResultStatusError,
    isGetTestObjectResultError,
    isGetTestSimulationResultError,
    isRemovedDecisionTableError,
  ]);

  useEffect(() => {
    if (
      isRemovedDecisionTableSuccess &&
      prevIsRemovedDecisionTableSuccess === false
    ) {
      setIsLoading(false);
      toast.warning(t("decision_table_deleted"), ToastOptions);
      navigate("/decision-tables");
    }
  }, [isRemovedDecisionTableSuccess]);

  useEffect(() => {
    if (
      isGetDecisionTableRowsSuccess &&
      prevIsGetDecisionTableRowsSuccess === false
    ) {
      setIsLoading(false);
      const decisionTableRowsClone = structuredClone(decisionTableRows);
      setDecisionTableRowsData(decisionTableRowsClone);
      const columnValues = [];
      decisionTableRowsClone?.map((row) => {
        return row.cols?.map((col, index) => {
          if (!columnValues[index]) {
            columnValues[index] = [col.value_a];
          } else {
            columnValues[index].push(col.value_a);
          }
        });
      });

      setColumnValues(columnValues);
    }
  }, [isGetDecisionTableRowsSuccess]);

  useEffect(() => {
    if (
      isUpdatedDecisionTableSuccess &&
      prevIsUpdatedDecisionTableSuccess === false
    ) {
      setIsLoading(false);
      toast.success(t("table_updated"), ToastOptions);
      dispatch(getDecisionTableRowsRequest(parseInt(id)));
    }
  }, [isUpdatedDecisionTableSuccess]);

  useEffect(() => {
    if (
      isGetDecisionTableRevisionsSuccess &&
      prevIsGetDecisionTableRevisionsSuccess === false
    ) {
      setIsLoading(false);
    }
  }, [isGetDecisionTableRevisionsSuccess]);

  useEffect(() => {
    if (isImportTableDataSuccess && prevIsImportTableDataSuccess === false) {
      setIsLoading(false);
      dispatch(getDecisionTableRowsRequest(parseInt(id)));
      toast.success(`${t("import_successful")}.`, ToastOptions);
      handleCloseImportModal();
    }
  }, [isImportTableDataSuccess]);

  useEffect(() => {
    if (isExportTableDataSuccess && prevIsExportTableDataSuccess === false) {
      setIsLoading(false);
      downloadFile({
        data: exportedTableData,
        fileName: `export_${decisionTable.name}_${moment(
          new Date()
        ).format()}.csv`,
        fileType: "text/csv",
      });
      toast.success(t("download_finished"), ToastOptions);
    }
  }, [isExportTableDataSuccess]);

  useEffect(() => {
    setVectorsDataClone([...vectorsData]);
  }, [vectorsData]);

  useEffect(() => {
    if (
      isGetTestResultStatusSuccess &&
      prevIsGetTestResultStatusSuccess === false
    ) {
      setIsLoading(false);
      setTestResultDecisionTableData(structuredClone(runTestResultStatus));
    }
  }, [isGetTestResultStatusSuccess]);

  useEffect(() => {
    if (
      isGetTestObjectResultSuccess &&
      prevIsGetTestObjectResultSuccess === false
    ) {
      setIsLoading(false);
      setTestObjectResultData(structuredClone(runTestObjectResult));
    }
  }, [isGetTestObjectResultSuccess]);

  useEffect(() => {
    if (
      isGetTestSimulationResultSuccess &&
      prevIsGetTestSimulationResultSuccess === false
    ) {
      setLoading(false);
      setTestSimulationResultData(
        structuredClone(Object.values(runTestSimulationResult))
      );
    }
  }, [isGetTestSimulationResultSuccess]);

  const handleCloseOverlapModal = () => {
    setIsOverlapModalOpen(false);
  };

  // table delete modal
  const handleShowDecisionTableModal = () => {
    setIsDecisionTableDeleteModalOpen(true);
  };

  const handleCloseDecisionTableModal = () => {
    setIsDecisionTableDeleteModalOpen(false);
  };

  const handleConfirmDecisionTableModal = () => {
    setIsLoading(true);
    setIsEdited(false);
    dispatch(deleteDecisionTableRequest(id));
    handleCloseDecisionTableModal();
  };

  // table delete modal
  const handleShowImportModal = () => {
    setIsImportModalOpen(true);
  };

  const handleCloseImportModal = () => {
    setIsImportModalOpen(false);
  };

  const handleConfirmImport = (selectedFile) => {
    const data = new URLSearchParams(new FormData());
    const fileReader = new FileReader();
    fileReader.onload = (evt) => {
      data.set("content", evt.target.result);
      setIsLoading(true);
      dispatch(importTableDataRequest({ id, data }));
    };
    fileReader.readAsText(selectedFile);
    setIsLoading(true);
  };

  // show modal revisions
  const handleShowDecisionTableRevisionsModal = () => {
    setIsLoading(true);
    dispatch(getDecisionTableRevisionsRequest(id));
    setIsDecisionTableRevisionsModalOpen(true);
  };

  // close modal revisions
  const handleCloseRevisions = () => {
    setIsDecisionTableRevisionsModalOpen(false);
  };

  // show modal testing modal
  const handleShowTestDecisionTableModal = () => {
    //if is edited, we will ask to save the scorecard first
    if (isEdited) {
      return toast.error(t("please_save_decision_table_first"), ToastOptions);
    }

    setIsTestDecisionTableModalOpen(true);
  };

  // close modal testing modal
  const handleCloseTestDecisionTableModal = () => {
    setTestSimulationResultData();
    setTestObjectResultData();
    setTestResultDecisionTableData();
    setIsTestDecisionTableModalOpen(false);
  };

  // run test result details confirm
  const handleRunTestResultDetailsConfirm = (e) => {
    e.preventDefault();
    const data = new URLSearchParams(new FormData(e.target));
    if (decisionTableCols?.length > 0) {
      decisionTableCols.forEach((col) => {
        data.set(
          `variable[${col.decision_table_column_id}][decision_table_column_id]`,
          col.decision_table_column_id
        );
      });
    }

    setIsLoading(true);
    dispatch(getRunTestResultStatusTableRequest({ data, id }));
  };

  // run test result object confirm
  const handleRunTestObjectResultSuccessConfirm = (vectorId) => {
    if (!vectorId) {
      vectorId = vectorsData.length > 1 ? vectorsData[1].vector_id : null;
    }
    if (!vectorId) {
      return toast.error(t("no_data_object_selected"), ToastOptions);
    }

    setIsLoading(true);
    dispatch(getRunTestObjectResultRequest({ id, vectorId }));
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  //check overlap
  const handleOverlapValidation = () => {
    setIsLoading(true);
    dispatch(getOverlapValidationRequest({ id: parseInt(id) }));
  };

  useEffect(() => {
    if (
      isGetOverlapValidationSuccess &&
      prevIsGetOverlapValidationError === false
    ) {
      setIsLoading(false);
      //if overlaps is json and array
      if (overlaps.length > 0 && Array.isArray(overlaps)) {
        setIsOverlapModalOpen(true);
      } else {
        toast.success(t("no_overlap"), ToastOptions);
      }
    }
  }, [isGetOverlapValidationSuccess]);

  const exportToCsv = () => {
    setIsLoading(true);
    dispatch(exportTableDataRequest(parseInt(id)));
  };

  const handleRunSimulationTestConfirm = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new URLSearchParams(new FormData(e.target));
    if (e.target.store_sample.checked === true) {
      data.set("store_sample", 1);
    }
    decisionTableCols
      .filter((col) => col.role !== "result")
      .map((col) => {
        if (
          e.target.querySelectorAll(
            `[name="variable[${col.decision_table_column_id}][list][]"]`
          ).length > 0
        ) {
          e.target
            .querySelectorAll(
              `[name="variable[${col.decision_table_column_id}][list][]"]`
            )
            .forEach((item) => {
              if (item.value === "") {
                data.delete(
                  `variable[${col.decision_table_column_id}][list][]`
                );
              }
            });
        }
        data.set(
          `variable[${col.decision_table_column_id}][decision_table_column_id]`,
          col.decision_table_column_id
        );
        data.set(
          `variable[${col.decision_table_column_id}][value_type]`,
          col.type
        );
      });

    dispatch(getRunTestSimulationResultRequest({ data, id }));
  };

  const settings = [
    {
      id: 1,
      content: (
        <Link
          to={`/decision-tables/${id}/cols`}
          className="dropdown-item dropdown-centered"
        >
          <SettingsIcon /> {t("definition")}
        </Link>
      ),
      divider: false,
    },
    {
      id: 6,
      content: (
        <button
          className="dropdown-item"
          type="button"
          onClick={handleOverlapValidation}
        >
          <OverlapIcon /> {t("check_overlap")}
        </button>
      ),
      divider: true,
    },
    {
      id: 2,
      content: (
        <button
          className="dropdown-item"
          onClick={handleShowDecisionTableRevisionsModal}
          type="button"
        >
          <ClockIcon /> {t("revisions")}
        </button>
      ),
      divider: false,
    },
    {
      id: 3,
      content: (
        <button
          className="dropdown-item"
          onClick={handleShowImportModal}
          type="button"
        >
          <ImportIcon /> {t("import")}
        </button>
      ),
      divider: false,
    },
    {
      id: 4,
      content: (
        <button className="dropdown-item" onClick={exportToCsv} type="button">
          <ExportIcon /> {t("export")}
        </button>
      ),
      divider: true,
    },
    {
      id: 5,
      content: (
        <button
          className="dropdown-item"
          type="button"
          onClick={handleShowDecisionTableModal}
        >
          <DeleteIcon /> {t("delete")}
        </button>
      ),
      divider: false,
    },
  ];

  function saveDecisionTable(target) {
    setIsEdited(false);
    const data = new URLSearchParams(new FormData(target));
    const finalRowsData = ref.current?.getFinalRowsData();
    if (finalRowsData?.length > 0) {
      finalRowsData.map((row, index) => {
        data.append(`row[${row.decision_table_row_id}][index]`, index);
      });
    }

    setIsLoading(true);
    dispatch(updateDecisionTableRequest({ id, data }));
  }

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          saveDecisionTable(e.target);
        }}
        onChange={() => setIsEdited(true)}
      >
        <SubHeader
          alt={t("decision_table")}
          icon={<DecisionTablesIcon />}
          title={decisionTableData?.title}
          actions={
            <>
              <button
                title={t("test_decision_table")}
                className="btn outline mr-1"
                type="button"
                onClick={handleShowTestDecisionTableModal}
                data-tip={true}
                data-for="test-decision-table"
              >
                <WindIcon />
              </button>
              <button
                className="btn primary mr-1"
                type="submit"
                title={t("save_decision_table")}
              >
                <SaveIcon />
              </button>
              <Link
                to={`/decision-tables/${id}/cols`}
                type="button"
                className="btn primary border-radius-left-4"
                title={t("decision_table_settings")}
              >
                <SettingsIcon />
              </Link>
              <div className="btn-group">
                <button
                  className="btn dropdown-toggle dropdown-toggle-split primary border-radius-right-4"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  title={t("decision_table_option")}
                >
                  <span className="sr-only">{t("toggle_dropdown")}</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                  {settings.length > 0 &&
                    settings.map((setting) => {
                      return (
                        <span key={setting.id}>
                          {setting.content}
                          {setting.divider && (
                            <div className="dropdown-divider" />
                          )}
                        </span>
                      );
                    })}
                </div>
              </div>
            </>
          }
        />

        {isRenderedTable ? (
          <div className="align-items-center col d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">{t("loading")}</span>
            </div>
          </div>
        ) : (
          <DecisionTableRows
            ref={ref}
            decisionTableColsRoles={decisionTableColsRoles}
            decisionTableCols={decisionTableCols}
            setDecisionTableRowsData={setDecisionTableRowsData}
            decisionTableRowsData={decisionTableRowsData}
            tableId={parseInt(id)}
          />
        )}
      </form>

      <DeleteConfirm
        handleClose={handleCloseDecisionTableModal}
        handleConfirm={handleConfirmDecisionTableModal}
        title={t("delete_decision_table")}
        message={t("delete_decision_table_message")}
        open={isDecisionTableDeleteModalOpen}
      />

      <Revisions
        handleClose={handleCloseRevisions}
        revisionsData={decisionTableRevisions}
        linkTo={"decision-tables"}
        open={isDecisionTableRevisionsModalOpen}
        elemId={parseInt(id)}
      />

      <Import
        handleClose={handleCloseImportModal}
        handleConfirm={handleConfirmImport}
        open={isImportModalOpen}
      />

      <TestingModal
        size={"xl"}
        title={t("testing_decision")}
        handleClose={handleCloseTestDecisionTableModal}
        loading={loading}
        handleRunTestResultDetailsConfirm={handleRunTestResultDetailsConfirm}
        handleRunTestResultSuccessConfirm={
          handleRunTestObjectResultSuccessConfirm
        }
        testingSimulationValues={testingSimulationValues}
        runTestResultDetailsData={testResultDecisionTableData}
        runTestResultStatusData={testObjectResultData}
        runTestSimulationResultData={testSimulationResultData}
        handleRunSimulationTestConfirm={handleRunSimulationTestConfirm}
        testingData={decisionTableCols}
        setDecisionTableCols={setDecisionTableCols}
        vectorsData={vectorsDataClone}
        open={isTestDecisionTableModalOpen}
        tableMode={true}
      />

      <OverlapModal
        show={isOverlapModalOpen}
        handleClose={handleCloseOverlapModal}
        overlaps={overlaps}
        columns={decisionTableCols}
      />
    </>
  );
};

export default DecisionTable;
