import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AutoSuggestInput from "../autosuggest";

const SubFlowAttributes = ({ attr, vectorsDataClone, stepOptionId }) => {
  const { revisionId } = useParams();
  const { t } = useTranslation();

  return (
    <div className="col mr-0 ml-0 subflow-option">
      <div
        className={`input-group${
          attr.name === "attribute_path_input" ? " mb-1" : ""
        }`}
      >
        <div className="bg-white border border-radius-left-4 input-group-prepend w-150">
          <span className="align-items-center d-flex font-size-14 pl-2 text-gray-66">
            {attr.name.includes("input")
              ? t("input_attribute")
              : t("output_attribute")}
          </span>
        </div>
        <input
          type="hidden"
          value={attr.name}
          name={`attribute[${stepOptionId}][${attr.wf_step_option_attribute_id}][name]`}
        />
        <input
          type="hidden"
          value="1"
          name={`attribute[${stepOptionId}][${attr.wf_step_option_attribute_id}][vector]`}
        />
        <AutoSuggestInput
          revisionMode={!!revisionId}
          vectorsData={vectorsDataClone}
          defaultValue={attr.path || ""}
          inputName={`attribute[${stepOptionId}][${attr.wf_step_option_attribute_id}][path]`}
        />
      </div>
    </div>
  );
};

SubFlowAttributes.propTypes = {
  attr: PropTypes.object,
  vectorsDataClone: PropTypes.array,
  stepOptionId: PropTypes.any,
  attributes: PropTypes.array,
  setStepOptions: PropTypes.any,
};

export default SubFlowAttributes;
