import React, { useState, useEffect } from "react";
import "./ApprovalProcessModal.scss"; // Import the styles
import DecisimoLogo from "../../assets/images/decisimo-logo-approval-proces.svg";
import { ReactComponent as RejectIcon } from "assets/icons/approval-process-reject.svg";
import { ReactComponent as ApproveIcon } from "assets/icons/approval-process-approve.svg";
import { ReactComponent as ApprovalProcessIcon } from "assets/icons/approval-process-icon.svg";
import { ReactComponent as DashboardSmall } from "assets/icons/dashboard-small.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import { ToastOptions } from "components/toastify";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  getApprovalStepRequest,
  signApprovalProcessStepRequest,
} from "redux/approvalProcesses/action";
import "react-toastify/dist/ReactToastify.css";
import { use } from "react-dom-factories";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const dummyData = {
  title: "Approval process: Sequence title",
  username: "Username",
  workflowRelease: "Lorem ipsum dolor sit amet",
  decisionFlow: "Lorem ipsum",
  releaseNotes:
    "Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Vivamus porttitor turpis ac leo. Pellentesque sapien. Sed vel lectus. Donec odio tempus molestie, porttitor ut, iaculis quis, sem. Aliquam ante. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
};

const ApprovalProcessModal = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { title, username, workflowRelease, decisionFlow, releaseNotes } =
    dummyData;
  const [decision, setDecision] = useState(null);
  const { approvalStep, isGetApprovalStepSuccess, isGetApprovalStepError } =
    useSelector((state) => state.approvalProcesses);
  const [isApproved, setIsApproved] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);

  const [isAlreadyApproved, setIsAlreadyApproved] = useState(false);
  const [isAlreadyRejected, setIsAlreadyRejected] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getApprovalStepRequest(id));
  }, []);

  useEffect(() => {
    if (approvalStep) {
      if (approvalStep.status === "a") {
        setIsAlreadyApproved(true);
      }
      if (approvalStep.status === "r") {
        setIsAlreadyRejected(true);
      }
    }
  }, [approvalStep, isGetApprovalStepSuccess]);

  //if error, move to page-not-found
  useEffect(() => {
    if (isGetApprovalStepError) {
      navigate(`/page-not-found`);
    }
  }, [isGetApprovalStepError]);

  const handleApproveClick = () => {
    setIsApproved(true);
  };

  const handleConfirmClick = () => {
    if (confirmationText === "APPROVE") {
      setIsConfirmed(true);
      dispatch(signApprovalProcessStepRequest({ id, status: "a" }));
      setDecision("a");
    } else {
      toast.error(t("please_write_down_word_approve_to_confirm"), ToastOptions);
    }
  };

  const handleRejectClick = () => {
    setIsConfirmed(true);
    setDecision("r");

    dispatch(signApprovalProcessStepRequest({ id, status: "r" }));
  };

  const handleCancelClick = () => {
    setIsApproved(false);
    setConfirmationText("");
  };

  return (
    <div className="approval-process-container">
      <div className="approval-process-modal">
        <div className="modal-header">
          <img src={DecisimoLogo} alt="Decisimo Logo" />
        </div>

        <div className="modal-content">
          {isConfirmed || isAlreadyApproved || isAlreadyRejected ? (
            <div className="final-confirmation">
              <p>
                {isAlreadyApproved && (
                  <>
                    <p>
                      {t("this_approval_process_has_been_already_approved")}
                    </p>
                  </>
                )}
                {isAlreadyRejected && (
                  <>
                    <p>
                      {t("this_approval_process_has_been_already_rejected")}
                    </p>
                  </>
                )}
                {isConfirmed &&
                  t(
                    "you_have_successfully_signed_the_approval_process_title_step_step_number",
                    {
                      title: title,
                      step_number: "2",
                      signed: decision === "a" ? t("approved") : t("rejected"),
                    }
                  )}{" "}
              </p>
              <div className="final-buttons">
                <button className="button-primary-icon-text final-button">
                  <LogoutIcon className="icon" />
                  {t("log_out")}
                </button>
                <Link
                  className="button-primary-icon-text final-button"
                  to="/dashboard"
                >
                  {" "}
                  <DashboardSmall className="icon" />
                  {t("go_to_dashboard")}
                </Link>
              </div>
            </div>
          ) : (
            <>
              <div className="title-container">
                <ApprovalProcessIcon className="approval-process-icon" />
                <h1 className="approval-process-title">
                  {approvalStep.sequence_title}
                </h1>
              </div>
              <div className="user-info-text">
                {t("you_are_logged_as_user")} <strong>{username}</strong>
              </div>
              <div className="details-section">
                <div className="details-row">
                  <span className="label">{t("release_capitalized")}</span>
                  <span>{approvalStep.release_name}</span>
                </div>
                <div className="details-row">
                  <span className="label">{t("decision_flow")}</span>
                  <span>{approvalStep.decision_flow_title}</span>
                </div>
                <div className="details-row">
                  <span className="label">{t("release_notes")}</span>
                  <span>{approvalStep.release_notes}</span>
                </div>
              </div>
              <div className="approve-section">
                {isApproved ? (
                  <div className="confirmation-section">
                    <label className="confirmation-label">
                      {t("please_write_down_word_approve_to_confirm")}
                    </label>
                    <input
                      type="text"
                      className="confirmation-input"
                      value={confirmationText}
                      placeholder="APPROVE"
                      onChange={(e) => setConfirmationText(e.target.value)}
                    />
                    <div className="confirmation-buttons">
                      <button
                        className="button-cancel"
                        onClick={handleCancelClick}
                      >
                        {t("cancel")}
                      </button>
                      <button
                        className="button-primary-icon-text confirm"
                        onClick={handleConfirmClick}
                      >
                        {t("confirm")}
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <button
                      className="button-primary-icon-text reject"
                      onClick={handleRejectClick}
                    >
                      <RejectIcon className="icon reject-icon" />
                      {t("reject")}
                    </button>
                    <button
                      className="button-primary-icon-text approve"
                      onClick={handleApproveClick}
                    >
                      <ApproveIcon className="icon approve-icon" />
                      {t("approve")}
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApprovalProcessModal;
