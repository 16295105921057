import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchDecisionTreeRequest,
  updateDecisionTreeRequest,
  createDecisionTreeRequest,
  deleteDecisionTreeRequest,
  getRunTestResultRequest,
  getRunTestResultStatusRequest,
  getTestDecisionTreeRequest,
  getDecisionTreeRevisionsRequest,
} from "redux/decisionTrees/action";
import { ToastOptions } from "components/toastify";
import { useTranslation } from "react-i18next";
import PropTypes, { node } from "prop-types";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import DeleteConfirm from "components/modals/DeleteConfirm";

import { ReactComponent as CrossHairIcon } from "assets/icons/crosshair.svg";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as BackEditIcon } from "assets/icons/back-edit.svg";
import { ReactComponent as ForwardEditIcon } from "assets/icons/forward-edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";

import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as WindIcon } from "assets/icons/wind.svg";
import Revisions from "components/modals/decisionTree/Revisions";

import TestingModal from "components/modals/Testing";
import usePrevious from "utility/hooks/usePrevious";

import SubHeader from "components/SubHeader";
import { MainContext } from "context/contexts";

import {
  DecisionTreeProvider,
  DecisionTreeContext,
} from "context/DecisionTreeContext";
import DTDiagram from "./drawflow/Diagram";
import {
  useCtrlSHandler,
  useCtrlQHandler,
} from "utility/hooks/useCtrlSHandler";
import { isFunction } from "lodash";

const DecisionTreeContent = ({ decisionTreeId, isRevision }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    decisionTree,
    isUpdateSuccess,
    isUpdateTreeSuccess,
    isUpdateError,
    isCreateSuccess,
    isCreateError,
    isFetchTreeError,
    isFetchTreeSuccess,
    isDeleteTreeSuccess,
    isDeleteTreeError,
    revisions,
    runTestResultDetails,
    testDecisionTreeData,
    testObjectResultData,
    isGetRunTestResultSuccess,
    isGetTestResultStatusSuccess,
    isFetchRevisionSuccess,
    runTestResultStatus,
  } = useSelector((state) => state.decisionTrees);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [
    isDecisionTreeRevisionsModalOpen,
    setIsDecisionTreeRevisionsModalOpen,
  ] = useState(false);

  useCtrlQHandler(() => handleToggleTestingModal(true));

  const prevIsDeleteTreeSuccess = usePrevious(isDeleteTreeSuccess);
  const prevIsDeleteTreeError = usePrevious(isDeleteTreeError);

  //prev update and create
  const prevIsUpdateSuccess = usePrevious(isUpdateTreeSuccess);

  const [isBackAndForth, setIsBackAndForth] = useState(false);

  const handleConfirmDelete = () => {
    dispatch(deleteDecisionTreeRequest(decisionTreeId));
    setIsDeleteModalOpen(false);
  };

  const decisionTreeRef = useRef();
  //get from context
  const {
    decisionTreeClone,
    setDecisionTreeClone,
    newNode,
    revisionMode,
    setRevisionMode,
  } = React.useContext(DecisionTreeContext);

  // se it iseEdited from main context
  const { vectorsData, setIsLoading, setIsEdited, isEdited } =
    React.useContext(MainContext);

  //vector data clone
  const [vectorsDataClone, setVectorsDataClone] = useState([]);

  const [name, setName] = useState("");
  const [title, setTitle] = useState("");

  //set vector data clone on load
  useEffect(() => {
    setVectorsDataClone(vectorsData);
  }, [vectorsData]);

  //if id si not set, create new decision tree
  useEffect(() => {
    if (!decisionTreeId) {
      let nodeToAdd = newNode;
      let node_id = "new_" + Math.round(Math.random() * 1000);
      nodeToAdd.name = "Start";
      nodeToAdd.id = node_id;
      nodeToAdd.decision_tree_node_id = node_id;
      //position in the middle of the screen- get the screensize, divide by 2 and then deduct the node size
      nodeToAdd.x = 500;
      nodeToAdd.y = 100;

      setDecisionTreeClone({
        title: t("new_decision_tree"),
        name: "new_decision_tree" + Math.random().toString(36).substring(3),
        nodes: [nodeToAdd],
        links: [],
      });
    }
  }, [decisionTreeId]);

  // Populate form when editing an existing decision tree
  useEffect(() => {
    if (decisionTreeId && (isFetchTreeSuccess || isFetchRevisionSuccess)) {
      setName(decisionTree.name);
      setTitle(decisionTree.title);
      setDecisionTreeClone(decisionTree);
    }
  }, [decisionTreeId, isFetchTreeError, isFetchTreeSuccess, decisionTree]);

  //onrevision load
  useEffect(() => {
    if (isRevision && isFetchRevisionSuccess) {
      setRevisionMode(true);
      setDecisionTreeClone(decisionTree);
    }
  }, [decisionTree]);

  const handleCloneAttributeChange = (e) => {
    const { name, value } = e.target;
    // Update clone state
    setDecisionTreeClone((prev) => ({ ...prev, [name]: value }));
  };

  const handleCloneChange = (newDecisionTree) => {
    if (newDecisionTree) {
      setDecisionTreeClone(newDecisionTree);
    }
  };

  const handleToggleDecisionTreeRevisionsModal = (isOpen = true) => {
    setIsDecisionTreeRevisionsModalOpen(isOpen);
    if (isOpen) {
      dispatch(getDecisionTreeRevisionsRequest(decisionTreeId));
    }
  };

  const handleSubmit = (e) => {
    if (e && isFunction(e.preventDefault)) {
      e.preventDefault();
    }
    const payload = decisionTreeClone;

    if (decisionTreeId) {
      dispatch(updateDecisionTreeRequest({ id: decisionTreeId, ...payload }));
    } else {
      setIsEdited(false);
      dispatch(createDecisionTreeRequest(payload));
    }
  };

  // Handle success and error feedback
  useEffect(() => {
    if (isUpdateTreeSuccess && prevIsUpdateSuccess === false) {
      setIsEdited(false);
      toast.success(t("decision_tree_saved_successfully"), ToastOptions);
    } else if (isUpdateError || isCreateError) {
      toast.error(t("error_saving_decision_tree"), ToastOptions);
    }
  }, [isUpdateTreeSuccess]);

  //if successfully deleted, show success message
  useEffect(() => {
    if (isDeleteTreeSuccess && prevIsDeleteTreeSuccess === false) {
      setIsEdited(false);
      //navigate to decision trees
      navigate("/decision-trees");
    }
  }, [isDeleteTreeSuccess]);

  //if delete failed, show error message
  useEffect(() => {
    if (isDeleteTreeError && !prevIsDeleteTreeError) {
      toast.error(t("error_deleting_decision_tree"), ToastOptions);
    }
  }, [isDeleteTreeError, t]);

  //handling test results
  useEffect(() => {
    if (runTestResultDetails) {
      setRunTestResultDetailsData(runTestResultDetails);
    }
  }, [runTestResultDetails]);

  const [decisionTreeFormRef] = useCtrlSHandler(handleSubmit);

  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item"
          onClick={() => handleToggleDecisionTreeRevisionsModal()}
          type="button"
        >
          <ClockIcon /> {t("revisions")}
        </button>
      ),
      divider: true,
    },
    {
      id: 2,
      content: (
        <button
          className="dropdown-item"
          type="button"
          title={t("delete_decision_tree")}
          onClick={() => setIsDeleteModalOpen(true)}
        >
          <TrashIcon /> {t("delete")}
        </button>
      ),
    },
  ];

  //testing modal
  const [loading, setLoading] = useState(false);
  const [isTestingModalOpen, setIsTestingModalOpen] = useState(false);
  const [runTestResultDetailsData, setRunTestResultDetailsData] = useState();
  const [runTestResultStatusData, setRunTestResultStatusData] = useState();
  const [testDecisionTreeClone, setTestDecisionTreeClone] = useState([]);

  const handleToggleTestingModal = (isOpen = false) => {
    //if is edited, we will ask to save first
    if (isEdited) {
      return toast.error(t("please_save_decision_tree_first"), ToastOptions);
    }

    setIsTestingModalOpen(isOpen);
    if (!isOpen) {
      setRunTestResultDetailsData();
    } else {
      dispatch(getTestDecisionTreeRequest(decisionTreeId));
    }
  };

  const handleRunTestResultConfirm = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = new URLSearchParams(new FormData(e.target));
    dispatch(getRunTestResultRequest({ data, id: decisionTreeId }));
  };

  useEffect(() => {
    if (
      runTestResultDetails &&
      (isGetRunTestResultSuccess || isGetTestResultStatusSuccess)
    ) {
      setRunTestResultDetailsData(runTestResultDetails);
      setLoading(false);
    }
  }, [runTestResultDetails, isGetTestResultStatusSuccess]);

  //on testing error
  useEffect(() => {
    if (isGetRunTestResultSuccess && !runTestResultDetails) {
      setLoading(false);
      toast.error(t("error_testing_decision_tree"), ToastOptions);
    }
  }, [isGetRunTestResultSuccess]);

  const handleRunTestResultSuccessConfirm = (vectorId) => {
    if (!vectorId) {
      vectorId = vectorsData.length > 1 ? vectorsData[1].vector_id : null;
    }
    if (!vectorId) {
      return toast.error(t("no_data_object_selected"), ToastOptions);
    }
    setIsLoading(true);
    dispatch(getRunTestResultStatusRequest({ id: decisionTreeId, vectorId }));
  };

  //isGetTestResultStatusSuccess
  useEffect(() => {
    if (isGetTestResultStatusSuccess) {
      setIsLoading(false);
      setRunTestResultStatusData(structuredClone(runTestResultStatus));
    }
  }, [isGetTestResultStatusSuccess]);

  return (
    <>
      <SubHeader
        title={
          (revisionMode ? t("revision") + " " : "") +
          (decisionTreeId && decisionTreeClone
            ? decisionTreeClone.title && decisionTreeClone.title.length < 1
              ? t("decision_tree")
              : decisionTreeClone.title
            : t("create_decision_tree"))
        }
        actions={
          <>
            {!isRevision ? (
              <>
                <Link to="/decision-trees">
                  <button className="btn outline-header">
                    <BackIcon />{" "}
                    <span className="ml-2">{t("back_to_decision_trees")}</span>
                  </button>
                </Link>
                {decisionTreeId && (
                  <button
                    title={t("test_decision_table")}
                    className="btn outline mr-1 ml-2"
                    type="button"
                    onClick={() => handleToggleTestingModal(true)}
                    data-tip={true}
                    data-for="test-decision-table"
                  >
                    <WindIcon />
                  </button>
                )}
                <button
                  className="btn primary-header mx-2"
                  onClick={handleSubmit}
                >
                  <SaveIcon />
                </button>
              </>
            ) : (
              <>
                <Link to={`/decision-trees/${decisionTreeId}`}>
                  <button className="btn outline-header">
                    <BackIcon />{" "}
                    <span className="ml-2">{t("back_to_decision_tree")}</span>
                  </button>
                </Link>
              </>
            )}
            {decisionTreeId && (
              <div>
                <button
                  type="button"
                  className="btn primary"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  title={t("delete_data_source")}
                >
                  <SettingsIcon />
                </button>

                <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                  {settings.length > 0 &&
                    settings.map((setting) => {
                      return (
                        <span key={setting.id}>
                          {setting.content}
                          {setting.divider && (
                            <div className="dropdown-divider" />
                          )}
                        </span>
                      );
                    })}
                </div>
              </div>
            )}
          </>
        }
      />
      <form onSubmit={handleSubmit} ref={decisionTreeFormRef}>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="title">{t("title_capitalized")}:</label>
              <input
                id="title"
                type="text"
                className="form-control"
                name="title"
                placeholder={t("decision_tree")}
                value={decisionTreeClone?.title || ""}
                onChange={handleCloneAttributeChange}
                disabled={isRevision}
                required
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="name">{t("identifier")}:</label>
              <input
                id="name"
                type="text"
                name="name"
                className="form-control"
                value={decisionTreeClone?.name || ""}
                onChange={handleCloneAttributeChange}
                disabled={isRevision}
                required
              />
            </div>
          </div>
        </div>
      </form>
      <nav className="bg-white border navbar overflow-auto">
        <div className="d-flex workflow-steps">
          {/*
          <button
            className="btn outline border m-1 d-flex align-items-center white-space-pre"
            onClick={handleBack}
            disabled={past.length < 2}
          >
            <BackEditIcon />
          </button>
          <button
            className="btn outline border m-1 d-flex align-items-center white-space-pre"
            onClick={handleForward}
            disabled={future.length === 0}
          >
            <ForwardEditIcon />
          </button>
          */}
        </div>
        <div className="d-flex">
          <button
            className="btn outline border m-1 d-flex align-items-center white-space-pre"
            onClick={() => decisionTreeRef.current.zoomOut()}
            title={t("zoom_out")}
          >
            -
          </button>

          <button
            className="btn outline border m-1 d-flex align-items-center white-space-pre"
            onClick={() => decisionTreeRef.current.zoom()}
            title={t("zoom_in")}
          >
            +
          </button>

          <button
            className="btn outline border m-1 d-flex align-items-center white-space-pre"
            onClick={() => decisionTreeRef.current.zoomFit()}
            title={t("center")}
          >
            <CrossHairIcon />
          </button>
        </div>
      </nav>
      <div className="diagram-container decision_tree">
        <DTDiagram
          decisionTreeClone={decisionTreeClone}
          handleCloneChange={handleCloneChange}
          decisionTreeRef={decisionTreeRef}
          isRevision={isRevision}
        />
      </div>
      <DeleteConfirm
        open={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={handleConfirmDelete}
        title={t("delete_decision_tree")}
        message={t("are_you_sure_delete_decision_tree", {
          title: decisionTree?.title,
        })}
      />

      <Revisions
        handleClose={() => handleToggleDecisionTreeRevisionsModal(false)}
        revisionsData={revisions}
        linkTo={"decision-trees"}
        open={isDecisionTreeRevisionsModalOpen}
        elemId={parseInt(decisionTreeId)}
      />

      <TestingModal
        title={t("testing_decision_tree")}
        size={"lg"}
        loading={loading}
        testingData={testDecisionTreeData}
        handleClose={() => handleToggleTestingModal()}
        handleRunTestResultDetailsConfirm={handleRunTestResultConfirm}
        handleRunTestResultSuccessConfirm={handleRunTestResultSuccessConfirm}
        runTestResultDetailsData={runTestResultDetailsData}
        runTestResultStatusData={runTestResultStatusData}
        open={isTestingModalOpen}
        isDecisionTree={true}
        vectorsData={vectorsDataClone}
      />
    </>
  );
};

DecisionTreeContent.propTypes = {
  decisionTreeId: PropTypes.string,
  isRevision: PropTypes.bool,
};

export default DecisionTreeContent;
