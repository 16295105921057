import * as SRD from "react-js-diagrams/lib/main";
import { CustomPortModel } from "components/workflow/drawflow/CustomNode/CustomPortModel";

export class CustomNodeModel extends SRD.NodeModel {
  constructor(node, index) {
    super("custom");
    this.x = node.x;
    this.y = node.y;
    this.index = index;
    this.name = node.name;
    this.data = node.data;
    this.icon = "";

    if (node.wf_step_type_name === "start") {
      this.icon = "start";
      this.addPort(
        new CustomPortModel({
          isInput: false,
          id: `right-${node.workflow_step_id}`,
          name: "right",
        })
      );
    } else if (node.wf_step_type_name === "fork") {
      this.icon = "fork";
      this.addPort(
        new CustomPortModel({
          isInput: true,
          id: `left-${node.workflow_step_id}`,
          name: "left",
          disable: true,
        })
      );
      this.addPort(
        new CustomPortModel({
          isInput: false,
          id: `right-${node.workflow_step_id}`,
          name: "right",
        })
      );
      this.addPort(
        new CustomPortModel({
          isInput: false,
          id: `right-down-${node.workflow_step_id}`,
          name: "right-down",
        })
      );
    } else {
      this.addPort(
        new CustomPortModel({
          isInput: true,
          id: `left-${node.workflow_step_id}`,
          name: "left",
          disable: true,
        })
      );
      this.addPort(
        new CustomPortModel({
          isInput: false,
          id: `right-${node.workflow_step_id}`,
          name: "right",
        })
      );
      if (node.wf_step_type_name === "rule_set") {
        this.icon = "rule-set";
      } else if (node.wf_step_type_name === "external_data_call") {
        this.icon = "external-data-call";
      } else if (node.wf_step_type_name === "decision_table") {
        this.icon = "decision-table";
      } else if (node.wf_step_type_name === "scorecard") {
        this.icon = "scorecard";
      } else if (node.wf_step_type_name === "model") {
        this.icon = "model";
      } else if (node.wf_step_type_name === "fce") {
        this.icon = "fce";
      } else if (node.wf_step_type_name === "subflow") {
        this.icon = "subflow";
      } else if (node.wf_step_type_name === "decision_tree") {
        this.icon = "decision_tree";
      } else if (node.wf_step_type_name === "end") {
        this.icon = "end";
      }
    }
  }
}
