import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { ReactComponent as Close } from "assets/icons/close_modal.svg";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { toInteger } from "lodash";
import "../../../assets/css/OverlapModal.css";

const OverlapModal = ({ show, handleClose, overlaps, columns }) => {
  const { t } = useTranslation();

  const prepareColumn = (role, operator, value_a, value_b) => {
    if (role === "result") {
      return value_a;
    }
    const patterns = {
      open_interval: "({$value_a},{$value_b})",
      left_closed_right_open: "[{$value_a},{$value_b})",
      closed_interval: "[{$value_a},{$value_b}]",
      left_open_right_closed: "({$value_a},{$value_b}]",
      greater_than_or_equal_to: ">={$value_a}",
      greater_than: ">{$value_a}",
      less_than_or_equal_to: "<={$value_a}",
      less_than: "<{$value_a}",
      not_equal_to: "!={$value_a}",
      equal_to: "{$value_a}",
      any_value: "ANY_VALUE",
      ends_with: "{$value_a}$",
      starts_with: "^{$value_a}",
    };

    return patterns[operator]
      .replace("{$value_a}", value_a)
      .replace("{$value_b}", value_b || "");
  };

  const renderRowMarkdown = (row) => {
    // Sort columns based on column_index
    const sortedCols = [...row.cols].sort(
      (a, b) =>
        columns.find(
          (col) => col.decision_table_column_id === a.decision_table_column_id
        ).column_index -
        columns.find(
          (col) => col.decision_table_column_id === b.decision_table_column_id
        ).column_index
    );

    const columnsMarkdown = sortedCols.map((col) =>
      prepareColumn(col.role, col.operator, col.value_a, col.value_b)
    );
    return `| ${toInteger(row.row_index) + 1}. | ${columnsMarkdown.join(
      " | "
    )} |`;
  };

  const renderOverlapMarkdown = (overlap) => {
    const header =
      `| Index | ${columns.map((col) => col.title).join(" | ")} |\n` +
      `| --- | ${columns.map(() => "---").join(" | ")} |\n`;
    const row1Markdown = renderRowMarkdown(overlap.row1);
    const row2Markdown = renderRowMarkdown(overlap.row2);
    return `${header}${row1Markdown}\n${row2Markdown}`;
  };

  return (
    <Modal dialogClassName="w-1300" show={show} onHide={handleClose} centered>
      <Modal.Header>
        <h5 className="modal-title">{t("overlap_detected")}</h5>
        <button type="button" className="close" onClick={handleClose}>
          <Close />
        </button>
      </Modal.Header>
      <Modal.Body>
        {overlaps.length > 0 ? (
          <div>
            <p>{t("overlaps_found_between_following_rows")}</p>

            {overlaps.map((overlap, index) => (
              <div key={index} className="mb-4">
                <h6>
                  {t("rows_with_overlap", {
                    row1: overlap.row1.row_index,
                    row2: overlap.row2.row_index,
                  })}
                </h6>
                <ReactMarkdown
                  className="markdown-table"
                  remarkPlugins={[remarkGfm]}
                >
                  {renderOverlapMarkdown(overlap)}
                </ReactMarkdown>
              </div>
            ))}
          </div>
        ) : (
          <p>{t("no_overlaps_found")}</p>
        )}
        {overlaps.length === 10 && <p>{t("max_10_overlaps_shown")}</p>}
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <div className={`d-flex `} onClick={handleClose}>
          <button type="submit" className="workflows-modal-btn mr-1">
            {t("close")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

OverlapModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  overlaps: PropTypes.array,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      column_index: PropTypes.number.isRequired,
      decision_table_column_id: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default OverlapModal;
